import React, { useMemo, useRef, useState } from "react";
import Icon from "shared/ui/atoms/Icon";
import LabeledItem from "shared/ui/atoms/LabeledItem/LabeledItem";

import styles from "./FileInput.module.css";

interface IProps {
  label?: string;
}

const FileInput: React.FC<IProps> = ({ label }) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [files, setFiles] = useState<File[]>([]);

  return (
    <LabeledItem label={label} isDefaultHeight={false}>
      <div className={styles.borders}>
        <div className={styles.list}>
          {files?.map((el, idx) => (
            <div key={el.name} className={styles.file}>
              <div className={styles.fileName} title={el.name}>
                {el.name}
              </div>
              <div
                className={styles.del}
                onClick={() =>
                  setFiles((prev) => prev.filter((el, i) => i !== idx))
                }
              >
                <Icon name="Close" />
              </div>
            </div>
          ))}
        </div>
        <div className={styles.btn} onClick={() => inputRef.current?.click()}>
          Choose file
        </div>
      </div>
      <input
        multiple
        max={3}
        type="file"
        ref={inputRef}
        className={styles.input}
        onChange={(e) => {
          if (!inputRef.current || !e.target.files) return;
          setFiles((prev) => [
            ...prev,
            ...Array.from(e.target.files as FileList),
          ]);
        }}
      />
    </LabeledItem>
  );
};

export default FileInput;
