import React, { useEffect, useMemo, useRef } from "react";
import downloadFileByUrl from "shared/lib/utils/downloadFileByUrl";
import { getFileExtension } from "shared/lib/utils/getFileExtension";
import { IBaseModalProps } from "shared/ui/modals/BaseModal/BaseModal";
import CommonModal from "shared/ui/modals/CommonModal/CommonModal";

import styles from "./FileViewer.module.scss";

const OPENABLE_EXTENSIONS = ["svg", "jpg", "jpeg", "png", "webp", "pdf"];
const IMG_EXTENSIONS = ["svg", "jpg", "jpeg", "png", "webp"];

interface IProps extends Omit<IBaseModalProps, "children"> {
  fileName: string;
  fileUrl: string;
}

const FileViewer: React.FC<IProps> = ({ fileName, fileUrl, ...baseProps }) => {
  const extension = getFileExtension(fileName);

  const isNotOpenable = useMemo(() => {
    return true;
    const extension = getFileExtension(fileName);
    return !OPENABLE_EXTENSIONS.includes(extension);
  }, [fileName]);

  const isImg = useMemo(() => {
    const extension = getFileExtension(fileName);
    return IMG_EXTENSIONS.includes(extension);
  }, [fileName]);

  const wasDownloaded = useRef<boolean | null>(false);

  useEffect(() => {
    if (
      isNotOpenable &&
      baseProps.isOpen &&
      !wasDownloaded.current &&
      fileUrl
    ) {
      downloadFileByUrl(fileUrl, fileName, () => {
        baseProps.onClose();
        wasDownloaded.current = true;
      });
    }
  }, [isNotOpenable, fileUrl, fileName, baseProps.isOpen]);

  if (isNotOpenable) {
    return null;
  }

  return (
    <CommonModal title={fileName} {...baseProps}>
      {JSON.stringify({ isImg })}
      {isImg && (
        <div className={styles.img} style={{ background: "red" }}>
          <img src={fileUrl} alt={fileName} />
        </div>
      )}
    </CommonModal>
  );
};

export default FileViewer;
