import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

interface SpvModalState {
  detailedSpvs: Record<string, any>;
  loading: Record<string, boolean>;
  invalidateKey: number;
}

const initialState: SpvModalState = {
  detailedSpvs: {},
  loading: {},
  invalidateKey: 1,
};

const spvModalSlice = createSlice({
  name: "spvModal",
  initialState,
  reducers: {
    setDetailed: (state, action: PayloadAction<{ data: any; key: string }>) => {
      state.detailedSpvs[action.payload.key] = action.payload.data;
    },
    setLoading: (
      state,
      action: PayloadAction<{ status: boolean; key: string }>
    ) => {
      state.loading[action.payload.key] = action.payload.status;
    },
    invalidateKey: (state) => {
      state.invalidateKey++;
    },
  },
});

export const spvModalActions = spvModalSlice.actions;

export default spvModalSlice.reducer;
