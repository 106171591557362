//@ts-nocheck
import { zodResolver } from "@hookform/resolvers/zod";
import { ICreateProfile } from "pages/Profiles/model/types";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Icon from "shared/ui/atoms/Icon";
import CommonModal from "shared/ui/modals/CommonModal/CommonModal";
import styles from "./PersonCard.module.css";
import * as z from "zod";
import PersonForm from "./PersonForm";

type IForm = Partial<ICreateProfile["additional_owners"][number]>;
interface IProps {
  onSubmit?: (vals: IForm) => void;
  modalTitle: string;
  disabled?: boolean;
}

const schema = z.object({
  first_name: z.string().min(1, { message: "Required" }),
  last_name: z.string().min(1, { message: "Required" }),
  email: z.string().min(1, { message: "Email is required" }).email({
    message: "Must be a valid email",
  }),
  middle_name: z.optional(z.string()),
  date_of_birth: z.optional(z.string()),
  ssn: z.optional(z.string()),
  zipcode: z.optional(z.string()),
  country: z.optional(z.string()),
  phone: z.optional(z.string()),
});

const AddControlPerson: React.FC<IProps> = ({
  modalTitle,
  onSubmit,
  disabled,
}) => {
  const [isModal, setIsModal] = useState(false);
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<IForm>({
    reValidateMode: "onBlur",
    resolver: zodResolver(schema),
    mode: "onChange",
  });

  return (
    <div className={styles.add} onClick={() => !disabled && setIsModal(true)}>
      {!disabled && <Icon name="Plus" />}{" "}
      <CommonModal
        isOpen={isModal}
        onClose={() => setIsModal(false)}
        closeOnClickOutside
        title={modalTitle}
        bottomControls={[
          {
            text: "Cancel",
            variant: "light",
            onClick: () => {
              reset();
              setIsModal(false);
            },
          },
          {
            text: "Add",

            onClick: () => {
              setIsModal(false);
              handleSubmit(onSubmit)();
            },
            disabled: !isValid,
          },
        ]}
        className={styles.modal}
      >
        <div className={styles.modalContent}>
          <div className={styles.grid3}>
            <PersonForm
              control={control}
              errors={errors}
              withoutOwnerPercentage
            />
          </div>
        </div>
      </CommonModal>
    </div>
  );
};

export default AddControlPerson;
