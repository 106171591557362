import AddPersonCardBtn from "features/PersonCard/AddOwnerCardBtn";
import PersonCard from "features/PersonCard/OwnerCard";
import { ICreateProfile, IProfileResponse } from "pages/Profiles/model/types";
import React, { useEffect, useState } from "react";
import Icon from "shared/ui/atoms/Icon";
import LabeledItem from "shared/ui/atoms/LabeledItem/LabeledItem";
import { NoData } from "shared/ui/atoms/NoData/NoData";
import Button from "shared/ui/controls/Button/Button";
import { Checkbox } from "shared/ui/inputs/Checkbox/Checkbox";
import Input from "shared/ui/inputs/Input/Input";
import PercentageInput from "shared/ui/inputs/PercentageInput/PercentageInput";
import BottomControls from "../../ui/BottomControls/BottomControls";
import { ICreateProfileStepProps } from "../CreateProfileStep1/CreateProfileStep1";

import styles from "./CreateEntityStep2.module.css";

interface IProps extends ICreateProfileStepProps {
  onSubmit: (signs: IProfileResponse["signatories"]) => void;
  isFinish?: boolean;
  isPending?: boolean;
}

const CreateEntityStep4: React.FC<IProps> = ({
  onCancel,
  isViewExisting,
  initialData,
  setStep,
  onSubmit,
  isFinish,
  isPending,
}) => {
  const [signs, setSigns] = useState<ICreateProfile["signatories"]>([]);

  const addSign = () => {
    setSigns((prev) => [
      ...prev,
      {
        email: "",
        first_name: "",
        last_name: "",
        middle_name: "",
        title: "",
      },
    ]);
  };

  const removeSign = (index: number) => {
    setSigns((prev) => prev.filter((el, i) => i !== index));
  };

  const editSign = (field: string, value: string, index: number) => {
    setSigns((prev) =>
      prev.map((el, i) => {
        if (i === index) {
          return { ...el, [field]: value };
        } else {
          return el;
        }
      })
    );
  };

  useEffect(() => {
    if (initialData?.signatories) {
      setSigns(initialData.signatories);
    }
  }, [initialData]);

  return (
    <div>
      {signs?.map((el, i) => (
        <div className={styles.block} key={i}>
          <div className={styles.part}>
            <div className={styles.header}>
              <div>
                <div className={styles.title}>Signatory</div>
                {/* <div className={styles.subtitle}>
                  A signatory is an individual...
                </div> */}
              </div>
              {!isViewExisting && i > 0 && (
                <div className={styles.delete} onClick={() => removeSign(i)}>
                  <Icon name="Delete" />
                </div>
              )}
            </div>

            <div className={styles.gridForm}>
              <Input
                label="First Name"
                placeholder="First Name"
                value={el.first_name}
                onChange={(e) => editSign("first_name", e.target.value, i)}
                disabled={isViewExisting}
              />
              <Input
                label="Middle Name"
                placeholder="Middle Name"
                value={el.middle_name}
                onChange={(e) => editSign("middle_name", e.target.value, i)}
                disabled={isViewExisting}
              />
              <Input
                label="Last Name"
                placeholder="Last Name"
                value={el.last_name}
                onChange={(e) => editSign("last_name", e.target.value, i)}
                disabled={isViewExisting}
              />
              <Input
                label="Title"
                placeholder="Title"
                value={el.title}
                onChange={(e) => editSign("title", e.target.value, i)}
                disabled={isViewExisting}
              />
              <Input
                label="Email Address"
                placeholder="Email Address"
                value={el.email}
                onChange={(e) => editSign("email", e.target.value, i)}
                disabled={isViewExisting}
              />
            </div>
          </div>
        </div>
      ))}

      {!isViewExisting && (
        <Button onClick={addSign}>
          <Icon name="Plus" />
          Add Signatory
        </Button>
      )}

      {isViewExisting &&
        initialData?.signatories !== null &&
        !initialData?.signatories?.length && (
          <div style={{ paddingTop: "200px" }}>
            <NoData />
          </div>
        )}

      {!isViewExisting && (
        <BottomControls
          isFinish={isFinish}
          onCommonClick={() => onSubmit(signs as any)}
          isViewExisting={isViewExisting}
          onClickPrev={() => setStep((p) => p - 1)}
          onClickNext={() => !isFinish && setStep((p) => p + 1)}
          isPending={isPending}
        />
      )}
    </div>
  );
};

export default CreateEntityStep4;
