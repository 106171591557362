import React from "react";

import styles from "./Status.module.scss";

interface IProps {
  children: React.ReactNode;
  colorBg?: string;
  colorText?: string;
}

const Status: React.FC<IProps> = ({
  children,
  colorBg = "#F1F6DF",
  colorText = "#6B7647",
}) => {
  return (
    <div
      className={styles.status}
      style={{ color: colorText, background: colorBg }}
    >
      {children}
    </div>
  );
};

export default Status;
