import Amount from "entities/Amount/Amount";
import TemplateBase from "features/template/TemplateBase/TemplateBase";
import React, { useState } from "react";
import Icon from "shared/ui/atoms/Icon";
import Button from "shared/ui/controls/Button/Button";
import Block from "shared/ui/layout/Block/Block";
import avatar from "./assets/avatar.png";

import styles from "./SpvSetup.module.scss";
import CreateSpvModal from "./widgets/CreateSpvModal/CreateSpvModal";

const SpvSetup = () => {
  const [isCreateModal, setIsCreateModal] = useState(false);

  return (
    <TemplateBase title="SPV Creation">
      <div className={styles.layout}>
        <div className={styles.cols3}>
          <Amount title="Total Investors" icon="MultiProfiles" value="435" />
          <Amount
            title="Investors Added Last Month"
            icon="ProfilePlus"
            value="12"
          />
          <Amount title="Overall Investment" icon="Cash" value="$38,000 M" />
        </div>
        <div className={styles.controls}>
          <Button onClick={() => setIsCreateModal(true)}>
            <Icon name="Plus" />
            Create SPV
          </Button>
        </div>
        <div className={styles.content}>
          <Block title="SPV Setup - Apollo">
            <div className={styles.table}>
              <div>
                <div className={styles.cell}>
                  <span>LLC Type</span>
                  <b>Series LLC (Included)</b>
                </div>
                <div className={styles.cell}>
                  <span>Target Portfolio Company</span>
                  <b>Apollo</b>
                </div>
                <div className={styles.cell}>
                  <span>Portfolio Company Legal Entity</span>
                  <b>Zenleads Inc.</b>
                </div>
                <div className={styles.cell}>
                  <span>Portfolio Company Website</span>
                  <a
                    href="https://website.com"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <b>website.com</b>
                  </a>
                </div>
                <div className={styles.cell}>
                  <span>SPV Legal Entity Name</span>
                  <b>AVTAR LLC - IL LLC</b>
                </div>
              </div>
              <div>
                <div className={styles.cell}>
                  <span>SPV Target Value</span>
                  <b>$150,000.00</b>
                </div>
                <div className={styles.cell}>
                  <span>Can SPV Go Over Target</span>
                  <b>Yes</b>
                </div>
                <div className={styles.cell}>
                  <span>Type of Investment</span>
                  <b>Equity</b>
                </div>
                <div className={styles.cell}>
                  <span>SPV Target Close Date</span>
                  <b>07-06-2021</b>
                </div>
              </div>
            </div>
          </Block>
          <Block title="Manager Profile">
            <div className={styles.avatar}>
              <img src={avatar} alt="avatar" />
            </div>
            <div className={styles.cell}>
              <span>First Name</span>
              <b>Ross</b>
            </div>
            <div className={styles.cell}>
              <span>Last Name</span>
              <b>Andrews</b>
            </div>
            <div className={styles.cell}>
              <span>Email</span>
              <a href="mailto:Ross@email.com">
                <b>Ross@email.com</b>
              </a>
            </div>
            <div className={styles.cell}>
              <span>Phone</span>
              <b>123 456 7890</b>
            </div>
            <div className={styles.cell}>
              <div className={styles.viewManager}> View Manager Profile</div>
            </div>
          </Block>
        </div>
      </div>
      <CreateSpvModal
        isOpen={isCreateModal}
        onClose={() => setIsCreateModal(false)}
      />
    </TemplateBase>
  );
};

export default SpvSetup;
