//@ts-nocheck
import { ICreateProfile, IProfileDetail } from "pages/Profiles/model/types";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Icon from "shared/ui/atoms/Icon";
import Label from "shared/ui/atoms/Label/Label";
import Dropdown from "shared/ui/inputs/Dropdown";
import FileInput from "shared/ui/inputs/FileInput/FileInput";
import IdentyVerification from "widgets/IdentyVerification/IdentyVerification";
import KYC from "widgets/KYC/KYC";

import BottomControls from "../../ui/BottomControls/BottomControls";
import { ICreateProfileStepProps } from "../CreateProfileStep1/CreateProfileStep1";

import styles from "./CreateProfileStep4.module.css";

const docTypes = [
  { label: "Passport", key: "Passport" },
  { label: "Driver’s license", key: "drivers_license" },
  { label: "State ID", key: "state_id" },
] as const;

export type DocumentTypes = "Passport" | "drivers_license" | "state_id";

interface IProps extends ICreateProfileStepProps {
  isViewExisting?: boolean;
  onCancel?: () => void;
  onSubmit?: (passport: File, passportType: DocumentTypes) => void;
  profile?: IProfileDetail;
}

const CraeteProfileStep4: React.FC<IProps> = ({
  setStep,
  isViewExisting,
  onCancel,
  profile,
  onSubmit,
}) => {
  const [type, setType] = useState<DocumentTypes>(docTypes[0].key);
  const [passport, setPassport] = useState<File[]>([]);

  /*   const alreadyUploaded = profile?.id_uploaded;
  const isIndividual = profile?.type === "INDIVIDUAL"; */

  return (
    <div>
      {/* {!alreadyUploaded && false && (
        <div className={styles.grid}>
          <Dropdown
            label="Type of ID"
            items={docTypes as any}
            value={type}
            onSelect={setType as any}
            disabled={alreadyUploaded}
          />
          <FileInput
            files={passport}
            setFiles={setPassport}
            label="Upload Passport"
          />
        </div>
      )}
      {false && alreadyUploaded && <Label>Upload ID</Label>}
      {false && (
        <div className={styles.tip}>
          <Icon name="Info" />
          {alreadyUploaded
            ? "ID already uploaded"
            : "Maximum file size X MB. File format must be .png, .jpeg, or .pdf."}
        </div>
      )} */}
      {/*  {!!profile && (
        <IdentyVerification
          profileId={profile.id}
          isVerified={!profile.needs_kyc}
        />
      )} */}
      {!!profile && <KYC profileId={profile.id} profile={profile} />}
      {!isViewExisting && (
        <BottomControls
          disabled={!passport && !isViewExisting}
          onCommonClick={
            isViewExisting ? () => {} : () => onSubmit?.(passport[0], type)
          }
          onClickNext={
            isViewExisting
              ? () => {
                  onSubmit?.(passport[0], type);
                }
              : () => setStep((prev) => prev + 1)
          }
          onClickPrev={() => {
            if (isViewExisting) {
              onCancel?.();
            } else {
              setStep((prev) => prev - 1);
            }
          }}
          isViewExisting={isViewExisting}
        />
      )}
    </div>
  );
};

export default CraeteProfileStep4;
