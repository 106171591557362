import React from "react";
import Icon from "shared/ui/atoms/Icon";
import Button, { IButtonProps } from "shared/ui/controls/Button/Button";
import BaseModal, { IBaseModalProps } from "../BaseModal/BaseModal";

import styles from "./CommonModal.module.css";

interface IProps extends IBaseModalProps {
  title?: string;
  bottomControls?: {
    text: string;
    onClick?: () => void;
    variant?: IButtonProps["variant"];
    type?: IButtonProps["type"];
    size?: IButtonProps["size"];
  }[];
}

const CommonModal: React.FC<IProps> = ({
  children,
  title,
  bottomControls,
  ...baseProps
}) => {
  return (
    <BaseModal
      {...baseProps}
      className={`${styles.modal} ${baseProps.className}`}
    >
      <div className={styles.wrapper}>
        <div className={styles.headline}>
          {title}
          <div className={styles.close} onClick={baseProps.onClose}>
            <Icon name="Close" />
          </div>
        </div>
        <div className={styles.content}>{children}</div>

        {!!bottomControls && (
          <div className={styles.bottom}>
            {bottomControls.map((el) => (
              <Button {...el} key={el.text}>
                {el.text}
              </Button>
            ))}
          </div>
        )}
      </div>
    </BaseModal>
  );
};

export default CommonModal;
