import React from "react";
import cn from "classnames";

import styles from "./CreateProfileModalSideBar.module.scss";
import { ProfileTypes } from "../../CreateProfileModal";

interface IProps {
  step: number;
  type: ProfileTypes;
}

const CreateProfileModalSideBar: React.FC<IProps> = ({ step, type }) => {
  const isIndividual = type === "INDIVIDUAL";

  const TITLE = isIndividual ? "CREATE INDIVIDUAL PROFILE" : `CREATE ${type}`;

  const STEP_1_TITLE = isIndividual
    ? "Personal"
    : `${type.toLowerCase()} Details`;

  const STEP_2_TITLE = isIndividual ? "Address" : `Beneficial Ownership`;

  const STEP_3_TITLE = isIndividual
    ? "Identity Verification"
    : `Signatory Details`;

  if (isIndividual) {
    return (
      <>
        <div className={styles.title}>{TITLE}</div>
        <div
          className={cn({
            [styles.step]: true,
            [styles.completed]: step > 0,
            [styles.active]: step === 0,
          })}
        >
          {STEP_1_TITLE}
        </div>
        <div
          className={cn({
            [styles.step]: true,
            [styles.completed]: step > 1,
            [styles.active]: step === 1,
          })}
        >
          {STEP_2_TITLE}
        </div>

        <div
          className={cn({
            [styles.step]: true,
            [styles.completed]: step > 2,
            [styles.active]: step === 2,
          })}
        >
          Consent
        </div>
        <div
          className={cn({
            [styles.step]: true,
            [styles.completed]: step > 3,
            [styles.active]: step === 3,
          })}
        >
          Confidential Information
        </div>
      </>
    );
  }

  return (
    <>
      <div className={styles.title}>{TITLE}</div>
      <div
        className={cn({
          [styles.step]: true,
          [styles.completed]: step > 0,
          [styles.active]: step === 0,
        })}
      >
        Types and Roles
      </div>
      <div
        className={cn({
          [styles.step]: true,
          [styles.completed]: step > 1,
          [styles.active]: step === 1,
        })}
      >
        {STEP_1_TITLE}
      </div>
      <div
        className={cn({
          [styles.step]: true,
          [styles.completed]: step > 2,
          [styles.active]: step === 2,
        })}
      >
        {STEP_2_TITLE}
      </div>
      {!isIndividual && (
        <div
          className={cn({
            [styles.step]: true,
            [styles.completed]: step > 3,
            [styles.active]: step === 3,
          })}
        >
          {STEP_3_TITLE}
        </div>
      )}
      <div
        className={cn({
          [styles.step]: true,
          [styles.completed]: step > 4,
          [styles.active]: step === 4,
        })}
      >
        Consent
      </div>
      <div
        className={cn({
          [styles.step]: true,
          [styles.completed]: step > 5,
          [styles.active]: step === 5,
        })}
      >
        Confidential Information
      </div>
    </>
  );
};

export default CreateProfileModalSideBar;
