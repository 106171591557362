import { useTypedSelector } from "app/store/useTypedSelector";
import TemplateBase from "features/template/TemplateBase/TemplateBase";
import { getOrgs } from "pages/Orgs/model/thunks";
import React, { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Icon from "shared/ui/atoms/Icon";
import Button from "shared/ui/controls/Button/Button";
import { Checkbox } from "shared/ui/inputs/Checkbox/Checkbox";
import FileInput from "shared/ui/inputs/FileInput/FileInput";
import Input from "shared/ui/inputs/Input/Input";

import styles from "./Organization.module.scss";

const Organization = () => {
  const { orgId } = useParams<Record<string, string>>();
  const dispatch = useDispatch();
  const history = useHistory();
  const { orgs } = useTypedSelector((state) => state.orgs);

  const org = useMemo(() => {
    return orgs?.find((el) => +el.id === +orgId);
  }, [orgId, orgs]);

  useEffect(() => {
    if (!!orgs?.length) return;
    dispatch(getOrgs());
  }, [orgs]);

  const onClose = () => {
    history.push("/orgs");
  };

  return (
    <TemplateBase title={org ? `Organizations - ${org?.name}` : ""}>
      <div className={styles.grid}>
        <Input label="Organization Name" value={org?.name} />
        <Input label="Subdomain" value={org?.subdomain} />
        <Checkbox label="Series Flag" />
        <div className={styles.row3}>
          <Input label="Master LLC" />
          <FileInput label="Master LLC Formation Document" />
          <Input label="Master LLC EIN Number" />
        </div>
        <Button variant="ghost">
          <Icon name="Plus" />
          New Master LLC
        </Button>
        <Input label="Website" value={org?.website} />
      </div>
      <div className={styles.bottom}>
        <Button variant="light" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={onClose}>Update</Button>
      </div>
    </TemplateBase>
  );
};

export default Organization;
