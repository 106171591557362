//@ts-nocheck
import React, { useContext } from "react";
import { countryOptions } from "shared/constants/countries";
import Dropdown, { DropdownProps } from "shared/ui/inputs/Dropdown";

import { GeoSelectsContext } from "./GeoSelectsContextProvider";

interface IProps extends DropdownProps {}

const CountrySelect: React.FC<IProps> = ({ onSelect, ...props }) => {
  const { setCountry } = useContext(GeoSelectsContext);

  return (
    <Dropdown
      {...props}
      items={countryOptions}
      onSelect={(val) => {
        onSelect(val);
        setCountry(val);
      }}
    />
  );
};

export default CountrySelect;
