//@ts-nocheck
import AddPersonCardBtn from "features/PersonCard/AddOwnerCardBtn";
import PersonCard from "features/PersonCard/OwnerCard";
import { ICreateProfile } from "pages/Profiles/model/types";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import LabeledItem from "shared/ui/atoms/LabeledItem/LabeledItem";
import { Checkbox } from "shared/ui/inputs/Checkbox/Checkbox";
import Input from "shared/ui/inputs/Input/Input";
import PercentageInput from "shared/ui/inputs/PercentageInput/PercentageInput";
import BottomControls from "../../ui/BottomControls/BottomControls";
import { ICreateProfileStepProps } from "../CreateProfileStep1/CreateProfileStep1";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";

import styles from "./CreateEntityStep2.module.css";
import Datepicker from "shared/ui/inputs/Datepicker/Datepicker";
import dayjs from "dayjs";
import { dateFormats } from "shared/constants/dateConstants";
import { scrollIntoView } from "shared/lib/utils/scrollIntoView";
import AddControlPerson from "features/PersonCard/AddControlPerson";
import ControlPersonCard from "features/PersonCard/ControlPersonCard";
import Dropdown from "shared/ui/inputs/Dropdown";
import { countryOptions } from "shared/constants/countries";

interface IProps extends ICreateProfileStepProps {
  onSubmit: (vals: Partial<ICreateProfile>) => void;
}

const schema = z.object({
  first_name: z.string().min(1, { message: "Required" }),
  last_name: z.string().min(1, { message: "Required" }),
  email: z.string().min(1, { message: "Email is required" }).email({
    message: "Must be a valid email",
  }),
  middle_name: z.optional(z.string()),
  date_of_birth: z.optional(z.string()),
  ssn: z.optional(z.string()),
  zipcode: z.optional(z.string()),
  country: z.string().min(1, { message: "Required" }),
  ownership_percentage: z.optional(z.string()),
  phone: z.string().min(1, { message: "Required" }),
});

const CreateEntityStep3: React.FC<IProps> = ({
  onCancel,
  isViewExisting,
  initialData,
  setStep,
  onSubmit,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
  } = useForm<Partial<ICreateProfile>>({
    reValidateMode: "onChange",
    defaultValues: initialData,
    resolver: zodResolver(schema),
    mode: "onChange",
  });

  const [additionals, setAdditiaonals] = useState<
    ICreateProfile["additional_owners"]
  >([]);
  const [controlPersons, setControlPersons] = useState<
    ICreateProfile["additional_owners"]
  >([]);

  const addOwner = (value: Partial<ICreateProfile["additional_owners"][0]>) => {
    setAdditiaonals((prev) => [...prev, { ...value } as any]);
  };

  const removeOwner = (index: number) => {
    setAdditiaonals((prev) => prev.filter((el, i) => i !== index));
  };

  const addControlPerson = (
    value: Partial<ICreateProfile["additional_owners"][0]>
  ) => {
    setControlPersons((prev) => [...prev, { ...value } as any]);
  };

  const removeControlPerson = (index: number) => {
    setControlPersons((prev) => prev.filter((el, i) => i !== index));
  };

  const editOwner = (field: string, value: string, index: number) => {
    setAdditiaonals((prev) =>
      prev.map((el, i) => {
        if (i === index) {
          return { ...el, [field]: value };
        } else {
          return el;
        }
      })
    );
  };

  useEffect(() => {
    if (initialData?.additional_owners) {
      setAdditiaonals(initialData.additional_owners);
    }
  }, [initialData]);

  const [country, setCountry] = useState<string>();

  return (
    <div>
      <div className={styles.block}>
        <div className={styles.part}>
          <div>
            <div className={styles.title}>Primary Beneficial Owner</div>
            <div className={styles.subtitle}>
              A primary beneficial owner owns and controls more than 25% of an
              entity through shares, voting, and/or equity, and will receive
              main communication and notifications regarding this entity. Please
              fill out the primary beneficial ownership information for your
              entity.
            </div>
          </div>

          <div className={styles.gridFormMain}>
            <Controller
              control={control}
              name="first_name"
              render={({ field }) => (
                <Input
                  {...field}
                  label="First Name"
                  placeholder="First Name"
                  disabled={isViewExisting}
                  onFocus={scrollIntoView}
                />
              )}
            />
            <Controller
              control={control}
              name="middle_name"
              render={({ field }) => (
                <Input
                  {...field}
                  label="Middle Name"
                  placeholder="Middle Name"
                  disabled={isViewExisting}
                  onFocus={scrollIntoView}
                />
              )}
            />
            <Controller
              control={control}
              name="last_name"
              render={({ field }) => (
                <Input
                  {...field}
                  label="Last Name"
                  placeholder="Last Name"
                  disabled={isViewExisting}
                  onFocus={scrollIntoView}
                />
              )}
            />
            <Controller
              control={control}
              name="email"
              render={({ field }) => (
                <Input
                  {...field}
                  label="Email Address"
                  placeholder="Email Address"
                  error={errors.email?.message}
                  disabled={isViewExisting}
                  onFocus={scrollIntoView}
                />
              )}
            />
            <Controller
              control={control}
              name="phone"
              render={({ field }) => (
                <Input
                  {...field}
                  label="Phone"
                  error={errors.phone?.message}
                  placeholder="Phone"
                  disabled={isViewExisting}
                  onFocus={scrollIntoView}
                />
              )}
            />
            <Controller
              control={control} /* @ts-ignore */
              name="ownership_percentage"
              render={({ field } /* @ts-ignore */) => (
                <PercentageInput
                  label="Ownership Percentage"
                  {...field}
                  disabled={isViewExisting}
                  onFocus={scrollIntoView}
                />
              )}
            />
            <Controller
              control={control}
              /* @ts-ignore */
              name="zipcode"
              render={({ field }) => (
                /* @ts-ignore */
                <Input
                  {...field}
                  label="Zipcode"
                  placeholder="Zipcode"
                  disabled={isViewExisting}
                  onFocus={scrollIntoView}
                />
              )}
            />
            <Controller
              control={control}
              name="country"
              render={({ field }) => (
                <Dropdown
                  {...field}
                  disabled={isViewExisting}
                  label="Country"
                  placeholder="Country"
                  items={countryOptions}
                  onSelect={(v) => {
                    field.onChange(v);
                    setCountry(v);
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="ssn"
              render={({ field }) => (
                <Input
                  {...field}
                  label="SSN"
                  placeholder="XXX-XX-XXXX"
                  disabled={isViewExisting}
                  onFocus={scrollIntoView}
                />
              )}
            />
            <Controller
              control={control}
              name="date_of_birth"
              render={({ field }) => (
                <Datepicker
                  {...field}
                  value={new Date(field.value)}
                  label="Date of Birth"
                  onChange={(v) =>
                    field.onChange(dayjs(v).format(dateFormats.day))
                  }
                  disabled={isViewExisting}
                />
              )}
            />
          </div>
        </div>
      </div>

      <div className={styles.block}>
        <div className={styles.part}>
          <div>
            <div className={styles.title}>Additional Beneficial Owners</div>
            <div className={styles.subtitle}>
              If there is no primary beneficial owner, a representative must be
              selected. Please note a beneficial owner owns and controls more
              than 25% of an entity through shares, voting and/or equity.
            </div>
          </div>
          <div className={styles.checkbox}>
            <Checkbox label="Agree to select an additional beneficial owner (Maximum of three beneficial owners)" />
          </div>
          <div className={styles.gridForm}>
            {additionals?.map((el, i) => (
              <PersonCard
                key={i + el.email}
                name={el.first_name + el.last_name}
                subtitle={el.type}
                id={i}
                onDelete={() => removeOwner(i)}
                disabled={isViewExisting}
              />
            ))}
            {additionals?.length < 3 && (
              <AddPersonCardBtn
                modalTitle="Add Beneficial Owner"
                onSubmit={addOwner}
                disabled={isViewExisting}
              />
            )}
          </div>
        </div>
      </div>

      <div className={styles.block}>
        <div className={styles.part}>
          <div>
            <div className={styles.title}>Control Person</div>
            <div className={styles.subtitle}>
              A control person has a significant responsibility to control and
              manage an entity. Please note that a maximum of three control
              persons can be selected.
            </div>
          </div>
          <div className={styles.checkbox}>
            <Checkbox label="Agree to select a control person; this is a required field if there are no beneficial owners." />
          </div>
          <div className={styles.gridForm}>
            {controlPersons?.map((el, i) => (
              <ControlPersonCard
                key={i + el.email}
                name={el.first_name + " " + el.last_name}
                subtitle={el.type}
                id={i}
                onDelete={() => removeControlPerson(i)}
                disabled={isViewExisting}
              />
            ))}
            {controlPersons?.length < 3 && (
              <AddControlPerson
                modalTitle="Add Control Person"
                onSubmit={addControlPerson}
                disabled={isViewExisting}
              />
            )}
          </div>
        </div>
      </div>
      {!isViewExisting && (
        <BottomControls
          disabled={!isValid}
          isViewExisting={isViewExisting}
          onCommonClick={handleSubmit((v: any) =>
            onSubmit({
              ...v,
              beneficary_owners: additionals,
              control_persons: controlPersons,
            } as any)
          )}
          onClickPrev={() => setStep((p) => p - 1)}
          onClickNext={() => setStep((p) => p + 1)}
        />
      )}
    </div>
  );
};

export default CreateEntityStep3;
