//@ts-nocheck
import { useTypedSelector } from "app/store/useTypedSelector";
import { ICreateProfile, IProfileDetail } from "pages/Profiles/model/types";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import Button from "shared/ui/controls/Button/Button";
import { Checkbox } from "shared/ui/inputs/Checkbox/Checkbox";

import BankAccount from "widgets/BankAccount/BankAccount";
import W9DocButton from "widgets/W9DocButton/W9DocButton";
import BottomControls from "../../ui/BottomControls/BottomControls";
import { ICreateProfileStepProps } from "../CreateProfileStep1/CreateProfileStep1";

import styles from "./CreateProfileStep5.module.css";

interface IProps extends ICreateProfileStepProps {
  onFinish: () => void;
  isViewExisting?: boolean;
  onCancel?: () => void;
  profileId?: number;
  detailProfile?: IProfileDetail;
  isIndividual: boolean;
}

const CraeteProfileStep5: React.FC<IProps> = ({
  setStep,
  onFinish,
  isViewExisting,
  onCancel,
  profileId,
  detailProfile,
  isIndividual,
}) => {
  const { isPending } = useTypedSelector((state) => state.profiles);
  const { inviteInfo } = useTypedSelector((state) => state.detailSpv);
  return (
    <div>
      <div className={styles.grid}>
        <div className={styles.block}>
          <div>
            <div className={styles.title}>Bank Account</div>
            <div className={styles.subtitle}>
              We will need you to link the bank account that you will use as the
              source of funding. We link accounts via Plaid and use that account
              to ACH funds at SPV close. We plan to offer wire and RTP in the
              future.
            </div>
          </div>
          <BankAccount profileId={profileId!} />
        </div>
        {/* {isViewExisting && !!profileId && (
          <W9DocButton profileId={profileId} isIndividual={isIndividual} />
        )} */}
      </div>
      {!isViewExisting && (
        <BottomControls
          onClickNext={() => {
            onFinish();
          }}
          onClickPrev={() => {
            if (isViewExisting) {
              onCancel?.();
            } else {
              setStep((prev) => prev - 1);
            }
          }}
          isFinish={!isViewExisting}
          isViewExisting={isViewExisting}
          isPending={isPending}
        />
      )}
    </div>
  );
};

export default CraeteProfileStep5;
