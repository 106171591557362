import { Dispatch } from "redux";
import { spvApi } from "./api";
import { spvActions } from "./slice";

export const getTransactions = (id: number) => (dispatch: Dispatch) => {
  spvApi.getTransactions(id).then(({ data }) => {
    dispatch(spvActions.setTransactions({ data: data.list, key: id }));
  });
};

export const getProfilesBySpv = (id: number) => (dispatch: Dispatch) => {
  spvApi.getProfilesBySpv(id).then(({ data }) => {
    dispatch(spvActions.setProfiles({ data: data, key: id }));
  });
};
