import { combineReducers } from "redux";
import authSlice from "features/auth/model/authSlice";
import profiles from "pages/Profiles/model/profilesSlice";
import bank from "widgets/BankAccount/model/bankSlice";
import spvCreating from "widgets/SpvModal/model/spvModalSlice";
import orgs from "pages/Orgs/model/orgsSlice";
import deals from "pages/Deals/model/slice";
import spv from "pages/SpvAdmin/model/slice";
import dataRoom from "pages/DataRoom/model/slice";
import investmentAdmin from "pages/InvestmentAdmin/model/slice";

export const appReducer = combineReducers({
  authSlice,
  profiles,
  bank,
  spvCreating,
  orgs,
  deals,
  spv,
  dataRoom,
  investmentAdmin,
});

export const rootReducer = (
  state: ReturnType<typeof appReducer>,
  action: any
): RootState => {
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof appReducer>;
