import React from "react";

import styles from "./Consent.module.css";

const ConsentText = () => {
  return (
    <div className={styles.text}>
      <h2>Offering Documents Legal Disclaimer</h2>
      <h3>Acknowledgment and Understanding Regarding Offering Documents</h3>
      <p>
        The undersigned (“Client”) hereby acknowledges and fully understands
        that the templates, drafts, forms, documents, and any and all materials
        related to offering documents (collectively, the “Offering Documents”)
        provided by Syndicately LLC (“Company”), its agents, employees, or
        representatives, are intended for general informational and educational
        purposes only. The Client acknowledges that such Offering Documents do
        not constitute legal advice, nor are they intended to be a substitute
        for legal advice from a qualified attorney.
      </p>
      <p>
        The Client represents and warrants that they have sought, or will seek,
        independent legal counsel regarding the appropriateness, legality,
        validity, and enforceability of the Offering Documents in connection
        with their specific circumstances and intended use. The Client
        understands that the laws and regulations governing securities,
        investments, and related matters are complex and subject to change, and
        that the Offering Documents may not be tailored to their particular
        needs, objectives, or legal requirements.
      </p>
      <p>
        By using the Offering Documents, the Client agrees and acknowledges that
        the Company is not providing legal advice or services, and the provision
        of the Offering Documents does not create an attorney-client
        relationship between the Company and the Client. The Client agrees not
        to rely on the Offering Documents as a substitute for legal advice from
        a qualified attorney.
      </p>
      <h3>Limitation of Liability</h3>
      <p>
        The Client hereby agrees to hold the Company, its officers, directors,
        employees, agents, and representatives harmless and releases them from
        any and all liability, claims, damages, losses, costs, and expenses,
        including but not limited to attorneys' fees, arising from or related to
        the Client's use of the Offering Documents. The Client understands and
        agrees that the Company shall not be liable for any direct, indirect,
        incidental, special, consequential, or exemplary damages, including but
        not limited to damages for loss of profits, goodwill, use, data, or
        other intangible losses, even if the Company has been advised of the
        possibility of such damages.
      </p>
      <h3>No Warranty</h3>
      <p>
        The Company provides the Offering Documents on an “as is” and “as
        available” basis without any warranties of any kind, either express or
        implied, including, but not limited to, the implied warranties of
        merchantability, fitness for a particular purpose, or non-infringement.
        The Company does not warrant the accuracy, completeness, or usefulness
        of the Offering Documents and any reliance placed on such materials is
        strictly at the Client's own risk.
      </p>
      <h3>Acknowledgment</h3>
      <p>
        By using the Offering Documents, the Client acknowledges that they have
        read, understood, and agree to be bound by this Legal Disclaimer. The
        Client further acknowledges that this Legal Disclaimer has been fully
        explained to them and that they have had the opportunity to consult with
        legal counsel of their choice regarding its terms.
      </p>
    </div>
  );
};

export default ConsentText;
