import { ISpv } from "pages/Deals/model/types";
import React, { useState } from "react";
import Icon from "shared/ui/atoms/Icon";
import Status from "shared/ui/atoms/Status/Status";
import Button from "shared/ui/controls/Button/Button";
import Block from "shared/ui/layout/Block/Block";
import avatar from "./avatar.png";
import EditSpvAdminOverview from "./EditSpvAdminOverview/EditSpvAdminOverview";

import styles from "./SpvAdminOverview.module.scss";

interface IProps {
  spv: ISpv;
}

const SpvAdminOverview: React.FC<IProps> = ({ spv }) => {
  const [isEditing, setIsEditing] = useState(false);

  if (isEditing) {
    return <EditSpvAdminOverview onClose={() => setIsEditing(false)} />;
  }

  return (
    <div className={styles.layout}>
      <div className={styles.controls}>
        <Button onClick={() => setIsEditing(true)}>Edit Details</Button>
      </div>
      <div className={styles.content}>
        <Block title={`SPV Setup - ${spv.name}`} className={styles.mainBlock}>
          <div className={styles.table}>
            <div>
              <div className={styles.cell}>
                <span>LLC Type</span>
                <b>{spv.llc_type}</b>
              </div>
              <div className={styles.cell}>
                <span>Target Portfolio Company</span>
                <b>Apollo</b>
              </div>
              <div className={styles.cell}>
                <span>Portfolio Company Legal Entity</span>
                <b>Zenleads Inc.</b>
              </div>
              <div className={styles.cell}>
                <span>Portfolio Company Website</span>
                <a href="https://website.com" target="_blank" rel="noreferrer">
                  <b>website.com</b>
                </a>
              </div>
              <div className={styles.cell}>
                <span>SPV Legal Entity Name</span>
                <b>AVTAR LLC - IL LLC</b>
              </div>
            </div>
            <div>
              <div className={styles.cell}>
                <span>SPV Target Value</span>
                <b>$150,000.00</b>
              </div>
              <div className={styles.cell}>
                <span>Can SPV Go Over Target</span>
                <b>Yes</b>
              </div>
              <div className={styles.cell}>
                <span>Type of Investment</span>
                <b>Equity</b>
              </div>
              <div className={styles.cell}>
                <span>SPV Target Close Date</span>
                <b>07-06-2021</b>
              </div>
            </div>
          </div>
        </Block>
        <div className={styles.secondCol}>
          <Block title="Manager Profile">
            <div className={styles.avatar}>
              <img src={avatar} alt="avatar" />
            </div>
            <div className={styles.cell}>
              <span>First Name</span>
              <b>Ross</b>
            </div>
            <div className={styles.cell}>
              <span>Last Name</span>
              <b>Andrews</b>
            </div>
            <div className={styles.cell}>
              <span>Email</span>
              <a href="mailto:Ross@email.com">
                <b>Ross@email.com</b>
              </a>
            </div>
            <div className={styles.cell}>
              <span>Phone</span>
              <b>123 456 7890</b>
            </div>
            <div className={styles.cell}>
              <div className={styles.viewManager}> View Manager Profile</div>
            </div>
          </Block>
          <Block title="SPV Details">
            <div className={styles.cell}>
              <span>SPV Legal Name</span>
              <b>RGA SPV</b>
            </div>
            <div className={styles.cell}>
              <span>Status</span>
              <Status colorBg="#FFF2F2" colorText="#720202">
                Closed
              </Status>
            </div>
          </Block>
        </div>
      </div>
    </div>
  );
};

export default SpvAdminOverview;
