import React, { useEffect, useState } from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import { useTypedSelector } from "app/store/useTypedSelector";
import styles from "./Auth.module.css";
import SignIn from "./SignIn/SignIn";
import SignUp, { Roles } from "./SignUp/SignUp";
import AuthSignUpPromo from "./AuthPromo/AuthSignUpPromo";

const Auth = () => {
  const match = useRouteMatch();
  const history = useHistory();
  const [role, setRole] = useState<Roles>("manager");

  const isAuth = useTypedSelector((state) => state.authSlice.isAuth);

  useEffect(() => {
    if (isAuth) {
      history.push("/");
    }
  }, [isAuth]);

  return (
    <div className={styles.page}>
      <div className={styles.left}>
        <Switch>
          <Route path={`${match.path}/signin`}>
            <SignIn />
          </Route>
        </Switch>
      </div>
      <div className={styles.right}>
        <AuthSignUpPromo role={role} />
      </div>
    </div>
  );
};

export default Auth;
