import { useTypedSelector } from "app/store/useTypedSelector";
import TemplateBase from "features/template/TemplateBase/TemplateBase";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Icon from "shared/ui/atoms/Icon";
import Button from "shared/ui/controls/Button/Button";
import ThreeDots from "shared/ui/controls/ThreeDots/ThreeDots";
import Search from "shared/ui/inputs/Search/Search";
import Toggle from "shared/ui/inputs/Toggle";
import Table from "shared/ui/layout/Table";
import { getOrgs } from "./model/thunks";
import { IOrg } from "./model/types";

import styles from "./Orgs.module.scss";

const Orgs = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const { orgs } = useTypedSelector((state) => state.orgs);

  useEffect(() => {
    dispatch(getOrgs());
  }, []);

  const searchedOrgs = useMemo(() => {
    return (
      orgs?.filter((el) =>
        el.name.toLowerCase().includes(search.toLowerCase())
      ) ?? []
    );
  }, [search, orgs]);

  return (
    <TemplateBase title="Organizations">
      <div className={styles.searchBar}>
        <Search
          placeholder="Search by profile"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        {/* <Button className={styles.add}>
          <Icon name="Plus" />
          Add user
        </Button> */}
      </div>
      <Table<IOrg>
        data={searchedOrgs}
        columns={[
          { title: "Name", dataIndex: "name" },
          { title: "Subdomain", dataIndex: "subdomain" },
          {
            title: "Website",
            dataIndex: "website",
            render: (link) => (
              <a href={link} target="_blank" rel="noreferrer">
                {link}
              </a>
            ),
          },
          {
            title: "API Access",
            dataIndex: "api_allowed",
            render: (status) => <Toggle checked={!!status} />,
          },
          {
            title: "",
            dataIndex: "id",
            render: (id) => (
              <ThreeDots
                items={[
                  {
                    text: "Edit",
                    icon: "EditPencil",
                    onClick: () => {
                      history.push(`/orgs/${id}`);
                    },
                  },
                ]}
              />
            ),
          },
        ]}
      />
    </TemplateBase>
  );
};

export default Orgs;
