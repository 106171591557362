//@ts-nocheck
import ConsentText from "features/Consent/ConsentText";
import React, { useMemo, useState } from "react";
import { Checkbox } from "shared/ui/inputs/Checkbox/Checkbox";
import BottomControls from "../../ui/BottomControls/BottomControls";
import { ICreateProfileStepProps } from "../CreateProfileStep1/CreateProfileStep1";

import styles from "./ConsentStep.module.css";

interface IProps extends ICreateProfileStepProps {}

const ConsentStep: React.FC<IProps> = ({ setStep }) => {
  const [disbled, setDisabled] = useState(true);

  return (
    <>
      <div className={styles.root}>
        <div className={styles.text}>
          <ConsentText />
        </div>
        <Checkbox
          label="I have read, understood the Legal Disclaimer Document and I accept."
          checked={!disbled}
          onChange={(e) => setDisabled(!e.target.checked)}
        />
      </div>
      <BottomControls
        onClickPrev={() => {
          setStep((prev) => prev - 1);
        }}
        disabled={disbled}
        onClickNext={() => {
          setStep((prev) => prev + 1);
        }}
      />
    </>
  );
};

export default ConsentStep;
