export default async function downloadFileByUrl(
  url: string,
  filename: string,
  successCallback?: () => void
) {
  const file = await fetch(url);
  const fileBlob = await file.blob();
  const fileURL = URL.createObjectURL(fileBlob);

  const anchor = document.createElement("a");
  anchor.href = fileURL;
  anchor.download = filename;

  document.body.appendChild(anchor);
  anchor.click();
  //@ts-ignore
  URL.revokeObjectURL(fileBlob);
  document.body.removeChild(anchor);
  successCallback?.();

  /* try {
    const response = await fetch(url);

    if (response.ok) {
      const blob = await response.blob();

      // Create a link element to trigger the download
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Remove the link element
      document.body.removeChild(link);
      successCallback?.();
    } else {
      console.error("Failed to fetch the file:", response.statusText);
    }
  } catch (error) {
    console.error("Error during file download:", error);
  } */
}
