import React from "react";
import cn from "classnames";

import styles from "./ViewProfileModalSideBar.module.css";
import Icon from "shared/ui/atoms/Icon";

import { IProfileResponse } from "pages/Profiles/model/types";
import ModalSidebar from "shared/ui/modals/ModalSidebar/ModalSidebar";

interface IProps {
  step: number;
  isIndividual: boolean;
  onChangeStep: (step: number) => void;
  profileInfo: IProfileResponse;
}

const ViewProfileModalSideBar: React.FC<IProps> = ({
  step,
  isIndividual,
  onChangeStep,
  profileInfo,
}) => {
  return (
    <>
      {isIndividual && (
        <div className={styles.individualHeader}>
          <div className={styles.avatar}>
            <Icon name="Prodile" />
          </div>
          <div>
            <div className={styles.name}>
              {profileInfo.first_name + " " + profileInfo.last_name}
            </div>
            <div className={styles.email}>john@email.com</div>
          </div>
        </div>
      )}
      {isIndividual && (
        <ModalSidebar
          variant="existing"
          title="INDIVIDUAL INFORMATION"
          activeIndex={step}
          onClick={onChangeStep}
          items={[
            { text: "Personal", icon: "Prodile" },
            { text: "Address", icon: "Geo" },
          ]}
        />
      )}
      {!isIndividual && (
        <ModalSidebar
          variant="existing"
          title="ENTITY INFORMATION"
          activeIndex={step}
          onClick={onChangeStep}
          items={[
            { text: "Personal", icon: "Prodile" },
            { text: "Address", icon: "Geo" },
          ]}
        />
      )}
    </>
  );
};

export default ViewProfileModalSideBar;
