import classNames from "classnames";
import React, { useRef, useState } from "react";
import useOnClickOutside from "shared/lib/hooks/useOnClickOutside";
import Icon, { IconNames } from "shared/ui/atoms/Icon";

import styles from "./ThreeDots.module.scss";

interface IProps {
  items: { text: React.ReactNode; onClick: () => void; icon?: IconNames }[];
}

const ThreeDots: React.FC<IProps> = ({ items }) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isOpened, setIsOpened] = useState(false);

  useOnClickOutside(ref, () => setIsOpened(false));

  return (
    <div
      className={classNames(styles.container, {
        [styles.openedContainer]: isOpened,
      })}
      ref={ref}
    >
      <div
        className={classNames({
          [styles.btn]: true,
          [styles.opened]: isOpened,
        })}
        onClick={() => setIsOpened((p) => !p)}
      >
        <Icon name="ThreeDots" />
      </div>
      {isOpened && (
        <div className={styles.list} onClick={() => setIsOpened(false)}>
          {items.map((el) => (
            <div
              key={String(el.text)}
              className={styles.item}
              onClick={el.onClick}
            >
              {!!el.icon && <Icon name={el.icon} />}
              {el.text}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ThreeDots;
