import React, { FC, ReactNode } from "react";
import cn from "classnames";

import styles from "./Tabs.module.scss";

interface IProps {
  tabs: { id: string; name: ReactNode }[];
  activeTab: string;
  onClick: (tab: string) => void;
}

const Tabs: FC<IProps> = ({ tabs, activeTab, onClick }) => {
  return (
    <div className={styles.tabs}>
      {tabs.map((el) => (
        <div
          key={el.id}
          className={cn({
            [styles.tab]: true,
            [styles.active]: activeTab === el.id,
          })}
          onClick={() => onClick(el.id)}
        >
          {el.name}
        </div>
      ))}
    </div>
  );
};

export default Tabs;
