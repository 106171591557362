import React from "react";
import { Provider } from "react-redux";
import store from "./../store/store";
import { BrowserRouter } from "react-router-dom";
import { ReactNotifications } from "react-notifications-component";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

interface IProps {
  children: React.ReactNode;
}

const Providers: React.FC<IProps> = ({ children }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Provider store={store}>
        <ReactNotifications />
        <BrowserRouter>{children}</BrowserRouter>
      </Provider>
    </LocalizationProvider>
  );
};

export default Providers;
