import { Dispatch } from "redux";
import { orgsApi } from "./api";
import { orgsActions } from "./orgsSlice";

export const getOrgs = () => (dispatch: Dispatch) => {
  //loading
  orgsApi
    .get()
    .then(({ data }) => {
      dispatch(orgsActions.setOrgs(data.list));
    })
    .finally(() => {
      //loading
    });
};
