import React from "react";
import Icon, { IconNames } from "shared/ui/atoms/Icon";
import Block from "shared/ui/layout/Block/Block";

import styles from "./Amount.module.scss";

interface IProps {
  icon: IconNames;
  value: React.ReactNode;
  title: string;
}

const Amount: React.FC<IProps> = ({ icon, value, title }) => {
  return (
    <Block>
      <div className={styles.flex}>
        <div className={styles.icon}>
          <Icon name={icon} />
        </div>
        <div>
          <div className={styles.title}>{title}</div>
          <div className={styles.value}>{value}</div>
        </div>
      </div>
    </Block>
  );
};

export default Amount;
