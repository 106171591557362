import React, { useRef, useState } from "react";
import Icon from "shared/ui/atoms/Icon";
import Status from "shared/ui/atoms/Status/Status";
import Button from "shared/ui/controls/Button/Button";
import BaseModal from "shared/ui/modals/BaseModal/BaseModal";
import CommonModal from "shared/ui/modals/CommonModal/CommonModal";

import styles from "./DocumentUploadItem.module.scss";

interface IProps {
  btnText?: string;
  text: string;
}

const DocumentUploadItem: React.FC<IProps> = ({ text, btnText = "Upload" }) => {
  const [isModal, setIsModal] = useState(false);
  const [files, setFiles] = useState<File[]>([]);

  const onDrop: React.DragEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    let files: File[];
    if (e.dataTransfer.items) {
      files = Array.from(e.dataTransfer.items)
        .filter((el) => el.kind === "file")
        .map((el) => el.getAsFile()!);
    } else {
      files = Array.from(e.dataTransfer.files);
    }
    setFiles(files);
  };

  const inputRef = useRef<HTMLInputElement>(null);

  const onClick = () => {
    inputRef.current?.click();
  };

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    if (!e.target.files) return;
    const newFiles = Array.from(e.target.files!);
    setFiles((prev) => [...prev, ...newFiles]);
  };

  const onDelete = (idx: number) => {
    setFiles((prev) => prev.filter((el, i) => i !== idx));
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.left}>
        <Status colorBg="#FFF2F2" colorText="#720202">
          Missing
        </Status>
        <div className={styles.text}>{text}</div>
      </div>
      <Button onClick={() => setIsModal(true)}>{btnText}</Button>
      <CommonModal
        isOpen={isModal}
        onClose={() => setIsModal(false)}
        className={styles.modal}
        title="Upload Entity Filing Document"
        bottomControls={[
          { text: "Cancel", variant: "light" },
          { text: "Import" },
        ]}
      >
        <div className={styles.content}>
          <div
            className={styles.dropzone}
            onDragOver={(e) => e.preventDefault()}
            onDrop={onDrop}
            onClick={onClick}
          >
            <Icon name="DropZone" />
            <p>
              Drag document here <br />
              or <span>browse files</span>
            </p>
          </div>
          <input
            type="file"
            className={styles.input}
            ref={inputRef}
            onChange={onChange}
          />
          <div className={styles.hint}>
            <Icon name="Info" />
            Maximum file size 5 MB. File format must be .PDF, .DOC ,or .XML
          </div>
          {files?.map((el, i) => (
            <div className={styles.file} key={el.name + i}>
              <Icon name="CheckInCircle" />
              <span>{el.name}</span>
              <div className={styles.fileDelete} onClick={() => onDelete(i)}>
                <Icon name="Delete" />
              </div>
            </div>
          ))}
        </div>
      </CommonModal>
    </div>
  );
};

export default DocumentUploadItem;
