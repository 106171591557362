import { useMemo, useState } from "react";

const filterData = <T, K extends keyof T>(
  data: T[],
  search: string,
  param: K
): T[] => {
  return (
    data?.filter((el) => {
      if (typeof el[param] !== "string" && typeof el[param] !== "number") {
        return false;
      } else {
        return String(el[param])
          .trim()
          .toLowerCase()
          .includes(String(search).trim().toLowerCase());
      }
    }) ?? []
  );
};

export const useSearch = <T, K extends keyof T>(data: T[], param: K) => {
  const [search, setSearch] = useState("");

  const filteredData = useMemo(() => {
    return filterData(data, search, param);
  }, [search, param, data]);

  return {
    search,
    setSearch,
    filteredData,
  };
};
