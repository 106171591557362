//@ts-nocheck
import { ICreateProfile } from "pages/Profiles/model/types";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Input from "shared/ui/inputs/Input/Input";
import BottomControls from "../../ui/BottomControls/BottomControls";
import { ICreateProfileStepProps } from "../CreateProfileStep1/CreateProfileStep1";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";

import styles from "./CreateEntityStep2.module.css";
import { Checkbox } from "shared/ui/inputs/Checkbox/Checkbox";
import { formatText } from "shared/lib/utils/formatText";
import { scrollIntoView } from "shared/lib/utils/scrollIntoView";

import GeoSelectsContextProvider from "features/GeoSelects/GeoSelectsContextProvider";
import CountrySelect from "features/GeoSelects/CountrySelect";
import StateSelect from "features/GeoSelects/StateSelect";

interface IProps extends ICreateProfileStepProps {
  onSubmit: (vals: Partial<ICreateProfile>) => void;
}

const schema = z.object({
  entity: z.object({
    name: z.string().min(1, { message: "Required" }),
    ein: z.string().min(1, { message: "Required" }),
  }),
  address: z.object({
    name: z.optional(z.string()),
    city: z.optional(z.string()),
    sof: z.optional(z.string()),
    zipcode: z.optional(z.string()),
    country: z.string().min(1, { message: "Required" }),
    state: z.optional(z.string()),
    address_1: z.optional(z.string()),
    address_2: z.optional(z.string()),
  }),
  mailing: z.optional(
    z.object({
      name: z.optional(z.string()),
      city: z.optional(z.string()),
      sof: z.optional(z.string()),
      zipcode: z.optional(z.string()),
      country: z.optional(z.string()),
      state: z.optional(z.string()),
      address_1: z.optional(z.string()),
      address_2: z.optional(z.string()),
    })
  ),
  requires_entity_formation: z.optional(z.boolean()),
});

const CreateEntityStep2: React.FC<IProps> = ({
  onCancel,
  isViewExisting,
  initialData,
  setStep,
  onSubmit,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    getValues,
  } = useForm<Partial<ICreateProfile>>({
    reValidateMode: "onBlur",
    defaultValues: initialData,
    resolver: zodResolver(schema),
    mode: "onBlur",
  });

  const [isMailingSame, setIsMailingSame] = useState(false);

  const [country, setCountry] = useState<string>();

  return (
    <div>
      <div className={styles.block}>
        <div className={styles.part}>
          <div>
            <div className={styles.title}>Entity Information</div>
            <div className={styles.subtitle}>
              Please fill out the address information of your entity.
            </div>
          </div>
          <div className={styles.row2items}>
            <Controller
              control={control}
              name="entity.name"
              render={({ field }) => (
                <Input
                  {...field}
                  label="Entity Name"
                  placeholder="Syndicately"
                  error={errors.entity?.name?.message}
                  disabled={isViewExisting}
                />
              )}
            />
            <Controller
              control={control}
              name="entity.ein"
              render={({ field }) => (
                <Input
                  {...field}
                  placeholder="XX-XXXXXXX"
                  label="EIN Number"
                  error={errors.entity?.ein?.message}
                  format={formatText.ein}
                  disabled={isViewExisting}
                  onFocus={scrollIntoView}
                />
              )}
            />
          </div>
          <div className={styles.row2items}>
            <Controller
              control={control}
              name="requires_entity_formation"
              render={({ field }) => (
                <Checkbox
                  label="Form a new Entity to use for investment"
                  value={field.value === true}
                  onChange={(e) => field.onChange(e.target.checked)}
                  disabled={isViewExisting}
                  onFocus={scrollIntoView}
                />
              )}
            />
          </div>
        </div>
        <GeoSelectsContextProvider
          onChangeCountry={() => {
            setValue("address.state", "");
            setValue("address.sof", "");
          }}
        >
          <div className={styles.part}>
            <div>
              <div className={styles.title}>Entity Address Information</div>
              <div className={styles.subtitle}>
                Please fill out the address information of your entity
              </div>
            </div>
            <div className={styles.row2items}>
              <Controller
                control={control}
                name="address.address_1"
                render={({ field }) => (
                  <Input
                    {...field}
                    label="Entity Address 1"
                    placeholder="Entity Address 1"
                    error={errors.entity_address?.message}
                    disabled={isViewExisting}
                    onFocus={scrollIntoView}
                  />
                )}
              />
              <Controller
                control={control}
                name="address.city"
                render={({ field }) => (
                  <Input
                    {...field}
                    label="Entity City"
                    placeholder="Entity City"
                    error={errors.entity_city?.message}
                    disabled={isViewExisting}
                    onFocus={scrollIntoView}
                  />
                )}
              />
            </div>
            <div className={styles.row3items}>
              <Controller
                control={control}
                name="address.address_2"
                render={({ field }) => (
                  <Input
                    {...field}
                    label="Entity Address 2"
                    placeholder="Entity Address 2"
                    error={errors.entity_address?.message}
                    disabled={isViewExisting}
                    onFocus={scrollIntoView}
                  />
                )}
              />
              <Controller
                control={control}
                name="address.zipcode"
                render={({ field }) => (
                  <Input
                    {...field}
                    label="Entity Zipcode"
                    placeholder="Entity Zipcode"
                    error={errors.entity_zipcode?.message}
                    disabled={isViewExisting}
                    onFocus={scrollIntoView}
                  />
                )}
              />
              <Controller
                control={control}
                name="address.country"
                render={({ field }) => (
                  <CountrySelect
                    {...field}
                    label="Entity Country"
                    placeholder="Entity Country"
                    error={errors.entity_country?.message}
                    disabled={isViewExisting}
                    onSelect={(v) => {
                      field.onChange(v);
                    }}
                  />
                )}
              />
            </div>
            <div className={styles.row2equals}>
              <Controller
                control={control}
                name="address.state"
                render={({ field }) => (
                  <StateSelect
                    {...field}
                    label="Entity State"
                    placeholder="Entity State"
                    error={errors.entity_city?.message}
                    disabled={isViewExisting}
                    onFocus={scrollIntoView}
                    onSelect={field.onChange}
                  />
                )}
              />
              <Controller
                control={control}
                name="address.sof"
                render={({ field }) => (
                  <StateSelect
                    {...field}
                    label="Entity State of Formation"
                    placeholder="Entity State of Formation"
                    error={errors.entity_city?.message}
                    disabled={isViewExisting}
                    onFocus={scrollIntoView}
                    onSelect={field.onChange}
                  />
                )}
              />
            </div>
            <div className={styles.row2items}>
              <Checkbox
                label="Mailing address is same as entity address"
                value={isMailingSame}
                disabled={isViewExisting}
                onFocus={scrollIntoView}
                onChange={(e) => {
                  scrollIntoView(e);
                  const val = e.target.checked;
                  setIsMailingSame(val);
                  const values = getValues();
                  if (val) {
                    setValue("mailing", {
                      ...(values.address || {}),
                    });
                  }
                }}
              />
            </div>
          </div>
        </GeoSelectsContextProvider>
      </div>
      <GeoSelectsContextProvider>
        <div
          className={styles.block}
          key={String(isMailingSame) + Math.random()}
        >
          <div className={styles.part}>
            <div>
              <div className={styles.title}>
                Entity Mailing Address Information
              </div>
              <div className={styles.subtitle}>
                Please fill out the mailing address information of your entity.
                If there is no separate mailing address, please close this
                widget.
              </div>
            </div>

            <div className={styles.row2items}>
              <Controller
                control={control}
                name={isMailingSame ? "address.address_1" : "mailing.address_1"}
                render={({ field }) => (
                  <Input
                    {...field}
                    label="Entity Address 1"
                    placeholder="Entity Address 1"
                    disabled={isMailingSame || isViewExisting}
                    onFocus={scrollIntoView}
                  />
                )}
              />
              <Controller
                control={control}
                name={isMailingSame ? "address.city" : "mailing.city"}
                render={({ field }) => (
                  <Input
                    {...field}
                    label="Entity City"
                    placeholder="Entity City"
                    disabled={isMailingSame || isViewExisting}
                    onFocus={scrollIntoView}
                  />
                )}
              />
            </div>
            <div className={styles.row3items}>
              <Controller
                control={control}
                name={isMailingSame ? "address.address_2" : "mailing.address_2"}
                render={({ field }) => (
                  <Input
                    {...field}
                    label="Entity Address 2"
                    placeholder="Entity Address 2"
                    disabled={isMailingSame || isViewExisting}
                    onFocus={scrollIntoView}
                  />
                )}
              />
              <Controller
                control={control}
                name={isMailingSame ? "address.zipcode" : "mailing.zipcode"}
                render={({ field }) => (
                  <Input
                    {...field}
                    label="Entity Zipcode"
                    placeholder="Entity Zipcode"
                    disabled={isMailingSame || isViewExisting}
                    onFocus={scrollIntoView}
                  />
                )}
              />
              <Controller
                control={control}
                name={isMailingSame ? "address.country" : "mailing.country"}
                render={({ field }) => (
                  <CountrySelect
                    {...field}
                    label="Entity Country"
                    placeholder="Entity Country"
                    disabled={isMailingSame || isViewExisting}
                    onFocus={scrollIntoView}
                    onSelect={(v) => {
                      field.onChange(v);
                    }}
                  />
                )}
              />
            </div>
            <div className={styles.row2equals}>
              <Controller
                control={control}
                name={isMailingSame ? "address.state" : "mailing.state"}
                render={({ field }) => (
                  <StateSelect
                    {...field}
                    label="Entity State"
                    placeholder="Entity State"
                    error={errors.entity_city?.message}
                    disabled={isMailingSame || isViewExisting}
                    onFocus={scrollIntoView}
                    onSelect={field.onChange}
                    externalCountryTrigger={isMailingSame}
                    getExternalCountry={() => getValues().address.country}
                  />
                )}
              />
              {/* <Controller
              control={control}
              name={isMailingSame ? "address.sof" : "mailing.sof"}
              render={({ field }) => (
                <Input
                  {...field}
                  label="Entity State of Formation"
                  placeholder="Entity State of Formation"
                  error={errors.entity_city?.message}
                  disabled={isMailingSame}
                />
              )}
            /> */}
            </div>
          </div>
        </div>
      </GeoSelectsContextProvider>
      {!isViewExisting && (
        <BottomControls
          disabled={!isValid}
          isViewExisting={isViewExisting}
          onCommonClick={handleSubmit((v) => {
            if (isMailingSame) {
              onSubmit({ ...v, mailing: v.address });
            } else {
              onSubmit(v);
            }
          })}
          onClickPrev={() => setStep((p) => p - 1)}
          onClickNext={() => setStep((p) => p + 1)}
        />
      )}
    </div>
  );
};

export default CreateEntityStep2;
