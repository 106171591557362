import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IBankAccountInfo } from "./types";

interface BankState {
  token: string;
  bankData: IBankAccountInfo | null;
  isDataLoading?: boolean;
  isPending: boolean;
}

const initialState: BankState = {
  token: "",
  bankData: null,
  isDataLoading: false,
  isPending: false,
};

const bankSlice = createSlice({
  name: "bank",
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setInfo: (state, action: PayloadAction<IBankAccountInfo>) => {
      state.bankData = action.payload;
    },
    setDataLoading: (state, action: PayloadAction<boolean>) => {
      state.isDataLoading = action.payload;
    },
    setPending: (state, action: PayloadAction<boolean>) => {
      state.isPending = action.payload;
    },
  },
});

export const bankActions = bankSlice.actions;

export default bankSlice.reducer;
