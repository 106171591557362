import { useTypedSelector } from "app/store/useTypedSelector";

import dayjs from "dayjs";
import TemplateBase from "features/template/TemplateBase/TemplateBase";

import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { dateFormats } from "shared/constants/dateConstants";
import Icon from "shared/ui/atoms/Icon";
import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import Status from "shared/ui/atoms/Status/Status";
import Button from "shared/ui/controls/Button/Button";

import ThreeDots from "shared/ui/controls/ThreeDots/ThreeDots";

import Search from "shared/ui/inputs/Search/Search";
import Block from "shared/ui/layout/Block/Block";
import Table from "shared/ui/layout/Table";

import { profilesActions } from "./model/profilesSlice";
import {
  deleteUserClient,
  getAdminProfiles,
  getProfileStats,
} from "./model/thunks";

import styles from "./Profiles.module.scss";
import CreateProfileModal from "./widgets/CreateProfileModal/CreateProfileModal";
import ViewProfileModal from "./widgets/CreateProfileModal/ViewProfileModal";
import ProfileSpvSlide from "./widgets/ProfileSpvSlide/ProfileSpvSlide";

const ClientUsers = () => {
  const dispatch = useDispatch();
  const [isCreatingModal, setIsCreatingModal] = useState(false);
  const [search, setSearch] = useState("");

  const { profiles, invalidateKey, profilesStats, deletingIds } =
    useTypedSelector((state) => state.profiles);

  useEffect(() => {
    dispatch(getAdminProfiles());
    dispatch(getProfileStats());
  }, [invalidateKey]);

  useEffect(() => {
    dispatch(profilesActions.setDeletingIds({}));
  }, [profiles]);

  const [openedSpvId, setOpenedSpvId] = useState(0);

  const searchedProfiles = useMemo(() => {
    return (
      profiles?.filter(
        (el) =>
          el.first_name.toLowerCase().includes(search.toLowerCase()) ||
          el.last_name.toLowerCase().includes(search.toLowerCase())
      ) ?? []
    );
  }, [profiles, search]);

  const [openedId, setOpenedId] = useState(-1);

  return (
    <TemplateBase
      title="Client Users"
      /*  additionalHeader={
        <Button onClick={() => setIsCreatingModal(true)}>
          <Icon name="Plus" />
          Create profile
        </Button>
      } */
    >
      <div className={styles.counts}>
        <Block>
          <div className={styles.count}>
            <div className={styles.countIcon}>
              <Icon name="MultiProfiles" />
            </div>
            <div>
              <div className={styles.countTitle}>Total Investors</div>
              <div className={styles.countSibtitle}>
                {profilesStats?.total_users}
              </div>
            </div>
          </div>
        </Block>
        <Block>
          <div className={styles.count}>
            <div className={styles.countIcon}>
              <Icon name="ProfilePlus" />
            </div>
            <div>
              <div className={styles.countTitle}>
                Investors Added Last Month
              </div>
              <div className={styles.countSibtitle}>
                {profilesStats?.total_recent}
              </div>
            </div>
          </div>
        </Block>
        <Block>
          <div className={styles.count}>
            <div className={styles.countIcon}>
              <Icon name="MultiProfiles" />
            </div>
            <div>
              <div className={styles.countTitle}>Overall Investment</div>
              <div className={styles.countSibtitle}>
                ${profilesStats?.total_investment}
              </div>
            </div>
          </div>
        </Block>
      </div>
      <div className={styles.searchBar}>
        <Search
          placeholder="Search by profile"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        <Button className={styles.add}>
          <Icon name="Plus" />
          Add client user
        </Button>
      </div>

      <Table<any>
        onRowClick={(row) => setOpenedId(row.id)}
        data={searchedProfiles}
        columns={[
          {
            title: "Name",
            dataIndex: "Name",
            render: (_, record) =>
              deletingIds[record.id] ? (
                <Spinner isSmallGray isStatic />
              ) : (
                record.first_name + " " + record.last_name
              ),
          },
          {
            title: "Email",
            dataIndex: "email",
            render: (_, record) => record.email ?? "None",
          },
          {
            title: "Type",
            dataIndex: "type",
            //render: (_, record) => record.email ?? "None",
          },
          {
            title: "Role",
            dataIndex: "role",
            render: (role) => (
              <Status colorBg="#F6F5FF" colorText="#564BC6">
                {role ?? "None"}
              </Status>
            ),
          },
          {
            title: "Creation Date",
            dataIndex: "created_at",
            render: (date) => dayjs(date).format(dateFormats.dayWithHours),
          },
          {
            title: "",
            dataIndex: "id",
            render: (id) => (
              <div className={styles.actions}>
                <ThreeDots
                  items={[
                    {
                      text: "Delete",
                      icon: "Delete",
                      onClick: () => {
                        dispatch(deleteUserClient(+id!));
                      },
                    },
                  ]}
                />
              </div>
            ),
          },
        ]}
      />

      <CreateProfileModal
        isOpen={isCreatingModal}
        onClose={() => setIsCreatingModal(false)}
      />
      <ProfileSpvSlide
        isOpen={!!openedSpvId}
        onClose={() => setOpenedSpvId(0)}
        spvId={openedSpvId}
      />
      <ViewProfileModal
        isOpen={openedId > -1}
        onClose={() => {
          setOpenedId(-1);
        }}
        profileInfo={profiles?.find((el) => el.id === openedId)!}
      />
    </TemplateBase>
  );
};

export default ClientUsers;
