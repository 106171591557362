import { RootState } from "app/store/rootReducer";
import { Dispatch } from "redux";
import { dataRoomApi } from "./api";
import { dataRoomActions } from "./slice";

export const getDataRoomDocs =
  () => (dispatch: Dispatch, getState: () => RootState) => {
    const key = "allDocs";
    if (!getState().dataRoom.docs?.length) {
      dispatch(dataRoomActions.setIsLoading({ status: true, key }));
    }
    dataRoomApi
      .get()
      .then(({ data }) => {
        dispatch(dataRoomActions.setDocs(data));
      })
      .finally(() => {
        dispatch(dataRoomActions.setIsLoading({ status: false, key }));
      });
  };

export const getFileUrl =
  (resourceId: string) => (dispatch: Dispatch, getState: () => RootState) => {
    const key = resourceId;
    if (!getState().dataRoom.fileUrls[resourceId]) {
      dispatch(dataRoomActions.setIsLoading({ status: true, key }));
    }
    dataRoomApi
      .getFileUrl(resourceId)
      .then(({ data }) => {
        dispatch(
          dataRoomActions.setFileUrl({ key: resourceId, url: data.url })
        );
      })
      .finally(() => {
        dispatch(dataRoomActions.setIsLoading({ status: false, key }));
      });
  };
