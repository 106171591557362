import { Http } from "app/Http/Http";
import { IList } from "shared/types/commonTypes";
import { ISpvTransaction } from "./types";

export const spvApi = {
  getTransactions: (id: number) => {
    return Http.get<IList<ISpvTransaction>>(`/admin/spv/transactions/${id}`);
  },
  getProfilesBySpv: (id: number) => {
    return Http.get(`/admin/spv/profile/${id}`);
  },
};
