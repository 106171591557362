import React from "react";
import Icon from "shared/ui/atoms/Icon";

import styles from "./PersonCard.module.css";

interface IProps {
  name: string;
  email?: string;
  subtitle?: string;
  id: number;
  onDelete: () => void;
  disabled?: boolean;
}

const ControlPersonCard: React.FC<IProps> = ({
  name,
  email,
  subtitle = "Individual",
  id,
  onDelete,
  disabled,
}) => {
  return (
    <>
      <div className={styles.card}>
        <div>
          <div className={styles.name}>{name}</div>
          <div className={styles.subtitle}>{subtitle}</div>
        </div>
        {!disabled && (
          <div className={styles.delete} onClick={onDelete}>
            <Icon name="Delete" />
          </div>
        )}
      </div>
    </>
  );
};

export default ControlPersonCard;
