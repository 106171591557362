import React, { useEffect } from "react";

import "react-notifications-component/dist/theme.css";
import "./App.css";
import "./styles/notification.css";
import Providers from "./Providers";
import AuthGuard from "./Router/AuthGuard";
import BaseRoutes from "./Router/BaseRoutes";
import { useTypedSelector } from "./store/useTypedSelector";

function App() {
  return (
    <Providers>
      <AuthGuard />
    </Providers>
  );
}

export default App;
