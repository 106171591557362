import React, { useState } from "react";
import { signIn } from "features/auth/model/thunks";
import { useDispatch } from "react-redux";
import Button from "shared/ui/controls/Button/Button";
import authStyles from "./../commonAuthStyles.module.css";
import AuthHeader from "../ui/AuthHeader/AuthHeader";
import { Link } from "react-router-dom";
import styles from "./SignIn.module.css";
import Input from "shared/ui/inputs/Input/Input";

const SignIn = () => {
  const dispatch = useDispatch();

  const [form, setForm] = useState<Record<string, string>>({});

  const formhandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setForm((p) => ({ ...p, [e.target.name]: e.target.value }));
  };

  const submit = () => {
    dispatch(signIn(form));
  };

  return (
    <div>
      <AuthHeader>
        {/* Don’t have an account?&nbsp;
        <Link to="/auth/signup">
          <span>Sign up</span>
        </Link> */}
      </AuthHeader>
      <div className={authStyles.wrapper}>
        <h1 className={authStyles.title}>Sign in</h1>
        <div className={styles.form}>
          <Input
            label="Email"
            placeholder="Email"
            onChange={formhandler}
            name={"email"}
          />
          <Input
            label="Password"
            placeholder="Password"
            onChange={formhandler}
            name={"password"}
            type="password"
          />
          <Button size="wide" onClick={submit}>
            Sign in
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
