import React from "react";
import Icon, { IconNames } from "shared/ui/atoms/Icon";
import cn from "classnames";

import styles from "./ModalSidebar.module.scss";

interface IProps {
  title?: string;
  variant: "new" | "existing";
  items: {
    text: string;
    icon?: IconNames;
  }[];
  activeIndex: number;
  onClick?: (index: number) => void;
}

const ModalSidebar: React.FC<IProps> = ({
  variant,
  items,
  activeIndex,
  onClick,
  title,
}) => {
  if (variant === "new") {
    return (
      <>
        <div className={styles.title}>{title}</div>
        {items.map((el, i) => (
          <div
            key={i}
            className={cn({
              [styles.step]: true,
              [styles.completed]: activeIndex > i,
              [styles.active]: activeIndex === i,
            })}
          >
            {el.text}
          </div>
        ))}
      </>
    );
  }

  return (
    <div className={styles.existing}>
      <div className={styles.title}>{title}</div>
      {items.map((el, i) => (
        <div
          key={i}
          className={cn({
            [styles.stepExisting]: true,
            [styles.activeExisting]: activeIndex === i,
          })}
          onClick={() => onClick?.(i)}
        >
          {!!el.icon && <Icon name={el.icon} />}
          {el.text}
        </div>
      ))}
    </div>
  );
};

export default ModalSidebar;
