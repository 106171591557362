import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IOrg } from "./types";

interface OrgsState {
  isLoading: Record<string, boolean>;
  orgs: IOrg[];
}

const initialState: OrgsState = {
  isLoading: {},
  orgs: [],
};

const orgsSlice = createSlice({
  name: "orgs",
  initialState,
  reducers: {
    setOrgs: (state, action: PayloadAction<IOrg[]>) => {
      state.orgs = action.payload;
    },
  },
});

export const orgsActions = orgsSlice.actions;

export default orgsSlice.reducer;
