import { Http } from "app/Http/Http";
import {
  IBankAccountInfo,
  IGetBankTokenResponse,
  IRegisterBankTokenReq,
} from "./types";

export const bankApi = {
  getToken: () => {
    return Http.get<IGetBankTokenResponse>(`/bank/token`);
  },
  postToken: (data: IRegisterBankTokenReq) => {
    return Http.post(`/bank/register_token`, data);
  },
  getBankInfo: () => {
    return Http.get<IBankAccountInfo>(`/bank/account`);
  },
};
