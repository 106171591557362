import { Http } from "app/Http/Http";
import { ILoginResp } from "./types";

const authApi = {
  signup: (data: any) => {
    return Http.post<ILoginResp>("/auth/signup", data);
  },
  signin: (data: any) => {
    return Http.post<ILoginResp>("/auth/login", data);
  },
  signout: () => {
    return Http.post("/auth/logout", null, {
      headers: { Authorization: `Bearer ${localStorage.getItem("access")}` },
    });
  },
  getMe: () => {
    return Http.get("/user/me", {
      headers: { Authorization: `Bearer ${localStorage.getItem("access")}` },
    });
  },
};

export default authApi;
