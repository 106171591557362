import cn from 'classnames';

import styles from './NoData.module.css';

export interface NoDataProps {
  className?: string;
}

export const NoData: React.FC<NoDataProps> = ({ className }) => {
  return <div className={cn(className, styles.root)}>No Data</div>;
};
