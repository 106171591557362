import { Http } from "app/Http/Http";
import {
  IGetSpvsInProfileResponse,
  IProfileResponse,
  IUsersStats,
} from "./types";

export const profilesApi = {
  create: (data: any) => {
    return Http.post(`/user/profile`, data);
  },
  get: () => {
    return Http.get(`/user/profile`);
  },
  patch: (data: Partial<IProfileResponse>) => {
    return Http.patch(`/user/profile`, data);
  },
  getDetail: (id: string | number) => {
    return Http.get(`/user/profile/${id}`);
  },
  getSpvList: () => {
    return Http.get<IGetSpvsInProfileResponse>(`/spv`);
  },
  getDetailedSpv: (id: number) => {
    return Http.get(`/spv/${id}`);
  },
  ////
  getAdminProfiles: () => {
    return Http.get(`/admin/user/profile`);
  },
  getAdminUsers: () => {
    return Http.get(`/admin/user`);
  },
  approveProfile: (id: number) => {
    return Http.patch(`/admin/profile/status/${id}`, {});
  },
  getUsersStats: () => {
    return Http.get<IUsersStats>("/admin/stat/user");
  },
  getProfilesStats: () => {
    return Http.get("/admin/stat/profile");
  },
  delete: (id: number) => {
    return Http.delete(`/admin/profile/${id}`);
  },
};
