import { Http } from "app/Http/Http";

export const w9docsApi = {
  sign: (profileId: number) => {
    return Http.post(`/doc/trigger_w9`, {
      profile_id: profileId,
    });
  },
  //
  getSessionId: (document_code: string, profileId: number) => {
    return Http.get<{ session_id: string; document_id: string }>(
      `/doc/${profileId}/session_id`,
      {
        params: { document_code },
      }
    );
  },
  completeSignage: (
    profileId: number,
    session_id: string,
    document_id: string
  ) => {
    return Http.post(`/doc/${profileId}/complete_signage`, {
      session_id,
      document_id,
    });
  },
};
