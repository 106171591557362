import { useTypedSelector } from "app/store/useTypedSelector";
import dayjs from "dayjs";
import TemplateBase from "features/template/TemplateBase/TemplateBase";
import { deleteUserClient } from "pages/Profiles/model/thunks";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { dateFormats } from "shared/constants/dateConstants";
import { Spinner } from "shared/ui/atoms/Spinner/Spinner";
import Status from "shared/ui/atoms/Status/Status";
import ThreeDots from "shared/ui/controls/ThreeDots/ThreeDots";
import Table from "shared/ui/layout/Table";
import { getInvestmentAdmin } from "./model/thunks";

import styles from "./../Profiles/Profiles.module.scss";

const InvestmentAdmin = () => {
  const dispatch = useDispatch();

  const { data } = useTypedSelector((state) => state.investmentAdmin);

  useEffect(() => {
    dispatch(getInvestmentAdmin());
  }, []);

  const {
    profiles,
    isLoading,
    isPending,
    isFetching,
    invalidateKey,
    isSpvsLoading,
    profilesStats,
    deletingIds,
  } = useTypedSelector((state) => state.profiles);

  return (
    <TemplateBase title="Investment Admin">
      <Table<any>
        columns={[
          {
            title: "Name",
            dataIndex: "Name",
            render: (_, record) =>
              deletingIds[record.id] ? (
                <Spinner isSmallGray isStatic />
              ) : (
                record.first_name + " " + record.last_name
              ),
          },
          {
            title: "Email",
            dataIndex: "email",
            render: (_, record) => record.email ?? "None",
          },
          {
            title: "Role",
            dataIndex: "role",
            render: (role) => (
              <Status colorBg="#F6F5FF" colorText="#564BC6">
                {role ?? "None"}
              </Status>
            ),
          },
          {
            title: "Creation Date",
            dataIndex: "created_at",
            render: (date) => dayjs(date).format(dateFormats.dayWithHours),
          },
          {
            title: "",
            dataIndex: "id",
            render: (id) => (
              <div className={styles.actions}>
                <ThreeDots
                  items={[
                    {
                      text: "Delete",
                      icon: "Delete",
                      onClick: () => {
                        dispatch(deleteUserClient(+id!));
                      },
                    },
                  ]}
                />
              </div>
            ),
          },
        ]}
        data={data}
      />
    </TemplateBase>
  );
};

export default InvestmentAdmin;
