import cn from 'classnames';
import React, { HTMLProps, ReactNode } from 'react';

import styles from './Toggle.module.scss';

export interface ToggleProps extends Omit<HTMLProps<HTMLInputElement>, 'label'> {
    label?: ReactNode;
    preLabel?: ReactNode;
    labelClassName?: string;
    hasError?: boolean;
}

export const Toggle: React.FC<ToggleProps> = ({ label, preLabel, className, ...props }) => (
    <label className={cn(styles.wrapper, props.disabled && styles.disabled, className)}>
        <input className={styles.checkbox} type="checkbox" {...props} />
        {preLabel && <span className={styles.preLabel}>{preLabel}</span>}
        <span className={styles.toggle} />
        {label && <span className={styles.label}>{label}</span>}
    </label>
);
