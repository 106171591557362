import { applyMiddleware, compose, createStore } from "redux";

import { RootState, rootReducer } from "./rootReducer";

import thunk from "redux-thunk";
//@ts-ignore
import persistState from "redux-localstorage";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

export type InferPropertiesTypes<T> = T extends { [key: string]: infer U }
  ? U
  : never;

export type IActions<T extends { [key: string]: (...args: any[]) => any }> =
  ReturnType<InferPropertiesTypes<T>>;

const middlewares = [thunk];
const composeEnhancers =
  process.env.NODE_ENV === "development" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;

const enhancer = composeEnhancers(
  applyMiddleware(...middlewares),
  persistState(["auth", "breadcrumbs"])
);

const store = createStore(rootReducer as any, {}, enhancer);

export default store;
