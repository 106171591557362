import { FC, ReactNode } from "react";
import ReactDOM from "react-dom";

const Portal: FC<{ children: ReactNode }> = ({ children }) => {
  return ReactDOM.createPortal(
    children,
    document.querySelector("#modal") as Element
  );
};

export default Portal;
