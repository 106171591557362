import { profilesActions } from "pages/Profiles/model/profilesSlice";
import { IProfileDetail } from "pages/Profiles/model/types";

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { beautifyValue } from "shared/lib/utils/beautifyValue";
import LabeledItem from "shared/ui/atoms/LabeledItem/LabeledItem";
import Status from "shared/ui/atoms/Status/Status";
import Button from "shared/ui/controls/Button/Button";
import Block from "shared/ui/layout/Block/Block";

interface IProps {
  profileId: number;
  profile: IProfileDetail;
}

const KYC: React.FC<IProps> = ({ profileId, profile }) => {
  const dispatch = useDispatch();

  const [isPending, setIsPending] = useState(false);

  const isIndividual = profile?.type === "INDIVIDUAL";

  /*   useEffect(() => {
    if (!parallel) return;

    const handler = (e: any) => {
      setIsPending(true);
      getProfile()
        .then(({ user_id }) => {
          identityApi
            .sendToken(profileId, user_id)
            .then(() => {
              dispatch(profilesActions.invalidateKey());
              logout();
            })
            .finally(() => {
              setIsPending(false);
            });
        })
        .catch(() => {
          setIsPending(false);
        });
    };

    parallel.subscribe("auth.login", handler);

    return () => {
      parallel.unsubscribe("auth.login", handler);
    };
  }, [parallel, profileId]); */

  const isConnected = false; //profile?.kyc_status !== "NOT_STARTED";

  const onLogin = () => {
    const loginMeta = {
      /* email: profile?.email,
      first_name: profile?.first_name,
      last_name: profile?.last_name, */
      // force_accreditation_check: false,
      // force_identity_check: true,
      scope: "profile identity",
    };
    if (profile?.type === "INDIVIDUAL") {
      //@ts-ignore
      loginMeta.expected_entity_type = "self";
    }
  };

  const kycLabel = isIndividual ? "KYC" : "KYB";

  return (
    <div style={{ marginTop: "1rem" }}>
      <Block>
        <LabeledItem
          label={`${kycLabel} process is mandatory if you like to be part of a SPV`}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Button
              isPending={isPending}
              onClick={onLogin}
              disabled={isConnected}
            >
              {isConnected ? "Connected" : "Initiate"}
            </Button>
            {isConnected && (
              <div
                style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
              >
                {kycLabel} Submitted | Status{" "}
                {/* <Status>{beautifyValue.capitalize(profile?.kyc_status)}</Status> */}
              </div>
            )}
          </div>
        </LabeledItem>
      </Block>
    </div>
  );
};

export default KYC;
