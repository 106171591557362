export const formatText = {
  ein: (value: string) => {
    const numericValue = value.replace(/\D/g, "");

    const formattedValue = numericValue.replace(/(\d{3})(\d{6})/, "$1-$2");

    return formattedValue.slice(0, 10);
  },
  int: (value: string) => {
    const numericValue = value.replace(/\D/g, "");

    return numericValue;
  },
};
