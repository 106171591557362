import cn from "classnames";
import React, { ReactNode, useRef } from "react";
import Portal from "shared/ui/atoms/Portal/Portal";

import styles from "./BaseModal.module.css";

export interface IBaseModalProps {
  children: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  className?: string;
  classNameShadow?: string;
  closeOnClickOutside?: boolean;
}

const Modal: React.FC<IBaseModalProps> = (props) => {
  const {
    children,
    isOpen,
    onClose,
    className,
    closeOnClickOutside = true,
    classNameShadow,
  } = props;
  const ref = useRef<HTMLDivElement>(null);

  const onShadowClick = (e: React.MouseEvent) => {
    if (!closeOnClickOutside) return;
    e.stopPropagation();
    onClose();
  };

  return (
    <>
      {isOpen && (
        <Portal>
          <div
            className={cn(styles.shadow, classNameShadow)}
            onClick={onShadowClick}
          >
            <div
              className={cn(styles.modal, className)}
              ref={ref}
              onClick={(e) => e.stopPropagation()}
            >
              {children}
            </div>
          </div>
        </Portal>
      )}
    </>
  );
};

export default React.memo(Modal);
