import React, { FC, ReactNode } from "react";
import SideBar from "../SideBar/SideBar";

import styles from "./TemplateBase.module.css";

interface IProps {
  children: ReactNode;
  title?: string;
  additionalHeader?: ReactNode;
  subtitle?: ReactNode;
}

const TemplateBase: FC<IProps> = ({
  children,
  title,
  subtitle,
  additionalHeader,
}) => {
  return (
    <div className={styles.template}>
      <SideBar />
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.titleContainer}>
            <h1 className={styles.title}>{title}</h1>
            {subtitle && <h2 className={styles.subtitle}>{subtitle}</h2>}
          </div>
          {additionalHeader}
        </div>
        {children}
      </div>
    </div>
  );
};

export default TemplateBase;
