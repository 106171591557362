import { Http } from "app/Http/Http";
import { ISpvTransaction } from "pages/SpvAdmin/model/types";
import { IList } from "shared/types/commonTypes";
import { ICreateSpv } from "./types";

export const spvModalApi = {
  getDetailedSpv: (id: number) => {
    return Http.get(`/spv/${id}`);
  },
  getProfilesBySpv: (id: number | string) => {
    return Http.get(`/spv/profile/${id}`);
  },
  capitalCall: (data: any) => {
    return Http.post(`/spv/capital_call`, data);
  },
  kyc: (file: File, spvId: number) => {
    const data = new FormData();
    data.append("article-of-org", file);
    data.append("spv_id", String(spvId));
    return Http.post(`/doc/upload_spv_kyc`, data);
  },
  inviteInvestor: (data: any) => {
    return Http.post(`/spv/invite_investor`, data);
  },
  attachPassport: (file: File, type: string, profile_id: number) => {
    const data = new FormData();
    data.append("passport", file);
    data.append("document_type", type);
    data.append("profile_id", String(profile_id));
    return Http.post(`/doc/upload_passport`, data);
  },
  getInviteInfo: (inviteId: string) => {
    return Http.get(`/spv/invite/${inviteId}`);
  },
  getApprovals: (spvId: number) => {
    return Http.get(`/spv/${spvId}/pending_approvals`);
  },
  approveProfile: (spvId: number, profileId: number) => {
    return Http.post(`/spv/${spvId}/profile/${profileId}/approve `);
  },
  getApprovalFiles: (spvId: number, profileId: number) => {
    return Http.get(`/spv/${spvId}/profile/${profileId}/files`);
  },
  getTransactions: (spvId: number) => {
    return Http.get<IList<ISpvTransaction>>(`/spv/${spvId}/transaction`);
  },
  refund: (txId: number) => {
    return Http.post(`/transaction/${txId}/refund`);
  },
};
