import React from "react";

import styles from "./Consent.module.css";

const ConfidentialText = () => {
  return (
    <div className={styles.text}>
      <h2>Confidential Information Disclaimer</h2>
      <p>
        All data, information, content, communications, messages, documents, and
        other materials that you are granted access to through the non-public
        sections of this website (the "Site") are considered confidential
        information of Syndicately LLC, and/or the entities referenced on this
        Syndicately LLC website, including but not limited to fund sponsors,
        lead investors, and other users of the Site. For a comprehensive
        understanding of our confidentiality obligations, please consult our
        Terms of Service and Privacy Policy. This confidentiality encompasses,
        without limitation, (a) details identifying other users, entities
        offering securities, clients, deal sponsors, investment targets,
        entities seeking capital, and related persons and entities, (b)
        specifics of any transaction, the existence of any sponsorship, efforts
        to attract investors, or companies pursuing funding, and other relevant
        deal, fund, or opportunity information, (c) financial, corporate,
        operational, historical, performance, qualification, valuation,
        structural, legal, or due diligence data concerning users, clients, deal
        sponsors, entities, or opportunities, (d) all forms of communication,
        documents, messages, and other materials received or learned through the
        Site, and (e) any compilations, derivatives, summaries, excerpts,
        analyses, benchmarking, performance data, or other information derived
        from any confidential material, irrespective of its form, origin, or
        creation method.
      </p>
      <p>
        You are obligated to: (x) protect and secure the confidentiality of such
        confidential information with a degree of care at least equivalent to
        that which you use to protect your own confidential material, but in no
        case less than a reasonable degree of care; (y) refrain from using the
        confidential information, or allowing its use or access, for any purpose
        other than those directly related to this Site; and (z) prevent the
        disclosure of any such confidential information to any third party,
        except to other authorized users as facilitated by the Site and in
        alignment with the specified Syndicately LLC Terms of Service.
      </p>
      <h3>Policy on Non-Circumvention</h3>
      <p>
        Unless expressly authorized in writing by Syndicately LLC, you and your
        representatives are prohibited from directly or indirectly initiating
        contact with any business, firm, or other entity disclosed to you by any
        fund sponsors, lead investors, and other Site users, with the intent of
        making any direct or indirect investment in such entities.
      </p>
    </div>
  );
};

export default ConfidentialText;
