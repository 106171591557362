import { ProfileTypes } from "./CreateProfileModal";

const titlesIndividual = [
  /* {
    title: "Types and Roles",
    subtitle:
      "Please select the types and role(s) that best fit your individual profile. Please note you may select more than one role.",
  }, */
  {
    title: "Personal Information",
    subtitle:
      "Please fill in your person information for your individual profile. Please press the “edit” button if you would like to change or update any of the following fields.",
  },
  {
    title: "Address Information",
    subtitle:
      "Please fill in your address information for your individual profile. Please press the “edit” button if you would like to change or update any of the following fields.",
  },
  {
    title: "Consent",
    subtitle: "Please read the consent",
  },
  {
    title: "Confidential Information Disclaimer",
    subtitle: "Please read the Confidential Information Disclaimer",
  },
];

const titlesEntity = [
  {
    title: "Types and Roles",
    subtitle:
      "Please select the types and role(s) that best fit your individual profile. Please note you may select more than one role.",
  },
  {
    title: "Entity Information",
    subtitle:
      "Please complete the details for your entity. Please note that you may edit or update this information at anytime, but all must be verified through the KYC process.",
  },
  {
    title: "Beneficial Ownership",
    subtitle:
      "Please complete the details for beneficial ownership. Please note the differences between primary beneficial owners and beneficial owners.",
  },
  {
    title: "Signatory Details",
    subtitle: "Please complete the details for your signatory(ies)",
  },
  {
    title: "Consent",
    subtitle: "Please read the consent",
  },
  {
    title: "Confidential Information Disclaimer",
    subtitle: "Please read the Confidential Information Disclaimer",
  },
];

const titlesViewEntity = [
  {
    title: "Entity Information",
    subtitle:
      "Please complete the details for your entity. Please note that you may edit or update this information at anytime, but all must be verified through the KYC process.",
  },
  {
    title: "Beneficial Ownership",
    subtitle:
      "Please complete the details for beneficial ownership. Please note the differences between primary beneficial owners and beneficial owners.",
  },
  {
    title: "Signatory Details",
    subtitle: "Please complete the details for your signatory(ies)",
  },
  {
    title: "Banking Information",
    subtitle:
      "Please complete the details for beneficial ownership, Please note the difference between ",
  },
  {
    title: "Identity Verification",
    subtitle:
      "As a part of federal regulations, we need to verify your identity. Please provide photos of a government issued ID. Passports require a photo that includes the signature page. Other IDs require a photo of both the front and back.",
  },
  {
    title: "Tax Document",
    subtitle: "Please complete your information for your individual profile.",
  },
];

const titlesViewIndividual = [
  {
    title: "Personal Information",
    subtitle:
      "Please fill in your person information for your individual profile. Please press the “edit” button if you would like to change or update any of the following fields.",
  },
  {
    title: "Address Information",
    subtitle:
      "Please fill in your address information for your individual profile. Please press the “edit” button if you would like to change or update any of the following fields.",
  },
  {
    title: "Identity Verification",
    subtitle:
      "As a part of federal regulations, we need to verify your identity. Please provide photos of a government issued ID. Passports require a photo that includes the signature page. Other IDs require a photo of both the front and back.",
  },
  {
    title: "Banking Information",
    subtitle: "Please complete your information for your individual profile.",
  },
  {
    title: "Tax Document",
    subtitle: "Please sign documents for your individual profile.",
  },
];

export const getCreateProfileTitles = (
  step: number,
  type: ProfileTypes
): { title: string; subtitle: string } => {
  if (type === "INDIVIDUAL") {
    return titlesIndividual[step];
  } else {
    return titlesEntity[step];
  }
};

export const getViewProfileTitles = (
  step: number,
  type: ProfileTypes
): { title: string; subtitle: string } => {
  if (type === "INDIVIDUAL") {
    return titlesViewIndividual[step];
  } else {
    return titlesViewEntity[step];
  }
};
