import React, { FC, ReactNode } from "react";
import Icon from "shared/ui/atoms/Icon";
import BaseModal, { IBaseModalProps } from "../BaseModal/BaseModal";

import styles from "./SplitedModal.module.css";

interface IProps extends IBaseModalProps {
  sidebar: ReactNode;
  title: string;
  subtitle: string;
}

const SplitedModal: FC<IProps> = ({
  children,
  sidebar,
  title,
  subtitle,
  ...baseProps
}) => {
  return (
    <BaseModal {...baseProps} className={styles.modal}>
      <div className={styles.grid}>
        <div className={styles.sidebar}>{sidebar}</div>
        <div className={styles.right}>
          <div className={styles.head}>
            <div>
              <div className={styles.title}>{title}</div>
              <div className={styles.subtitle}>{subtitle}</div>
            </div>
            <div className={styles.close} onClick={baseProps.onClose}>
              <Icon name="Close" />
            </div>
          </div>
          <div className={styles.content}>{children}</div>
        </div>
      </div>
    </BaseModal>
  );
};

export default SplitedModal;
