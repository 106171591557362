import React from "react";
import styles from "./LoadingTable.module.scss";

interface Props {
  limit?: number;
  itemHeight?: number;
}

const LoadingTable: React.FC<Props> = ({ limit, itemHeight }) => {
  return <div className={styles.container}></div>;
};

export default LoadingTable;
