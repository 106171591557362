//@ts-nocheck

import React, { useContext, useEffect } from "react";
import { statesOptionsByCountries } from "shared/constants/states";
import Dropdown, { DropdownProps } from "shared/ui/inputs/Dropdown";
import { GeoSelectsContext } from "./GeoSelectsContextProvider";

interface IProps extends DropdownProps {
  externalCountryTrigger?: boolean;
  getExternalCountry?: () => string;
}

export const statefulCountries = ["Canada", "US"];

const StateSelect: React.FC<IProps> = ({
  externalCountryTrigger,
  getExternalCountry,
  onSelect,
  ...props
}) => {
  const { country, setCountry } = useContext(GeoSelectsContext);

  const disabledByCountry = !statefulCountries.includes(country);

  useEffect(() => {
    if (externalCountryTrigger) {
      setCountry(getExternalCountry());
    }
  }, [externalCountryTrigger, getExternalCountry]);

  return (
    <Dropdown
      {...props}
      items={statesOptionsByCountries[country]}
      onSelect={(val) => {
        onSelect(val);
      }}
      disabled={props.disabled || !country || disabledByCountry}
    />
  );
};

export default StateSelect;
