//@ts-nocheck
import { ICreateProfile, IProfileResponse } from "pages/Profiles/model/types";
import React from "react";
import { Controller, useForm } from "react-hook-form";
import Input from "shared/ui/inputs/Input/Input";
import BottomControls from "../../ui/BottomControls/BottomControls";
import { ICreateProfileStepProps } from "../CreateProfileStep1/CreateProfileStep1";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";
import Range from "shared/ui/inputs/Range/Range";

import styles from "./CraeteProfileStep2.module.css";
import Datepicker from "shared/ui/inputs/Datepicker/Datepicker";
import dayjs from "dayjs";
import { dateFormats } from "shared/constants/dateConstants";
import { formatText } from "shared/lib/utils/formatText";

export type ICraeteProfileStep2 = Pick<
  ICreateProfile,
  | "date_of_birth"
  | "first_name"
  | "last_name"
  | "middle_name"
  | "phone"
  | "ssn"
  | "email"
  | "subscription_amount"
  | "tax_id"
  | "role"
  | "allocation_size"
>;

const schema = z.object({
  first_name: z.string().min(1, { message: "Required" }),
  middle_name: z.optional(z.string()),
  last_name: z.string().min(1, { message: "Required" }),
  email: z.string().min(1, { message: "Email is required" }).email({
    message: "Must be a valid email",
  }),
  phone: z.string().min(1, { message: "Required" }),
  date_of_birth: z.string().min(1, { message: "Required" }),
  ssn: z.string().min(1, { message: "Required" }) /* z
    .string()
    .min(9, { message: "Should be 9 digits" })
    .max(9, { message: "Should be 9 digits" }), */,
  tax_id: z.optional(
    z
      .string()
      .min(9, { message: "Should be 9 digits" })
      .max(9, { message: "Should be 9 digits" })
  ),
  //role: z.string().min(1, { message: "Required" }),
  allocation_size: z.optional(z.string()),
  subscription_amount: z.optional(z.number()),
});

const schemaWithSubscription = schema.and(
  z.object({
    subscription_amount: z.number().min(0, { message: "Must be more than 0" }),
  })
);

const roleOptions = [
  { label: "General Partner ( Raise Capital )", key: "GP" },
  { label: "Limited Partner ( Investor )", key: "LP" },
];

interface IProps extends ICreateProfileStepProps {
  onSubmit: (values: ICraeteProfileStep2) => void;
  initialData?: IProfileResponse;
  isViewExisting?: boolean;
  onCancel?: () => void;
  maxAmount?: number;
  minAmount?: number;
  inviteText?: string;
  isInvite: boolean;
}

const CraeteProfileStep2: React.FC<IProps> = ({
  setStep,
  onSubmit,
  initialData,
  isViewExisting,
  onCancel,
  maxAmount,
  minAmount,
  inviteText,
  isInvite,
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ICraeteProfileStep2>({
    reValidateMode: "onChange",
    defaultValues: initialData,
    resolver: zodResolver(isInvite ? schemaWithSubscription : schema),
    mode: "onChange",
  });

  const inviteId = localStorage.getItem("inviteId");

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      {!!inviteText && <div className={styles.inviteText}>{inviteText}</div>}
      <Controller
        control={control}
        name="first_name"
        render={({ field }) => (
          <Input
            {...field}
            ref={field.ref}
            label="First Name"
            placeholder="First Name"
            disabled={isViewExisting}
            error={errors.first_name?.message}
          />
        )}
      />
      <Controller
        control={control}
        name="middle_name"
        render={({ field }) => (
          <Input
            {...field}
            ref={field.ref}
            label="Middle Name"
            placeholder="Middle Name"
            disabled={isViewExisting}
            error={errors.middle_name?.message}
          />
        )}
      />
      <Controller
        control={control}
        name="last_name"
        render={({ field }) => (
          <Input
            {...field}
            ref={field.ref}
            label="Last Name"
            placeholder="Last Name"
            error={errors.last_name?.message}
            disabled={isViewExisting}
          />
        )}
      />
      {/* <Controller
        control={control}
        name="role"
        render={({ field }) => (
          <Dropdown
            {...field}
            label="Role"
            disabled={isViewExisting}
            placeholder="Role"
            items={roleOptions}
            value={field.value}
            onSelect={(key) => {
              field.onChange(key);
              setRole(key);
            }}
          />
        )}
      />
      {role === "GP" && (
        <Controller
          control={control}
          name="allocation_size"
          render={({ field }) => (
            <Input
              {...field}
              ref={field.ref}
              disabled={isViewExisting}
              label="What will be the average allocation size of each investor for this raise?"
              placeholder="Allocation Size"
              error={errors.allocation_size?.message}
              format={formatText.int}
            />
          )}
        />
      )} */}
      <Controller
        control={control}
        name="email"
        render={({ field }) => (
          <>
            <Input
              {...field}
              ref={field.ref}
              label="Email"
              placeholder="Email"
              error={errors.email?.message}
              disabled={isViewExisting}
            />
          </>
        )}
      />
      <Controller
        control={control}
        name="phone"
        render={({ field }) => (
          <Input
            {...field}
            ref={field.ref}
            label="Phone Number"
            placeholder="Phone Number"
            error={errors.phone?.message}
            disabled={isViewExisting}
          />
        )}
      />
      <Controller
        control={control}
        name="date_of_birth"
        render={({ field }) => (
          <Datepicker
            {...field}
            value={new Date(field.value)}
            onChange={(v) => field.onChange(dayjs(v).format(dateFormats.day))}
            label="Date of Birth"
            error={errors.date_of_birth?.message}
            disabled={isViewExisting}
          />
        )}
      />
      <Controller
        control={control}
        name="ssn"
        render={({ field }) => (
          <Input
            {...field}
            ref={field.ref}
            label="Social Security Number"
            placeholder="Social Security Number"
            error={errors.ssn?.message}
            format={formatText.int}
            disabled={isViewExisting}
          />
        )}
      />
      <Controller
        control={control}
        name="tax_id"
        render={({ field }) => (
          <Input
            {...field}
            ref={field.ref}
            label="Tax ID (International Individuals only)"
            placeholder="Tax ID"
            error={errors.tax_id?.message}
            format={formatText.int}
            disabled={isViewExisting}
          />
        )}
      />
      {inviteId && (
        <Controller
          control={control}
          name="subscription_amount"
          render={({ field }) => (
            /* @ts-ignore */
            <Range
              label={`Subscription amount $${field.value ?? 0}`}
              min={minAmount}
              max={maxAmount}
              step={50}
              {...field}
              tipLeft={minAmount}
              tipRight={maxAmount}
              disabled={isViewExisting}
              error={errors?.subscription_amount?.message}
            />
          )}
        />
      )}
      {!isViewExisting && (
        <BottomControls
          disabled={!isValid}
          onCommonClick={handleSubmit(onSubmit)}
          onClickNext={() => {
            setStep((prev) => prev + 1);
          }}
          isViewExisting={isViewExisting}
        />
      )}
    </form>
  );
};

export default CraeteProfileStep2;
