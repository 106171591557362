import React from "react";
import W9DocButton from "widgets/W9DocButton/W9DocButton";

interface IProps {
  profileId: number;
  isIndividual: boolean;
}

const ProfileStep6Signage: React.FC<IProps> = ({ profileId, isIndividual }) => {
  return (
    <div>
      <W9DocButton profileId={profileId} isIndividual={isIndividual} />
    </div>
  );
};

export default ProfileStep6Signage;
