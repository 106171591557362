import { useTypedSelector } from "app/store/useTypedSelector";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Button from "shared/ui/controls/Button/Button";

import styles from "./BankAccount.module.css";
import {
  getBankAccountInfo,
  getBankToken,
  postBankToken,
} from "./model/thunks";
import { PlaidLinkOptions, usePlaidLink } from "react-plaid-link";

interface IProps {
  profileId: number;
}

const BankAccount: React.FC<IProps> = ({ profileId }) => {
  const dispatch = useDispatch();
  const { token, bankData, isDataLoading, isPending } = useTypedSelector(
    (state) => state.bank
  );
  const detailedProfile = useTypedSelector(
    (state) => state.profiles.detailedProfiles
  )[profileId];

  useEffect(() => {
    dispatch(getBankAccountInfo());
  }, []);

  useEffect(() => {
    dispatch(getBankToken());
  }, []);

  const onSuccess = (publicToken: string) => {
    dispatch(postBankToken(publicToken, profileId));
  };

  const config: PlaidLinkOptions = {
    token,
    onSuccess,
  };

  const { open } = usePlaidLink(config);
  const onClick = () => {
    open();
  };

  const btnText = detailedProfile?.bank_aacounts?.account_number
    ? `Account number ${detailedProfile?.bank_aacounts?.account_number} connected`
    : "Link Bank Account";

  return (
    <>
      <Button
        //disabled={!token || isDataLoading}
        isPending={isPending}
        onClick={onClick}
      >
        {btnText}
      </Button>
    </>
  );
};

export default BankAccount;
