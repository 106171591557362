import React, { FC } from "react";

import avatar from "./avatar.png";

import styles from "./AuthPromo.module.css";
import { Roles } from "../SignUp/SignUp";

interface IProps {
  role: Roles;
}

const AuthSignUpPromo: FC<IProps> = ({ role }) => {
  return (
    <div className={styles.promo}>
      {role === "manager" && (
        <div className={styles.title}>
          <b>
            <span>Simplify</span>
          </b>{" "}
          and{" "}
          <b>
            <span>streamline</span>
          </b>
          <br /> your private{" "}
          <b>
            <span>investment</span>
          </b>{" "}
          deals
        </div>
      )}
      {role === "investor" && (
        <div className={styles.title}>
          <b>
            <span>Invest</span>
          </b>{" "}
          in private and alternative assets{" "}
          <b>
            <span>without all the friction.</span>
          </b>
        </div>
      )}
      <div className={styles.subtitle}>
        One Platform To Monitor and Manage Your Deals
      </div>
      {/* <div className={styles.bottom}>
        <div className={styles.avatar}>
          <img src={avatar} alt="" />
        </div>
        <div className={styles.text}>
          “<b>Citizen Mint</b> was looking for an SPV partner with a depth of
          experience and unique API capabilities and we found that with&nbsp;
          <b>Syndicately</b>. Working with the team and integrating our product
          has been extremely smooth due their technical capabilities and
          responsiveness. I’m really not sure there’s another vendor that could
          deliver the complicated requirements we’ve had, and consider ourselves
          lucky for connecting and partnering with&nbsp;&nbsp;<b>Syndicately</b>
          .
        </div>
        <div className={styles.epilog}>
          <b>Marshall Dunford,</b> - Co-Founder & CTO, Citizen Mint
        </div>
      </div> */}
    </div>
  );
};

export default AuthSignUpPromo;
