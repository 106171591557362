import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface InvestmentAdminState {
  isLoading: Record<string, boolean>;
  data: any[];
}

const initialState: InvestmentAdminState = {
  isLoading: {},
  data: [],
};

const investmentAdminSlice = createSlice({
  name: "investmentAdmin",
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<any[]>) => {
      state.data = action.payload;
    },
  },
});

export const investmentAdminActions = investmentAdminSlice.actions;

export default investmentAdminSlice.reducer;
