import React, { useEffect } from "react";
import Logo from "shared/ui/atoms/Logo/Logo";
import styles from "./SideBar.module.css";

import SideBarLink from "./SideBarLink/SideBarLink";
import SideBarProfile from "./SideBarProfile/SideBarProfile";

const SideBar = () => {
  return (
    <div className={styles.sidebar}>
      <div className={styles.logo}>
        <Logo />
      </div>
      {/* <SideBarLink icon="Home" text="Admin Home" link="/" /> */}
      <div className={styles.subtitle}>USERS</div>
      <SideBarLink
        icon="Prodile"
        text="User Accounts"
        link="/users"
        /* items={[
          { link: "/dmint", text: "Admin Role Management" },
          { link: "/minagement", text: "Admin User Management" },
          { link: "/ieagement", text: "Client Role Management" },
          { link: "/", text: "Client User Management" },
        ]} */
      />
      <SideBarLink icon="MultiProfiles" text="User Profiles" link="/profiles" />
      <SideBarLink
        icon="ProfileCheck"
        text="Profile Approvals"
        link="/profiles-approvals"
      />
      <div className={styles.subtitle}>INVESTMENT</div>
      <SideBarLink
        icon="BarChart"
        text="Investment Admin"
        link="/investment-admin"
      />
      <div className={styles.subtitle}>SPV & ENTITY ADMIN</div>
      {/* <SideBarLink icon="CaseTrunk" text="SPV Admin" link="/spv/0/overview" /> */}
      <SideBarLink icon="Deals" text="Deals" link="/deals" />
      {/* <SideBarLink
        icon="Inbox"
        text="SPV Creation"
        link="/spv-setup"
        count={22}
      /> */}
      <SideBarLink
        icon="FileQuestion"
        text="LLC Formation"
        link="/profil222es"
      />
      <SideBarLink icon="DataBase" text="Data Room" link="/data" />
      <SideBarLink icon="FileCheck" text="Close Process" link="/profil222es" />
      <div className={styles.subtitle}>SETTINGS</div>
      <SideBarLink icon="Layout" text="Site Settings" link="/profil222es" />
      {/* <SideBarLink
        icon="Coins"
        text="Manage Blue Sky Fee Settings"
        link="/profil222es"
      />
      <SideBarLink icon="Info" text="Tooltip Settings" link="/profil222es" /> */}
      <SideBarLink icon="Buildings" text="Organizations" link="/orgs" />
      <SideBarProfile />
    </div>
  );
};

export default SideBar;
