export const beautifyValue = {
  percents: (value: number | string) => {
    if (!value) {
      return 0;
    }
    if (+value > 100) {
      return 100;
    }
    return value;
  },
  currency: (value: string | number) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(Number(value ?? 0));
  },
  capitalize: (value: string) => {
    if (!value) return "";
    if (value.length === 1) return value;
    return (
      value.charAt(0).toUpperCase() +
      value.slice(1).toLowerCase().replace(/_/g, " ")
    );
  },
};
