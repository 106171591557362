import DocumentUploadItem from "pages/SpvAdmin/ui/DocumentUploadItem/DocumentUploadItem";
import React from "react";
import Block from "shared/ui/layout/Block/Block";

import styles from "./SpvAdminDocuments.module.scss";

const SpvAdminDocuments = () => {
  return (
    <div className={styles.container}>
      <Block withPaddings={false}>
        <DocumentUploadItem text="Entity Filing Document (Signed)" />
        <DocumentUploadItem text="EIN Confirmation" />
        <DocumentUploadItem text="EIN Number" />
        <DocumentUploadItem text="LLC Operation Agreement" />
      </Block>
      <Block title="SPV Entity Documents Summary">
        <div className={styles.cell}>
          <span>Entity Name</span>
          <b>RGA Qatch SPV</b>
        </div>
        <div className={styles.cell}>
          <span>LLCOC</span>
          <b>link</b>
        </div>
        <div className={styles.cell}>
          <span>Entity Filing Document</span>
          <b>Upload</b>
        </div>
        <div className={styles.cell}>
          <span>EIN</span>
          <b>88-0586287</b>
        </div>
        <div className={styles.cell}>
          <span>EIN Doc</span>
          <b>Upload</b>
        </div>
      </Block>
    </div>
  );
};

export default SpvAdminDocuments;
