import BottomControls from "pages/Profiles/widgets/CreateProfileModal/ui/BottomControls/BottomControls";
import React, { useState } from "react";
import Dropdown from "shared/ui/inputs/Dropdown";
import FileInput from "shared/ui/inputs/FileInput/FileInput";
import Input from "shared/ui/inputs/Input/Input";
import { IBaseModalProps } from "shared/ui/modals/BaseModal/BaseModal";
import ModalSidebar from "shared/ui/modals/ModalSidebar/ModalSidebar";
import SplitedModal from "shared/ui/modals/SplitedModal/SplitedModal";

import styles from "./CreateSpvModal.module.scss";
import { CREATE_SPV_MODAL_TITLES } from "./utils";

const menuItems = [
  { text: "Create Entity", icon: "Analysis" },
  { text: "Legal Doc Templates", icon: "Analysis" },
  { text: "File EIN", icon: "Analysis" },
  { text: "Status Update", icon: "Analysis" },
];
interface IProps extends Omit<IBaseModalProps, "children"> {}

const CreateSpvModal: React.FC<IProps> = ({ ...baseProps }) => {
  const [step, setStep] = useState(0);

  return (
    <SplitedModal
      {...baseProps}
      title={CREATE_SPV_MODAL_TITLES[step]}
      sidebar={
        <>
          <div className={styles.title}>Create SPV</div>
          <ModalSidebar
            activeIndex={step}
            variant="new"
            items={menuItems as any}
          />
        </>
      }
      subtitle=""
    >
      {step === 0 && (
        <FileInput label="Upload Entity Filing Document (Signed)" />
      )}
      {step === 1 && (
        <div className={styles.form}>
          <div className={styles.formTitle}>Investor Document Templates</div>
          <FileInput label="Upload OA Joinder - Template" />
          <FileInput label="Upload Subscription Agreement - Template" />
          <FileInput label="Upload W9 - Template" />
        </div>
      )}
      {step === 2 && (
        <div className={styles.form}>
          <Input label="EIN Issued" placeholder="XX-XXXXXX" />
          <FileInput label="Upload EIN Confirmation" />
        </div>
      )}
      {step === 3 && (
        <div className={styles.form}>
          <Dropdown
            items={[{ key: "Setup", label: "Setup" }]}
            value="Setup"
            label="Status"
          />
        </div>
      )}
      <BottomControls
        onClickNext={step < 3 ? () => setStep((p) => p + 1) : () => null}
        onClickPrev={!!step ? () => setStep((p) => p - 1) : undefined}
        isFinish={step === 3}
        isPending={false}
      />
    </SplitedModal>
  );
};

export default CreateSpvModal;
