import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IDataRoomDoc } from "./types";

interface DataRoomState {
  isLoading: Record<string, boolean>;
  docs: IDataRoomDoc[];
  invalidateKey: number;
  fileUrls: Record<string, string>;
}

const initialState: DataRoomState = {
  isLoading: {},
  docs: [],
  invalidateKey: 0,
  fileUrls: {},
};

const dataRoomSlice = createSlice({
  name: "dataRoom",
  initialState,
  reducers: {
    setDocs: (state, action: PayloadAction<IDataRoomDoc[]>) => {
      state.docs = action.payload;
    },
    setIsLoading: (state, { payload }) => {
      state.isLoading[payload.key] = payload.status;
    },
    invalidateKey: (state) => {
      state.invalidateKey++;
    },
    setFileUrl: (state, { payload }) => {
      state.fileUrls[payload.key] = payload.url;
    },
  },
});

export const dataRoomActions = dataRoomSlice.actions;

export default dataRoomSlice.reducer;
