import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { IMe } from "./types";

interface AuthState {
  isAuth: boolean;
  me: null | IMe;
  meIsLoading: boolean;
}

const initialState: AuthState = {
  isAuth: !!localStorage.getItem("access") ?? false,
  me: null,
  meIsLoading: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setIsAuth: (state, action: PayloadAction<boolean>) => {
      state.isAuth = action.payload;
    },
    setMe: (state, action: PayloadAction<IMe | null>) => {
      state.me = action.payload;
    },
    setMeLoading: (state, action: PayloadAction<boolean>) => {
      state.meIsLoading = action.payload;
    },
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;
