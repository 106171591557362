import React, { FC, useEffect, useState } from "react";
import { IBaseModalProps } from "shared/ui/modals/BaseModal/BaseModal";
import SplitedModal from "shared/ui/modals/SplitedModal/SplitedModal";

import styles from "./CreateProfileModal.module.css";

import { getViewProfileTitles } from "./utils";
import CraeteProfileStep2, {
  ICraeteProfileStep2,
} from "./steps/CraeteProfileStep2/CraeteProfileStep2";
import CraeteProfileStep3, {
  ICraeteProfileStep3,
} from "./steps/CreateProfileStep3/CreateProfileStep3";
import CraeteProfileStep4, {
  DocumentTypes,
} from "./steps/CreateProfileStep4/CreateProfileStep4";
import CraeteProfileStep5 from "./steps/CreateProfile-EntityStep5/CreateProfileStep5";
import { useDispatch } from "react-redux";
import { editProfile, getProfileDetail } from "pages/Profiles/model/thunks";
import { ICreateProfile, IProfileResponse } from "pages/Profiles/model/types";
import ViewProfileModalSideBar from "./ui/ViewProfileModalSideBar/ViewProfileModalSideBar";
import { ProfileTypes } from "./CreateProfileModal";
import CreateEntityStep2 from "./steps/CreateEntityStep2-3-4/CreateEntityStep2";
import CreateEntityStep3 from "./steps/CreateEntityStep2-3-4/CreateEntityStep3";
import CreateEntityStep4 from "./steps/CreateEntityStep2-3-4/CreateEntityStep4";
import { useTypedSelector } from "app/store/useTypedSelector";
import ProfileStep6Signage from "./steps/ProfileStep6Signage/ProfileStep6Signage";
import { spvModalApi as spvDetailApi } from "widgets/SpvModal/model/api";
import { Notifications } from "app/Notifications/Notifications";
import { profilesActions } from "pages/Profiles/model/profilesSlice";

interface IProps extends Omit<IBaseModalProps, "children"> {
  profileInfo: IProfileResponse;
}

const ViewProfileModal: FC<IProps> = ({ profileInfo, ...baseProps }) => {
  const dispatch = useDispatch();
  const { invalidateKey, isPending } = useTypedSelector(
    (state) => state.profiles
  );
  const [step, setStep] = useState(0);
  const [type, setType] = useState<ProfileTypes>(
    profileInfo?.type ?? "INDIVIDUAL"
  );
  const [form, setForm] = useState<Partial<ICreateProfile>>(profileInfo);

  const onSubmitStep2 = (values: ICraeteProfileStep2) => {
    setForm((prev) => ({ ...prev, ...values }));
  };

  const onSubmitStep3 = (values: ICraeteProfileStep3) => {
    setForm((prev) => ({ ...prev, ...values }));
  };

  const onFinish = () => {
    if (isPending) return;
    dispatch(editProfile({ ...form, type }));
    baseProps.onClose();
  };

  const isIndividual = type === "INDIVIDUAL";

  useEffect(() => {
    if (!baseProps.isOpen) return;
    dispatch(getProfileDetail(profileInfo?.id));
  }, [profileInfo?.id, baseProps.isOpen, invalidateKey]);

  const detailProfile = useTypedSelector(
    (state) => state.profiles.detailedProfiles
  )[profileInfo?.id];

  const saveDocument = (passport: File, passportType: DocumentTypes) => {
    spvDetailApi
      .attachPassport(passport!, passportType, profileInfo?.id)
      .then(() => {
        Notifications.success("Document Attached");
        dispatch(profilesActions.invalidateKey());
        baseProps.onClose();
      });
  };

  return (
    <SplitedModal
      sidebar={
        <ViewProfileModalSideBar
          step={step}
          isIndividual={isIndividual}
          onChangeStep={setStep}
          profileInfo={profileInfo}
        />
      }
      {...baseProps}
      title={getViewProfileTitles(step, type)?.title}
      subtitle=""
      //subtitle={getViewProfileTitles(step, type)?.subtitle}
    >
      <div className={styles.content}>
        {/* {!step && (
          <CreateProfileStep1
            setStep={setStep}
            type={type}
            onClick={setType}
            isViewExisting
            onCancel={baseProps.onClose}
          />
        )} */}
        {isIndividual && step === 0 && (
          <CraeteProfileStep2
            setStep={setStep}
            onSubmit={onSubmitStep2}
            initialData={detailProfile!}
            isViewExisting
            onCancel={baseProps.onClose}
            key={String(detailProfile)}
            isInvite={false}
          />
        )}
        {isIndividual && step === 1 && (
          <CraeteProfileStep3
            setStep={setStep}
            onSubmit={onSubmitStep3}
            isViewExisting
            onCancel={baseProps.onClose}
            initialData={profileInfo}
          />
        )}
        {isIndividual && step === 2 && (
          <CraeteProfileStep4
            setStep={setStep}
            isViewExisting
            onCancel={baseProps.onClose}
            profile={detailProfile!}
            onSubmit={saveDocument}
          />
        )}
        {isIndividual && step === 3 && (
          <CraeteProfileStep5
            isIndividual={isIndividual}
            setStep={setStep}
            onFinish={onFinish}
            isViewExisting
            onCancel={baseProps.onClose}
            detailProfile={detailProfile!}
            profileId={profileInfo?.id}
          />
        )}
        {isIndividual && step === 4 && (
          <ProfileStep6Signage
            profileId={profileInfo?.id}
            isIndividual={isIndividual}
            /*
            setStep={setStep}
            onFinish={onFinish}
            isViewExisting
            onCancel={baseProps.onClose}
            detailProfile={detailProfile!}
             */
          />
        )}
        {/* ///////// */}
        {!isIndividual && step === 0 && (
          <CreateEntityStep2
            setStep={setStep}
            onSubmit={() => {}}
            initialData={profileInfo}
            isViewExisting
            onCancel={baseProps.onClose}
            key={String(detailProfile)}
          />
        )}
        {!isIndividual && step === 1 && (
          <CreateEntityStep3
            setStep={setStep}
            onSubmit={() => {}}
            initialData={detailProfile!}
            isViewExisting
            onCancel={baseProps.onClose}
          />
        )}
        {!isIndividual && step === 2 && (
          <CreateEntityStep4
            setStep={setStep}
            isViewExisting
            initialData={detailProfile!}
            onSubmit={(signatories) =>
              setForm((prev) => ({
                ...prev,
                signatories,
              }))
            }
            onCancel={baseProps.onClose}
          />
        )}
        {!isIndividual && step === 3 && (
          <CraeteProfileStep5
            isIndividual={isIndividual}
            setStep={setStep}
            onFinish={onFinish}
            isViewExisting
            onCancel={baseProps.onClose}
            profileId={profileInfo?.id}
          />
        )}
        {!isIndividual && step === 4 && (
          <CraeteProfileStep4
            setStep={setStep}
            isViewExisting
            onCancel={baseProps.onClose}
            profile={detailProfile!}
            onSubmit={saveDocument}
            key={String(detailProfile)}
          />
        )}
        {!isIndividual && step === 5 && (
          <ProfileStep6Signage
            profileId={profileInfo?.id}
            isIndividual={isIndividual}

            /*
          setStep={setStep}
          onFinish={onFinish}
          isViewExisting
          onCancel={baseProps.onClose}
          detailProfile={detailProfile!}
           */
          />
        )}
      </div>
    </SplitedModal>
  );
};

export default ViewProfileModal;
