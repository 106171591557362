import React from "react";
import ReactSlider from "react-slider";
import LabeledItem from "shared/ui/atoms/LabeledItem/LabeledItem";

import styles from "./Range.module.css";

interface IProps {
  step?: number;
  max?: number;
  min?: number;
  label?: string;
  tipLeft?: string | number;
  tipRight?: string | number;
  onChange?: (v: number) => void;
  value?: number;
}

const Range: React.FC<IProps> = ({
  step = 1,
  max = 100,
  min,
  label,
  tipLeft,
  tipRight,
  onChange,
  value,
}) => {
  return (
    <LabeledItem label={label} classNameContent={styles.content}>
      <div className={styles.wrapper}>
        <ReactSlider
          className={styles.slider}
          thumbClassName={styles.thumb}
          trackClassName={styles.track}
          renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
          onChange={onChange}
          max={max}
          min={min}
          step={step}
          value={value}
        />
      </div>
      <div className={styles.tip}>{tipLeft}</div>
      <div className={styles.tip + " " + styles.right}>{tipRight}</div>
    </LabeledItem>
  );
};

export default Range;
