import { Notifications } from "app/Notifications/Notifications";
import store from "app/store/store";
import axios, { AxiosRequestConfig } from "axios";
import { signOut } from "features/auth/model/thunks";

export const Http = axios;

Http.defaults.baseURL = process.env.REACT_APP_API_URL;

export interface IError {
  Title: string;
  Message: string;
}

const requestInterceptor = (request: AxiosRequestConfig<any>) => {
  if (!request.headers) {
    request.headers = {};
  }

  request.headers["Cache-Control"] = "no-cache";

  const storeToken = localStorage.getItem("access");
  if (storeToken) {
    request.headers.Authorization = `Bearer ${storeToken}`;
  }

  return request;
};

axios.interceptors.request.use(requestInterceptor as any);

Http.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config as AxiosRequestConfig;

    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest?.url?.includes("/logout")
    ) {
      store.dispatch(signOut() as any);
      return Promise.reject(error);
    }

    const isMutationReq = ["post", "patch", "put", "delete"].includes(
      originalRequest?.method?.toLowerCase()!
    );

    if (error.response && error.response.status === 400 && isMutationReq) {
      const message = error.response?.data?.Message || error.response?.Message;
      if (message) {
        Notifications.fail(message);
      }
    }

    if (error.response && error.response.status === 500) {
      Notifications.fail("Server error");
    }

    return Promise.reject(error);
  }
);
