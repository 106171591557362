import React, { createContext, ReactNode, useState } from "react";

export const GeoSelectsContext = createContext<{
  country: string;
  setCountry: (country: string) => void;
}>({ country: "", setCountry: () => void 0 });

interface IProps {
  onChangeCountry?: (country: string) => void;
  children: ReactNode;
}

const GeoSelectsContextProvider: React.FC<IProps> = ({
  onChangeCountry,
  children,
}) => {
  const [country, setCountry] = useState("");

  const changeCountry = (value: string) => {
    onChangeCountry?.(value);
    setCountry(value);
  };

  return (
    <GeoSelectsContext.Provider value={{ country, setCountry: changeCountry }}>
      {children}
    </GeoSelectsContext.Provider>
  );
};

export default GeoSelectsContextProvider;
