import { useTypedSelector } from "app/store/useTypedSelector";
import classNames from "classnames";
import TemplateBase from "features/template/TemplateBase/TemplateBase";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import Icon from "shared/ui/atoms/Icon";
import Button from "shared/ui/controls/Button/Button";
import Search from "shared/ui/inputs/Search/Search";
import Block from "shared/ui/layout/Block/Block";
import Table from "shared/ui/layout/Table";

import dayjs from "dayjs";

import styles from "./ProfilesApprovals.module.scss";
import {
  approveProfile,
  getAdminProfiles,
  getAdminUsers,
} from "pages/Profiles/model/thunks";
import { IProfileResponse, IUser } from "pages/Profiles/model/types";
import ThreeDots from "shared/ui/controls/ThreeDots/ThreeDots";
import { dateFormats } from "shared/constants/dateConstants";

const ProfilesApprovals = () => {
  const dispatch = useDispatch();
  const [isCreatingModal, setIsCreatingModal] = useState(false);
  const [search, setSearch] = useState("");

  const {
    profiles,
    isLoading,
    isPending,
    isFetching,
    invalidateKey,
    isSpvsLoading,
    users,
  } = useTypedSelector((state) => state.profiles);

  useEffect(() => {
    dispatch(getAdminProfiles());
  }, [invalidateKey]);

  const [openedSpvId, setOpenedSpvId] = useState(0);

  const searchedProfiles = useMemo(() => {
    return (
      profiles
        ?.filter(
          (el) =>
            el.first_name.toLowerCase().includes(search.toLowerCase()) ||
            el.last_name?.toLowerCase().includes(search.toLowerCase())
        )
        .filter((el) => el.stage === "PENDING_APPROVAL") ?? []
    );
  }, [profiles, search]);

  return (
    <TemplateBase
      title="Users"
      /*  additionalHeader={
        <Button onClick={() => setIsCreatingModal(true)}>
          <Icon name="Plus" />
          Create profile
        </Button>
      } */
    >
      <div className={styles.searchBar}>
        <Search
          placeholder="Search by profile"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        {/* <Button className={styles.add}>
          <Icon name="Plus" />
          Add user
        </Button> */}
      </div>

      <Table<any>
        data={searchedProfiles}
        columns={[
          {
            title: "Name",
            dataIndex: "first_name",
            styles: { width: "35%" },
            render: (_, record) => record!.first_name + " " + record!.last_name,
          },
          {
            title: "Email",
            dataIndex: "email",
            styles: { width: "30%" },
            render: (_, record) => record!.email ?? "None",
          },
          {
            title: "Creation Date",
            dataIndex: "created_at",
            styles: { width: "30%" },
            render: (date) => dayjs(date).format(dateFormats.dayWithHours),
          },
          {
            title: "",
            dataIndex: "id",
            styles: { width: "5%" },
            render: (id) => (
              <ThreeDots
                items={[
                  {
                    text: "Approve",
                    onClick: () => dispatch(approveProfile(+id!)),
                  },
                ]}
              />
            ),
          },
        ]}
      />

      {/* <CreateProfileModal
        isOpen={isCreatingModal}
        onClose={() => setIsCreatingModal(false)}
      />
      <ProfileSpvSlide
        isOpen={!!openedSpvId}
        onClose={() => setOpenedSpvId(0)}
        spvId={openedSpvId}
      /> */}
    </TemplateBase>
  );
};

export default ProfilesApprovals;
