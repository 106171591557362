import { Icons } from "./icons";
//import { hexToRgb } from "../../utils/colorTransform";
import styles from "./Icon.module.scss";
import cn from "classnames";

export type IconNames = keyof typeof Icons;

export interface IconProps {
  name: IconNames;
  size?: number;
  color?: string;
  backgroundSize?: "middle" | "big" | "biggest";
  backgroundColor?: string;
}

const Icon: React.FC<IconProps> = ({
  name,
  size = "middle",
  color = "var(--color-text-gray)",
  backgroundSize,
  backgroundColor,
}) => {
  const computedSize: number = typeof size === "string" ? 20 : size;
  let icon;
  switch (size) {
    case "small":
      icon = Icons[name](14, 14, color);
      break;
    case "large":
      icon = Icons[name](32, 32, color);
      break;
    default:
      icon = Icons[name](computedSize, computedSize, color);
  }

  return backgroundSize ? (
    <div
      className={cn(styles.roundBackground, styles[backgroundSize])}
      style={{
        backgroundColor: backgroundSize && backgroundColor /* ||
            `rgba(${hexToRgb(color)?.r}, ${hexToRgb(color)?.g}, ${
              hexToRgb(color)?.b
            }, 0.15)` */,
      }}
    >
      {icon!}
    </div>
  ) : (
    icon ?? null
  );
};

export default Icon;
