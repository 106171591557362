import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import {
  ICreateProfile,
  IDetailedSpv,
  IGetSpvsInProfile,
  IProfileDetail,
  IProfileResponse,
  IUser,
  IUsersStats,
} from "./types";

interface ProfilesState {
  isLoading: boolean;
  profiles: IProfileResponse[];
  isPending: boolean;
  isFetching: boolean;
  invalidateKey: number;
  detailedProfiles: Record<string, IProfileDetail | null>;
  spvsByProfiles: Record<string, IGetSpvsInProfile[]>;
  isSpvsLoading: boolean;
  detailedSpvs: Record<string, IDetailedSpv | null>;
  users: IUser[];
  usersStats: IUsersStats | null;
  profilesStats: IUsersStats | null;
  deletingIds: Record<number, boolean>;
}

const initialState: ProfilesState = {
  isLoading: false,
  profiles: [],
  isPending: false,
  isFetching: false,
  invalidateKey: 0,
  detailedProfiles: {},
  spvsByProfiles: {},
  isSpvsLoading: false,
  detailedSpvs: {},
  users: [],
  usersStats: null,
  profilesStats: null,
  deletingIds: {},
};

const profilesSlice = createSlice({
  name: "profiles",
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    setProfiles: (state, action: PayloadAction<IProfileResponse[]>) => {
      state.profiles = action.payload;
    },
    setPending: (state, action) => {
      state.isPending = action.payload;
    },
    setFetching: (state, action) => {
      state.isFetching = action.payload;
    },
    invalidateKey: (state) => {
      state.invalidateKey++;
    },
    setUsers: (state, action: PayloadAction<IUser[]>) => {
      state.users = action.payload;
    },
    setUserStats: (state, action) => {
      state.usersStats = action.payload;
    },
    setProfileStats: (state, action) => {
      state.profilesStats = action.payload;
    },
    setDeletingIds: (state, action) => {
      state.deletingIds = action.payload;
    },
    setDeletingIdsPartialy: (state, action) => {
      state.deletingIds[action.payload.id] = action.payload.value;
    },
  },
});

export const profilesActions = profilesSlice.actions;

export default profilesSlice.reducer;
