import cn from 'classnames';
import React, { HTMLProps, ReactNode } from 'react';

import { checkIcon } from './checkIcon';

import styles from './Checkbox.module.scss';

interface CheckboxProps extends Omit<HTMLProps<HTMLInputElement>, 'label'> {
    label?: ReactNode;
    labelClassName?: string;
    hasError?: boolean;
    color?: 'primary' | 'secondary' | 'tertiary';
}

export const Checkbox: React.FC<CheckboxProps> = ({
    checked,
    disabled,
    onChange,
    label,
    className,
    labelClassName,
    hasError,
    color = 'primary',
    ...props
}) => (
    <label
        className={cn(
            styles.wrapper,
            disabled && styles.disabled,
            styles[color],
            hasError && styles.error,
            className,
        )}
    >
        <input
            className={styles.checkbox}
            type="checkbox"
            checked={checked}
            onChange={onChange}
            disabled={disabled}
            {...props}
        />
        <div className={styles.icon}>{checkIcon}</div>
        {label && <span className={cn(styles.label, labelClassName)}>{label}</span>}
    </label>
);
