import { useTypedSelector } from "app/store/useTypedSelector";
import Amount from "entities/Amount/Amount";
import { ISpv } from "pages/Deals/model/types";
import { getProfilesBySpv } from "pages/SpvAdmin/model/thunks";
import { ISpvProfile } from "pages/SpvAdmin/model/types";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Icon from "shared/ui/atoms/Icon";
import Status from "shared/ui/atoms/Status/Status";
import Button from "shared/ui/controls/Button/Button";
import Table from "shared/ui/layout/Table";

import styles from "./SpvAdminContributions.module.scss";

interface IProps {
  spv: ISpv;
}

const SpvAdminContributions: React.FC<IProps> = ({ spv }) => {
  const dispatch = useDispatch();
  const profiles = useTypedSelector((state) => state.spv.profiles)[spv.id];

  useEffect(() => {
    dispatch(getProfilesBySpv(spv.id));
  }, [spv?.id]);

  return (
    <div>
      <div className={styles.counts}>
        <Amount
          icon="MultiProfiles"
          title="Manager pays the SPV Fees"
          value="No"
        />
        <Amount icon="Cash" title="Total Subscribed Amount" value="$10,000" />
        <Amount icon="Cash" title="Total Received Amount" value="$0.00" />
      </div>
      <div className={styles.controls}>
        <Button>
          <Icon name="Plus" />
          Add Entry
        </Button>
      </div>
      <Table<ISpvProfile>
        data={profiles}
        columns={[
          {
            title: "Investor",
            dataIndex: "FirstName",
            render: (_, row) => row?.FirstName + " " + row?.FirstName,
          },
          { title: "Subscription", dataIndex: "SubscribedAmount" },
          {
            title: "Carry Percentage",
            dataIndex: "SubscribedAmount",
            render: () => "None",
          },
          {
            title: "Fixed Management Fee",
            dataIndex: "SubscribedAmount",
            render: () => "None",
          },
          {
            title: "Status",
            dataIndex: "Phone",
            render: (status) => (
              <Status colorBg="#F1F6DF" colorText="#6B7647">
                {status}
              </Status>
            ),
          },
        ]}
      />
    </div>
  );
};

export default SpvAdminContributions;
