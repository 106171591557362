import React from "react";
import cn from "classnames";

import styles from "./Block.module.css";

interface IProps {
  className?: string;
  children: React.ReactNode;
  title?: React.ReactNode;
  subtitle?: string;
  withPaddings?: boolean;
}

const Block: React.FC<IProps> = ({
  className,
  children,
  title,
  subtitle,
  withPaddings = true,
}) => {
  const isCustomHeader = typeof title !== "string";

  return (
    <div className={cn(className, styles.block)}>
      {!!title && (
        <div className={styles.header}>
          {isCustomHeader ? title : <div className={styles.title}>{title}</div>}
          {!!subtitle && <div className={styles.subtitle}>{subtitle}</div>}
        </div>
      )}
      <div className={cn({ [styles.paddings]: withPaddings })}>{children}</div>
    </div>
  );
};

export default Block;
