import { useTypedSelector } from "app/store/useTypedSelector";
import dayjs from "dayjs";
import Amount from "entities/Amount/Amount";
import { ISpv } from "pages/Deals/model/types";
import { getTransactions } from "pages/SpvAdmin/model/thunks";
import { ISpvTransaction } from "pages/SpvAdmin/model/types";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { dateFormats } from "shared/constants/dateConstants";
import Status from "shared/ui/atoms/Status/Status";
import Button from "shared/ui/controls/Button/Button";
import Table from "shared/ui/layout/Table";

import styles from "./SpvAdminFinances.module.scss";

interface IProps {
  spv: ISpv;
}
const SpvAdminFinances: React.FC<IProps> = ({ spv }) => {
  const dispatch = useDispatch();

  const { transactions } = useTypedSelector((state) => state.spv);

  useEffect(() => {
    dispatch(getTransactions(spv.id));
  }, [spv.id]);

  const tsxs = transactions[spv.id];

  return (
    <div>
      <div className={styles.counts}>
        <Amount icon="Tag" title="Account ID" value="View" />
        <Amount icon="Cash" title="Account Balance" value="$83,985.00" />
      </div>
      <div className={styles.controls}>
        <span>SPV Transactions Summary</span>
        <Button>Add Transfer</Button>
      </div>
      <Table<ISpvTransaction>
        data={tsxs}
        columns={[
          { title: "Transfer ID", dataIndex: "id" },
          { title: "Amount", dataIndex: "amount" },
          {
            title: "Creation Date",
            dataIndex: "created_at",
            render: (date) => dayjs(date).format(dateFormats.dayWithHours),
          },
          { title: "Sender", dataIndex: "sender" },
          { title: "Receiver", dataIndex: "receiver" },
          {
            title: "Status",
            dataIndex: "amount",
            render: () => (
              <Status colorBg="#F1F6DF" colorText="#6B7647">
                Completed
              </Status>
            ),
          },
        ]}
      />
    </div>
  );
};

export default SpvAdminFinances;
