import { RootState } from "app/store/rootReducer";
import { Dispatch } from "redux";
import { bankApi } from "./api";
import { bankActions } from "./bankSlice";

export const getBankToken = () => (dispatch: Dispatch) => {
  bankApi.getToken().then(({ data }) => {
    dispatch(bankActions.setToken(data.token));
  });
};

export const postBankToken =
  (token: string, profile_id: number) => (dispatch: Dispatch) => {
    dispatch(bankActions.setPending(true));
    bankApi.postToken({ token, profile_id }).finally(() => {
      dispatch(bankActions.setPending(false));
    });
  };

export const getBankAccountInfo =
  () => (dispatch: Dispatch, getState: () => RootState) => {
    if (!getState().bank.bankData) {
      dispatch(bankActions.setDataLoading(true));
    }
    bankApi
      .getBankInfo()
      .then(({ data }) => {
        dispatch(bankActions.setInfo(data));
      })
      .finally(() => {
        dispatch(bankActions.setDataLoading(false));
      });
  };
