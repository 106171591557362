import React, { FC } from "react";
import cn from "classnames";

import styles from "./Search.module.scss";

interface IProps {
  classNameInput?: string;
  placeholder?: string;
  value?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

const Search: FC<IProps> = ({
  classNameInput,
  placeholder,
  value,
  onChange,
}) => {
  return (
    <input
      className={cn(styles.input, classNameInput)}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      /* name={name}
      type={type} */
    />
  );
};

export default Search;
