import { ReactNode, useState } from "react";

import styles from "./Datepicker.module.scss";
import { dateFormats } from "shared/constants/dateConstants";
import { DatePicker, DatePickerProps } from "@mui/x-date-pickers/DatePicker";
import LabeledItem from "shared/ui/atoms/LabeledItem/LabeledItem";
import dayjs, { Dayjs } from "dayjs";

export interface DatepickerProps extends Omit<DatePickerProps<Date>, "label"> {
  valueFormat?: string;
  label?: ReactNode;
  error?: string;
}

const Datepicker: React.FC<DatepickerProps> = ({
  valueFormat = dateFormats.day,
  label,
  error,
  ...props
}) => {
  return (
    <div className={styles.root}>
      <LabeledItem label={label} error={error}>
        <DatePicker
          {...props}
          value={props.value ? dayjs(props.value) : (null as any)}
          format={valueFormat}
        />
      </LabeledItem>
    </div>
  );
};

export default Datepicker;
