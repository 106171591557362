import React, { useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import Auth from "pages/Auth/Auth";
import { useTypedSelector } from "../store/useTypedSelector";
import BaseRoutes from "./BaseRoutes";

const AuthGuard = () => {
  const isAuth = useTypedSelector((state) => state.authSlice.isAuth);
  const history = useHistory();

  useEffect(() => {
    if (!isAuth) {
      history.push("/auth/signin");
    }
  }, [isAuth]);

  if (isAuth) {
    return <BaseRoutes />;
  }

  return (
    <Switch>
      <Route path="/auth" component={Auth} />
    </Switch>
  );
};

export default AuthGuard;
