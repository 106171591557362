import { RootState } from "app/store/rootReducer";
import { authActions } from "features/auth/model/authSlice";
import { signOut } from "features/auth/model/thunks";
import { Dispatch } from "redux";
import { profilesApi } from "./api";
import { profilesActions } from "./profilesSlice";
import { IGetSpvsInProfile } from "./types";

export const createProfile = (data: any) => (dispatch: Dispatch) => {
  //pending
  profilesApi
    .create(data)
    .then(() => {
      dispatch(profilesActions.invalidateKey());
    })
    .finally(() => {
      //pending
    });
};

export const getProfiles =
  () => (dispatch: Dispatch, getState: () => RootState) => {
    if (!getState().profiles.profiles?.length) {
      dispatch(profilesActions.setLoading(true));
    }
    dispatch(profilesActions.setFetching(true));
    profilesApi
      .get()
      .then(({ data }) => {
        dispatch(profilesActions.setProfiles(data.profiles));
      })
      .finally(() => {
        dispatch(profilesActions.setFetching(false));
        dispatch(profilesActions.setLoading(false));
      });
  };

export const editProfile = (data: any) => (dispatch: Dispatch) => {
  //pending
  profilesApi
    .patch(data)
    .then(() => {
      dispatch(profilesActions.invalidateKey());
    })
    .finally(() => {
      //pending
    });
};

export const getProfileDetail =
  (id: number | string) => (dispatch: Dispatch, getState: () => RootState) => {
    //loading
    profilesApi.getDetail(id).then(({ data }) => {
      //dispatch(profilesActions.setDetailedProfile({ data, key: id as string }));
    });
    //loading
  };

export const getSpvListInProfiles =
  () => (dispatch: Dispatch, getState: () => RootState) => {
    if (!Object.values(getState().profiles.spvsByProfiles)?.length) {
      //dispatch(profilesActions.setSpvsLoading(true));
    }
    profilesApi
      .getSpvList()
      .then(({ data }) => {
        const spvs: Record<string, IGetSpvsInProfile[]> = {};
        data.list.forEach((el) => {
          const profileId = el.profile_id;
          if (spvs[profileId]) {
            spvs[profileId].push(el);
          } else {
            spvs[profileId] = [el];
          }
        });
        //dispatch(profilesActions.setSpvsByProfiles(spvs));
      })
      .finally(() => {
        //dispatch(profilesActions.setSpvsLoading(false));
      });
  };

export const getDetailedSpv =
  (id: number) => (dispatch: Dispatch, getState: () => RootState) => {
    //loading
    profilesApi.getDetailedSpv(id).then(({ data }) => {
      //dispatch(profilesActions.setDetailedSpv({ key: String(id), data }));
    });
  };

////////

export const getAdminProfiles =
  () => (dispatch: Dispatch, getState: () => RootState) => {
    //loading
    profilesApi
      .getAdminProfiles()
      .then(({ data }) => {
        dispatch(profilesActions.setProfiles(data.list));
      })
      .catch((err) => {
        if (err.response.status == 403) {
          dispatch(signOut() as any);
        }
      })
      .finally(() => {
        //loading
      });
  };

export const getAdminUsers =
  () => (dispatch: Dispatch, getState: () => RootState) => {
    //loading
    profilesApi
      .getAdminUsers()
      .then(({ data }) => {
        dispatch(profilesActions.setUsers(data.list));
      })
      .finally(() => {
        //loading
      });
  };

export const approveProfile = (id: number) => (dispatch: Dispatch) => {
  //loading
  profilesApi.approveProfile(id).then(() => {
    dispatch(profilesActions.invalidateKey());
  });
};

export const getUsersStats = () => (dispatch: Dispatch) => {
  //loading
  profilesApi.getUsersStats().then(({ data }) => {
    dispatch(profilesActions.setUserStats(data));
  });
};

export const getProfileStats = () => (dispatch: Dispatch) => {
  //loading
  profilesApi.getProfilesStats().then(({ data }) => {
    dispatch(profilesActions.setProfileStats(data));
  });
};

export const deleteUserClient = (id: number) => (dispatch: Dispatch) => {
  dispatch(profilesActions.setDeletingIdsPartialy({ id, value: true }));
  profilesApi.delete(id).then(() => {
    dispatch(profilesActions.invalidateKey());
  });
};
