import { useTypedSelector } from "app/store/useTypedSelector";
import dayjs from "dayjs";
import TemplateBase from "features/template/TemplateBase/TemplateBase";
import FileViewer from "widgets/FileViewer/FileViewer";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import downloadFileByUrl from "shared/lib/utils/downloadFileByUrl";
import Button from "shared/ui/controls/Button/Button";
import Table from "shared/ui/layout/Table";

import styles from "./DataRoom.module.scss";
import { getDataRoomDocs, getFileUrl } from "./model/thunks";
import { IDataRoomDoc } from "./model/types";
import Search from "shared/ui/inputs/Search/Search";
import { useSearch } from "shared/lib/hooks/useSearch";
import { dateFormats } from "shared/constants/dateConstants";

const DataRoom = () => {
  const dispatch = useDispatch();

  const { docs, isLoading, fileUrls } = useTypedSelector(
    (state) => state.dataRoom
  );

  useEffect(() => {
    dispatch(getDataRoomDocs());
  }, []);

  const [openedFileId, setOpenedFileId] = useState("");

  const { filteredData, search, setSearch } = useSearch(docs, "FileName");

  return (
    <TemplateBase title="Data Room">
      <div className={styles.search}>
        <Search
          onChange={(e) => setSearch(e.target.value)}
          value={search}
          placeholder="File Name"
        />
      </div>
      <Table<IDataRoomDoc>
        data={filteredData}
        columns={[
          { title: "Name", dataIndex: "FileName" },
          { title: "Type", dataIndex: "FileType" },
          { title: "Source", dataIndex: "EntityType" },
          {
            title: "Upload Date",
            dataIndex: "CreatedAt",
            render: (date) => dayjs(date).format(dateFormats.day),
          },
          {
            title: "View",
            dataIndex: "ResourceId",
            render: (url, row) => (
              <>
                <Button
                  isPending={isLoading[url!]}
                  onClick={() => {
                    setOpenedFileId(url!);
                    dispatch(getFileUrl(url!));
                  }}
                >
                  Download
                </Button>
                <FileViewer
                  isOpen={openedFileId === url}
                  fileName={row?.OriginalName!}
                  fileUrl={fileUrls[url!]}
                  onClose={() => setOpenedFileId("")}
                />
              </>
            ),
          },
        ]}
      />
    </TemplateBase>
  );
};

export default DataRoom;
