import React from "react";
import Icon from "shared/ui/atoms/Icon";
import Button from "shared/ui/controls/Button/Button";

import styles from "./BottomControls.module.css";

interface IProps {
  onClickNext: () => void;
  onClickSkip?: () => void;
  onClickPrev?: () => void;
  isFinish?: boolean;
  onCommonClick?: () => void;
  isViewExisting?: boolean;
  isPending?: boolean;
  disabled?: boolean;
}

const BottomControls: React.FC<IProps> = ({
  onClickNext,
  onClickSkip,
  onClickPrev,
  isFinish,
  onCommonClick,
  isViewExisting,
  isPending,
  disabled,
}) => {
  const isBackBtn = isViewExisting || !!onClickPrev;
  return (
    <div className={styles.bottom} onClick={onCommonClick}>
      {isBackBtn && (
        <Button
          variant="light"
          onClick={onClickPrev}
          className={styles.backBtn}
        >
          {!isViewExisting && <Icon name="ArrowRight" />}
          {isViewExisting ? "Cancel" : "Back"}
        </Button>
      )}
      {/* {!isFinish && <Button variant="ghost">Skip</Button>} */}
      {!isFinish && (
        <Button
          onClick={onClickNext}
          type="submit"
          isPending={isPending}
          disabled={disabled}
        >
          {isViewExisting ? "Save" : "Next"}
          {!isViewExisting && <Icon name="ArrowRight" />}
        </Button>
      )}
      {isFinish && (
        <Button onClick={onClickNext} isPending={isPending} disabled={disabled}>
          Finish
        </Button>
      )}
    </div>
  );
};

export default BottomControls;
