import React, { useState } from "react";
import LabeledItem from "shared/ui/atoms/LabeledItem/LabeledItem";
import Input from "../Input/Input";

import styles from "./PercentageInput.module.css";

interface Iprops {
  label?: string;
  percents?: number;
}

const PercentageInput: React.FC<Iprops> = ({ label, percents = 40 }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  const isShow = isHovered || isFocused;

  return (
    <LabeledItem label={label}>
      {!isShow && (
        <div
          className={styles.container}
          onMouseEnter={() => {
            setIsHovered(true);
          }}
        >
          <div className={styles.bar} style={{ width: `${percents}%` }}></div>
          <div className={styles.percents}>{percents}%</div>
        </div>
      )}
      {isShow && (
        <div
          onMouseLeave={() => {
            setIsHovered(false);
          }}
          style={{ width: "100%" }}
        >
          <Input
            value={percents + ""}
            onBlur={() => setIsFocused(false)}
            onFocus={() => setIsFocused(true)}
          />
        </div>
      )}
    </LabeledItem>
  );
};

export default PercentageInput;
