import React from "react";

import styles from "./Button.module.css";

import cn from "classnames";

export interface IButtonProps {
  onClick?: React.MouseEventHandler;
  children: React.ReactNode;
  size?: "normal" | "wide";
  variant?: "empty" | "ghost" | "primary" | "light";
  disabled?: boolean;
  isPending?: boolean;
  className?: string;
  type?: "submit";
}

const Button: React.FC<IButtonProps> = ({
  onClick,
  children,
  size = "normal",
  variant = "primary",
  disabled,
  isPending,
  className,
  type,
}) => {
  return (
    <button
      className={cn({
        [styles.btn]: true,
        [styles.wide]: size === "wide",
        [styles[variant]]: true,
        [className!]: !!className,
        [styles.pending]: isPending,
      })}
      disabled={disabled || isPending}
      onClick={onClick}
      type={type}
    >
      {children}
    </button>
  );
};

export default Button;
