import { useTypedSelector } from "app/store/useTypedSelector";
import classNames from "classnames";
import { getDetailedSpv } from "pages/Profiles/model/thunks";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Icon from "shared/ui/atoms/Icon";
import Status from "shared/ui/atoms/Status/Status";
import Button from "shared/ui/controls/Button/Button";
import Toggle from "shared/ui/inputs/Toggle";
import Block from "shared/ui/layout/Block/Block";
import Table from "shared/ui/layout/Table";
import BaseModal, {
  IBaseModalProps,
} from "shared/ui/modals/BaseModal/BaseModal";

import styles from "./ProfileSpvSlide.module.css";

interface IProps extends Omit<IBaseModalProps, "children"> {
  spvId: number;
}

const ProfileSpvSlide: React.FC<IProps> = ({ spvId, ...baseProps }) => {
  const dispatch = useDispatch();
  const { detailedSpvs } = useTypedSelector((state) => state.profiles);
  const currentSpv = detailedSpvs[spvId];

  useEffect(() => {
    if (!spvId) return;
    dispatch(getDetailedSpv(spvId));
  }, [spvId]);

  return (
    <BaseModal
      classNameShadow={styles.shadow}
      className={styles.modal}
      {...baseProps}
    >
      <div className={styles.header}>
        <div className={styles.titles}>
          <div className={styles.title}>Seed Stage Startup</div>
          <div className={styles.subtitle}>RGA SSS SPV - Equity</div>
        </div>
        <div onClick={baseProps.onClose}>
          <Icon name="Close" />
        </div>
      </div>
      {/* <div className={styles.subheader}>
        <div className={styles.navs}>
          <div className={styles.nav}>Invite Investor</div>
          <div className={styles.nav}>Manage Founder</div>
          <div className={styles.nav}>Data Room</div>
          <div className={styles.nav}>Initiate Close</div>
        </div>
      </div> */}
      <div className={styles.cols}>
        <div className={styles.col}>
          <Block title="Need Your Attention">
            <div className={styles.btnAndTitle}>
              <span>
                <b>Document Needed:</b> Upload the Purchase Agreement to the
                Data Room.
              </span>
              <Button>Go to Data Room</Button>
            </div>
            <div className={styles.btnAndTitle}>
              <span>
                <b>Operating Agreement:</b>
              </span>
              <Button>Sign/Preview</Button>
            </div>
          </Block>
          <div className={styles.amounts}>
            <Block>
              <div className={styles.amount}>
                <div className={styles.amountDescr}>Total Subscribed</div>
                <div className={styles.amountNumber}>$85,000.00</div>
              </div>
            </Block>
            <Block>
              <div className={styles.amount}>
                <div className={styles.amountDescr}>Amount Received</div>
                <div className={styles.amountNumber}>$0.00</div>
              </div>
            </Block>
          </div>
          <Block title="SPV Cap Table">
            <Button variant="ghost" className={styles.addBtn}>
              <Icon name="Plus" />
              Add entry
            </Button>
            <Table<any>
              columns={[
                { title: "Investor", dataIndex: "a" },
                { title: "Subscription", dataIndex: "b" },
                { title: "Carry Percentage", dataIndex: "c" },
                { title: "Fixed Management Fee", dataIndex: "d" },
                {
                  title: "Status",
                  dataIndex: "e",
                  render: () => <Status>Done</Status>,
                },
              ]}
              data={[
                {
                  a: "Jacob Jones",
                  b: "$35,000,000",
                  c: "3%",
                  d: "0.00",
                  e: 9,
                },
                {
                  a: "Jacob Jones",
                  b: "$35,000,000",
                  c: "3%",
                  d: "0.00",
                  e: 9,
                },
                {
                  a: "Jacob Jones",
                  b: "$35,000,000",
                  c: "3%",
                  d: "0.00",
                  e: 9,
                },
              ]}
            />
          </Block>
          <Block title="SPV Investor Document">
            <Table<any>
              columns={[
                { title: "Investor", dataIndex: "a" },
                {
                  title: "Sub Docs",
                  dataIndex: "b",
                  render: () => (
                    <Status colorBg="#FFF2F2" colorText="#720202">
                      Rejected
                    </Status>
                  ),
                },
                {
                  title: "W-9",
                  dataIndex: "e",
                  render: () => (
                    <Status colorBg="#FFE2D2" colorText="#BA4505">
                      Pending Verification
                    </Status>
                  ),
                },
              ]}
              data={[
                { a: "Jacob Jones", b: 1, c: 1, d: 1, e: 9 },
                { a: "Jacob Jones", b: 1, c: 1, d: 1, e: 9 },
                { a: "Jacob Jones", b: 1, c: 1, d: 1, e: 9 },
              ]}
            />
          </Block>
        </div>
        <div className={styles.col}>
          <Block title="Estimated Net Investment">
            <div className={styles.fees}>
              <div className={styles.feeItem}>
                <span>Gross SPV Value</span>
                <b>$85,000.00</b>
              </div>
              <div className={styles.divider}></div>
              <div className={styles.feeItem}>
                <span>SPV Management Fees</span>
                <b>$85,000.00</b>
              </div>
              <div className={styles.divider}></div>
              <div className={styles.feeItem}>
                <span>Syndicately Admin Fees</span>
                <b>$85,000.00</b>
              </div>
              <div className={styles.divider}></div>
              <div className={styles.feeItem}>
                <span>Regulatory Fees</span>
                <b>$85,000.00</b>
              </div>
              <div className={classNames(styles.dividerBold, styles.divider)} />
              <div className={classNames(styles.feeItem, styles.feeTotal)}>
                <b>Total Fees</b>
                <b>$85,000.00</b>
              </div>
              <div className={classNames(styles.dividerBold, styles.divider)} />
              <div className={styles.feeItem}>
                <span>Estimated Net Investment</span>
                <b>$85,000.00</b>
              </div>
              <div className={styles.divider}></div>
              <div className={styles.feeItem}>
                <span>SPV Manager Carry</span>
                <b>$85,000.00</b>
              </div>
            </div>
          </Block>
          <Block
            title={
              <div className={styles.blockHeader}>
                SPV Details
                <div className={styles.editBtn}>
                  <Icon name="EditPencil" />
                </div>
              </div>
            }
          >
            <div className={styles.feeItem}>
              <span>Entity Name</span>
              <b>{currentSpv?.legal_entity}</b>
            </div>
            <div className={styles.divider}></div>
            <div className={styles.feeItem}>
              <span>Portfolio Company</span>
              <b>{currentSpv?.name}</b>
            </div>
            <div className={styles.divider}></div>
            <div className={styles.feeItem}>
              <span>Investment Type</span>
              <b>{currentSpv?.llc_type}</b>
            </div>
            <div className={styles.divider}></div>
            <div className={styles.feeItem}>
              <span>Target Seed Stage Startup</span>
              <b>no data</b>
            </div>
            <div className={classNames(styles.dividerBold, styles.divider)} />
            <div className={styles.feeItem}>
              <span>Accepting Subscription Docs</span>
              <Toggle checked={false} />
            </div>
            <div className={classNames(styles.feeItem, styles.feeToggle)}>
              <span>Enforce Investment Limit</span>
              <Toggle checked={currentSpv?.allow_overflow} />
            </div>
            <div className={styles.feeItem}>
              <span>Manager pays the SPV Fees</span>
              <Toggle checked={!!currentSpv?.management_fee_percent} />
            </div>
            <div className={classNames(styles.dividerBold, styles.divider)} />
            <div className={styles.feeItem}>
              <span>SPV Target Value</span>
              <b>no data</b>
            </div>
            <div className={styles.divider}></div>
            <div className={styles.feeItem}>
              <span>Target Close Date</span>
              <b>no data</b>
            </div>
            <div className={styles.divider}></div>
            <div className={styles.feeItem}>
              <span>Investment Minimum</span>
              <b>${currentSpv?.investor_min_val}</b>
            </div>
            <div className={styles.divider}></div>
            <div className={styles.feeItem}>
              <span>Investment Maximum</span>
              <b>${currentSpv?.investor_max_val}</b>
            </div>
          </Block>
        </div>
      </div>
    </BaseModal>
  );
};

export default ProfileSpvSlide;
