import React, { ReactNode } from "react";
import styles from "./Table.module.scss";
import TableCell, { TableCellProps } from "../TableCell";
import LoadingTable from "./LoadingTable";
import cn from "classnames";

export interface Column<T> {
  title: string;
  dataIndex?: keyof T;
  render?: (text?: string, record?: T, index?: number) => React.ReactNode;
  record?: any;
  styles?: any;
  skip?: boolean;
  getColor?: (text?: string, record?: T, index?: number) => string;
  width?: number;
}

interface TableProps<T> {
  columns: Column<T>[];
  data: T[];
  footer?: TableCellProps[];
  header?: ReactNode;
  isLoading?: boolean;
  innerClassName?: string;
  onRowClick?: (row: T) => void;
}

const Table = <T extends { [key: string]: any }>(props: TableProps<T>) => {
  const footerColspan =
    props.columns.length - (props.footer ? props.footer.length - 1 : 0);

  return (
    <div>
      {props.header && <div className={styles.mainHeader}>{props.header}</div>}
      <div className={cn(styles.container, props.innerClassName)}>
        {props.isLoading && <LoadingTable />}
        {!props.isLoading && (
          <table className={styles.table}>
            <thead className={styles.header}>
              <tr>
                {props.columns?.map((column, idx) => {
                  if (column.skip) return null;
                  return (
                    <th
                      style={column.styles && { ...column.styles }}
                      className={styles.cell}
                      key={(column.dataIndex as string) + "_" + idx}
                    >
                      {column.title}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody className={styles.body}>
              <>
                {props.data?.map((item, idx) => (
                  <tr key={idx} onClick={() => props.onRowClick?.(item)}>
                    {props.columns?.map((column, index) => {
                      if (column.skip) return null;
                      return (
                        <TableCell
                          key={index + "_" + idx}
                          index={index}
                          text={item[column.dataIndex as string]}
                          record={item}
                          render={column.render}
                          color={
                            column?.getColor?.(
                              item[column.dataIndex as string],
                              item
                            ) as any
                          }
                          width={column.width}
                        />
                      );
                    })}
                  </tr>
                ))}
                {props.footer && (
                  <tr className={styles.footer}>
                    {props.footer.map((item, index) => (
                      <TableCell
                        key={item.text + "_" + index}
                        {...item}
                        colspan={
                          item.colspan ?? index === props.footer!.length - 1
                            ? footerColspan
                            : 1
                        }
                      />
                    ))}
                  </tr>
                )}
              </>
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default Table;
