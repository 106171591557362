import BottomControls from "pages/Profiles/widgets/CreateProfileModal/ui/BottomControls/BottomControls";
import React from "react";
import Button from "shared/ui/controls/Button/Button";
import Dropdown from "shared/ui/inputs/Dropdown";
import Input from "shared/ui/inputs/Input/Input";
import Block from "shared/ui/layout/Block/Block";

import styles from "./EditSpvAdminOverview.module.scss";

interface IProps {
  onClose: () => void;
}

const EditSpvAdminOverview: React.FC<IProps> = ({ onClose }) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>Edit Details</div>
      <div className={styles.cols}>
        <Block title="Client SPVs - Ross G - Qatch">
          <div className={styles.form}>
            <Input label="LLC Type" placeholder="LLC (Additional Charge)" />
            <Input label="SPV Target Value" placeholder="$150,000.00" />
            <Input label="Portfolio Company Name" placeholder="Qatch" />
            <Input label="Can SPV Go Over Target" placeholder="Yes" />
            <Input
              label="Portfolio Company Legal Entity"
              placeholder="Qatch Inc."
            />
            <Input label="Investor Minimum" placeholder="$0.00" />
            <Input
              label="Portfolio Company Website"
              placeholder="website.com"
            />
            <Input label="Investor Maximum" placeholder="$55,000.00" />
            <Input label="SPV Legal Entity Name" placeholder="RGA Qatch SPV" />
            <Input label="Manager Carry Percent" placeholder="0%" />
            <Input label="Type of Investment" placeholder="Equity" />
            <Input label="Manager Fee Percent" placeholder="0%" />
            <Input label="SPV Target Close Date" placeholder="07-06-2021" />
            <Input label="Manager Fee (per Investor)" placeholder="$0.00" />
          </div>
        </Block>
        <Block title="SPV Details" className={styles.smallBlock}>
          <div className={styles.inner}>
            <Input label="SPV Legal Name" placeholder="RGA SPV" />
            <Dropdown
              label="Status"
              items={[{ key: "Closed", label: "Closed" }]}
              value="Closed"
            />
          </div>
        </Block>
      </div>
      <div className={styles.bottom}>
        <Button variant="light" onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={onClose}>Update</Button>
      </div>
    </div>
  );
};

export default EditSpvAdminOverview;
