import React, { FC } from "react";
import LabeledItem from "../../atoms/LabeledItem/LabeledItem";

import styles from "./Input.module.css";
import cn from "classnames";

interface IProps {
  placeholder?: string;
  value?: string;
  classNameInput?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  label?: string;
  name?: string;
  classNameContainer?: string;
  type?: HTMLInputElement["type"];
  postFix?: React.ReactNode;
  onBlur?: () => void;
  onFocus?: () => void;
  disabled?: boolean;
}

const Input: FC<IProps> = ({
  placeholder,
  value,
  classNameInput,
  label,
  onChange,
  name,
  classNameContainer,
  type,
  postFix,
  onBlur,
  onFocus,
  disabled,
}) => {
  return (
    <LabeledItem label={label} className={classNameContainer}>
      <div className={styles.inputWrapper}>
        <input
          className={cn(styles.input, classNameInput)}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          name={name}
          type={type}
          onBlur={onBlur}
          onFocus={onFocus}
          disabled={disabled}
        />
        {postFix && <div className={styles.postFix}>{postFix}</div>}
      </div>
    </LabeledItem>
  );
};

export default Input;
