//@ts-nocheck
import ConfidentialText from "features/Consent/ConfidentialText";
import ConsentText from "features/Consent/ConsentText";
import React, { useMemo, useState } from "react";
import { Checkbox } from "shared/ui/inputs/Checkbox/Checkbox";
import BottomControls from "../../ui/BottomControls/BottomControls";
import { ICreateProfileStepProps } from "../CreateProfileStep1/CreateProfileStep1";

interface IProps extends ICreateProfileStepProps {
  onSubmit: () => void;
  isPending: boolean;
}

const ConfidentialStep: React.FC<IProps> = ({
  setStep,
  onSubmit,
  isPending,
}) => {
  const [disbled, setDisabled] = useState(true);

  return (
    <>
      <div>
        <ConfidentialText />
        <Checkbox
          label="I have read, understood the Confidential Information Disclaimer and I accept."
          checked={!disbled}
          onChange={(e) => setDisabled(!e.target.checked)}
        />
      </div>
      <BottomControls
        onClickPrev={() => {
          setStep((prev) => prev - 1);
        }}
        disabled={disbled}
        isFinish
        onClickNext={() => {
          onSubmit();
        }}
        isPending={isPending}
      />
    </>
  );
};

export default ConfidentialStep;
