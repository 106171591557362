import { useTypedSelector } from "app/store/useTypedSelector";
import { signOut } from "features/auth/model/thunks";
import React, { FC, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import useOnClickOutside from "shared/lib/hooks/useOnClickOutside";
import Icon from "shared/ui/atoms/Icon";
import cn from "classnames";

import styles from "./SideBarProfile.module.css";

interface IProps {
  avatar?: string;
}

const SideBarProfile: FC<IProps> = ({ avatar }) => {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState(false);
  const popup = useRef<HTMLDivElement>(null);
  const { me, meIsLoading } = useTypedSelector((state) => state.authSlice);

  useOnClickOutside(popup, () => {
    setExpanded(false);
  });

  const logout = () => {
    dispatch(signOut());
  };

  return (
    <div className={styles.container}>
      <div className={styles.avatar}>
        {avatar ? (
          <img src={avatar} alt="avatar" />
        ) : (
          <div className={styles.avatarHolder}>
            <Icon name="Prodile" />
          </div>
        )}
      </div>
      <div className={cn(styles.names, { [styles.loading]: meIsLoading })}>
        <div className={styles.name}>
          {me?.first_name + " " + me?.last_name}
        </div>
        <div className={styles.email}>{me?.email}</div>
      </div>
      <div className={styles.trigger} onClick={() => setExpanded(true)}>
        <Icon name="Chevron" />
      </div>
      {expanded && (
        <div ref={popup} className={styles.popup} onClick={logout}>
          Logout
        </div>
      )}
    </div>
  );
};

export default SideBarProfile;
