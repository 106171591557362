import { Http } from "app/Http/Http";
import { RootState } from "app/store/rootReducer";
import { Dispatch } from "redux";
import authApi from "./authApi";
import { authActions } from "./authSlice";

export const signUp = (data: any) => (dispatch: Dispatch) => {
  authApi.signup(data).then(({ data }) => {
    localStorage.setItem("access", data.access);
    //Http.defaults.headers.
    dispatch(authActions.setIsAuth(true));
  });
};

export const signIn = (data: any) => (dispatch: Dispatch) => {
  authApi.signin(data).then(({ data }) => {
    localStorage.setItem("access", data.access);
    dispatch(authActions.setIsAuth(true));
  });
};

export const signOut = () => (dispatch: Dispatch) => {
  authApi.signout().finally(() => {
    localStorage.removeItem("access");
    dispatch(authActions.setIsAuth(false));
  });
};

export const getMe = () => (dispatch: Dispatch, getState: () => RootState) => {
  if (!getState().authSlice.me) {
    dispatch(authActions.setMeLoading(true));
  }
  authApi
    .getMe()
    .then(({ data }) => {
      dispatch(authActions.setMe(data));
    })
    .finally(() => {
      dispatch(authActions.setMeLoading(false));
    });
};
