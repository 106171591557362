import { getMe } from "features/auth/model/thunks";
import Dashboard from "pages/Dashboard/Dashboard";
import DataRoom from "pages/DataRoom/DataRoom";
import Deals from "pages/Deals/Deals";
import InvestmentAdmin from "pages/InvestmentAdmin/InvestmentAdmin";
import Organization from "pages/Organization/Organization";
import Orgs from "pages/Orgs/Orgs";
import ClientUsers from "pages/Profiles/ClientUsers";
import { getAdminProfiles } from "pages/Profiles/model/thunks";
import Profiles from "pages/Profiles/Users";
import ProfilesApprovals from "pages/ProfilesApprovals/ProfilesApprovals";
import SpvAdmin from "pages/SpvAdmin/SpvAdmin";
import SpvSetup from "pages/SpvSetup/SpvSetup";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import Auth from "../../pages/Auth/Auth";

const BaseRoutes = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMe());
    dispatch(getAdminProfiles());
  }, []);

  return (
    <Switch>
      {/* <Route path="/" exact>
        <Dashboard />
      </Route> */}
      <Route path="/users" exact>
        <Profiles />
      </Route>
      <Route path="/profiles" exact>
        <ClientUsers />
      </Route>
      <Route path="/profiles-approvals" exact>
        <ProfilesApprovals />
      </Route>
      <Route path="/deals" exact>
        <Deals />
      </Route>
      <Route path="/orgs" exact>
        <Orgs />
      </Route>
      <Route path="/orgs/:orgId" exact>
        <Organization />
      </Route>
      <Route path="/spv-setup" exact>
        <SpvSetup />
      </Route>
      <Route path="/spv/:id/:tab" exact>
        <SpvAdmin />
      </Route>
      <Route path="/deals" exact>
        <Deals />
      </Route>
      <Route path="/data" exact>
        <DataRoom />
      </Route>
      <Route path="/investment-admin" exact>
        <InvestmentAdmin />
      </Route>
      <Redirect to="/users" />
    </Switch>
  );
};

export default BaseRoutes;
