import React, { FC, useEffect, useRef, useState } from "react";

import type { IBaseModalProps } from "shared/ui/modals/BaseModal/BaseModal";
import SplitedModal from "shared/ui/modals/SplitedModal/SplitedModal";

import styles from "./CreateProfileModal.module.css";
import CreateProfileModalSideBar from "./ui/CreateProfileModalSideBar/CreateProfileModalSideBar";
import { getCreateProfileTitles } from "./utils";
import CraeteProfileStep2, {
  ICraeteProfileStep2,
} from "./steps/CraeteProfileStep2/CraeteProfileStep2";
import CraeteProfileStep3, {
  ICraeteProfileStep3,
} from "./steps/CreateProfileStep3/CreateProfileStep3";
import { useDispatch } from "react-redux";
import { createProfile } from "pages/Profiles/model/thunks";
import { ICreateProfile } from "pages/Profiles/model/types";
import { useTypedSelector } from "app/store/useTypedSelector";
import { bankActions } from "widgets/BankAccount/model/bankSlice";
//import { getInviteInfo } from "widgets/SpvDetail/model/thunks";
import ConsentStep from "./steps/ConsentStep/ConsentStep";
import ConfidentialStep from "./steps/ConfidentialStep/ConfidentialStep";

interface IProps extends Omit<IBaseModalProps, "children"> {}

export type ProfileTypes = "INDIVIDUAL" | "ENTITY" | "TRUST";

const CreateProfileModal: FC<IProps> = ({ ...baseProps }) => {
  const dispatch = useDispatch();
  const [step, setStep] = useState(0);
  const [type, setType] = useState<ProfileTypes>("INDIVIDUAL");
  const [form, setForm] = useState<Partial<ICreateProfile>>({});

  //const { publicToken } = useTypedSelector((state) => state.bank);
  const { isPending } = useTypedSelector((state) => state.profiles);

  const onSubmitStep2 = (values: ICraeteProfileStep2) => {
    setForm((prev) => ({ ...prev, ...values }));
  };

  const onSubmitStep3 = (values: ICraeteProfileStep3) => {
    setForm((prev) => ({ ...prev, ...values }));
  };

  const onFinish = (values: Partial<ICreateProfile> = {}) => {
    /* if (isPending) return;
    const inviteId = localStorage.getItem("inviteId");
    dispatch(
      createProfile(
        { ...form, ...values, type, plaid_token: publicToken },
        inviteId!,
        undefined,
        undefined,
        baseProps.onClose
      )
    ); */
  };

  /* const { inviteInfo, invalidateKey } = useTypedSelector(
    (state) => state.detailSpv
  ); */
  /* 
  useEffect(() => {
    const inviteId = localStorage.getItem("inviteId");
    if (!inviteId || !inviteInfo) return;
    setForm((prev) => ({
      ...prev,
      subscription_amount: inviteInfo.investor_min_val as any,
    }));
  }, [inviteInfo]); */

  /*   useEffect(() => {
    const inviteId = localStorage.getItem("inviteId");
    if (inviteId) return;
    baseProps.onClose();
    setStep(0);
    setType("INDIVIDUAL");
  }, [invalidateKey]); */

  /*   useEffect(() => {
    const inviteId = localStorage.getItem("inviteId");
    if (inviteId) {
      dispatch(getInviteInfo(inviteId));
    }
    return () => {
      dispatch(bankActions.setPublicToken(""));
    };
  }, []); */

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    containerRef.current?.parentElement?.scrollTo({ top: 0 });
  }, [step]);

  /*   const inviteText = inviteInfo?.name
    ? `You are invited to join SPV ${inviteInfo?.name}`
    : ""; */

  return (
    <SplitedModal
      sidebar={<CreateProfileModalSideBar step={step} type={type} />}
      {...baseProps}
      title={getCreateProfileTitles(step, type)?.title}
      subtitle={getCreateProfileTitles(step, type)?.subtitle}
    >
      <div className={styles.content} ref={containerRef}>
        {/*   {step === 0 && (
          <CraeteProfileStep2
            setStep={setStep}
            onSubmit={onSubmitStep2}
            minAmount={inviteInfo?.investor_min_val}
            maxAmount={inviteInfo?.investor_max_val}
            initialData={form as any}
            inviteText={inviteText}
            isInvite={!!inviteInfo}
          />
        )} */}
        {step === 1 && (
          <CraeteProfileStep3
            setStep={setStep}
            onSubmit={onSubmitStep3}
            initialData={form as any}
            onFinish={onFinish}
          />
        )}
        {step === 2 && <ConsentStep setStep={setStep} />}
        {step === 3 && (
          <ConfidentialStep
            isPending={isPending}
            setStep={setStep}
            onSubmit={onFinish}
          />
        )}
        {/* {step === 2 && (
          <CraeteProfileStep4
            setStep={setStep}
            onSubmit={(file, type) => {
              setPassport(file);
              setPassportType(type);
            }}
            initialData={form as any}
          />
        )}
        {step === 3 && (
          <CraeteProfileStep5
            isIndividual
            setStep={setStep}
            onFinish={onFinish}
            initialData={form as any}
          />
        )} */}
      </div>
    </SplitedModal>
  );
};

export default CreateProfileModal;
