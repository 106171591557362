import React from "react";

import styles from "./AuthHeader.module.css";

interface IProps {
  children: React.ReactNode;
}

const AuthHeader: React.FC<IProps> = ({ children }) => {
  return (
    <div className={styles.header}>
      <div className={styles.logo}>Syndicately</div>
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default AuthHeader;
