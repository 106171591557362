import { Notifications } from "app/Notifications/Notifications";
import { useTypedSelector } from "app/store/useTypedSelector";
import React, { useEffect, useMemo, useState } from "react";
import { beautifyValue } from "shared/lib/utils/beautifyValue";
import Status from "shared/ui/atoms/Status/Status";
import Button from "shared/ui/controls/Button/Button";
import { Checkbox } from "shared/ui/inputs/Checkbox/Checkbox";
import Dropdown from "shared/ui/inputs/Dropdown";
import Block from "shared/ui/layout/Block/Block";
import { w9docsApi } from "./model/api";

import styles from "./W9DocButton.module.css";

interface IProps {
  profileId: number;
  isIndividual: boolean;
}

const W9DocButton: React.FC<IProps> = ({ profileId, isIndividual }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState("");
  const [token, setToken] = useState("");
  const [documentId, setDocumentId] = useState("");
  const [isIframe, setIsIframe] = useState(false);

  const doc = useTypedSelector((state) => state.profiles.detailedProfiles)[
    profileId
    //@ts-ignore
  ]?.doc;

  useEffect(() => {
    if (doc?.document_code) {
      setType(doc?.document_code);
      setToken(doc.session_id);
      setDocumentId(doc.document_id);
      setIsIframe(true);
    }
  }, [doc]);

  const docTypes = useMemo(() => {
    return [
      {
        label: isIndividual
          ? "W8-BEN (International Individuals Only)"
          : "W8-BEN-E",
        key: "w8-ben",
      },
      { label: "W9 (US Residents Only)", key: "w9" },
    ];
  }, [isIndividual]);

  useEffect(() => {
    if (!type) return;
    if (doc) return;
    setIsLoading(true);
    w9docsApi
      .getSessionId(type, profileId)
      .then(({ data }) => {
        setToken(data.session_id);
        setDocumentId(data.document_id);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [type, profileId, doc]);

  const onClick = () => {
    if (!token) return;
    setIsIframe(true);
  };

  useEffect(() => {
    const handler = (event: MessageEvent) => {
      const type = event.data && event.data.type;

      switch (type) {
        case "session_view.document.completed":
          w9docsApi.completeSignage(profileId, token, documentId).then(() => {
            Notifications.success("Successfully Signed");
          });
          break;
      }
    };
    window.addEventListener("message", handler);
    return () => window.removeEventListener("message", handler);
  }, [documentId]);

  const [isAgree, setIsAgree] = useState(false);

  return (
    <Block>
      <div className={styles.grid}>
        <div className={styles.row}>
          <Dropdown
            items={docTypes}
            onSelect={setType}
            value={doc?.document_code ?? type}
            placeholder="Select"
            containerClassName={styles.select}
            disabled={isIframe || !!doc}
          />
          {doc?.status && (
            <Status>{beautifyValue.capitalize(doc?.status)}</Status>
          )}
        </div>
        <div className={styles.row}>
          {!isIframe && (
            <Checkbox
              checked={isAgree}
              onChange={(e: any) => setIsAgree(e.target.checked)}
              label="Agree to receive tax documents electronically"
            />
          )}
          {!isIframe && (
            <Button
              disabled={!type || isIframe || !isAgree}
              isPending={isLoading}
              onClick={onClick}
              /* onClick={() => {
            setIsLoading(true);
            w9docsApi
              .sign(profileId)
              .then(() => {
                Notifications.success("Email sent");
              })
              .finally(() => {
                setIsLoading(false);
              });
          }} */
            >
              Sign {/* {isIndividual ? "W8-BEN" : "W-8BEN-E"} */}
            </Button>
          )}
        </div>
      </div>
      <div className={styles.frameWrapper}>
        {isIframe && (
          <iframe
            title="Pandadoc"
            src={`https://app.pandadoc.com/s/${token}/`}
            width="100%"
            height="500px"
          ></iframe>
        )}
      </div>
    </Block>
  );
};

export default W9DocButton;
