import React, { FC, ReactNode, useState } from "react";
import cn from "classnames";

import styles from "./SideBarLink.module.css";
import { NavLink } from "react-router-dom";
import Icon, { IconNames } from "shared/ui/atoms/Icon";

interface IProps {
  isDirectlyActive?: boolean;
  link: string;
  text: ReactNode;
  icon?: IconNames;
  items?: { link: string; text: ReactNode }[];
  count?: number;
}

const SideBarLink: FC<IProps> = ({
  isDirectlyActive,
  link,
  text,
  icon,
  items,
  count,
}) => {
  const isExpandable = !!items?.length;
  const [expanded, setExpanded] = useState(false);

  return (
    <>
      <NavLink
        className={(isActive) =>
          cn(styles.link, { [styles.active]: isActive || isDirectlyActive })
        }
        to={link}
        exact
        onClick={
          isExpandable
            ? (e) => {
                e.preventDefault();
                setExpanded((prev) => !prev);
              }
            : undefined
        }
      >
        <div className={styles.icon}>
          <Icon name={icon!} />
        </div>
        <div className={styles.text}>{text}</div>
        {isExpandable && (
          <div className={styles.chevrone}>
            <Icon name="ChevroneRight" />
          </div>
        )}
        {!!count && <div className={styles.count}>{count}</div>}
      </NavLink>
      {expanded &&
        items?.map((el) => (
          <NavLink
            key={el.link}
            className={(isActive) =>
              cn(styles.child, {
                [styles.activeChild]: isActive,
              })
            }
            to={el.link}
          >
            {el.text}
          </NavLink>
        ))}
    </>
  );
};

export default SideBarLink;
