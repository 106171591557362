import { Http } from "app/Http/Http";

export const dataRoomApi = {
  get: () => {
    return Http.get(`/admin/data_room`);
  },
  getFileUrl: (resourceId: string) => {
    return Http.get(`/doc/fetch_url/${resourceId}`);
  },
};
