import { Store } from "react-notifications-component";

export const Notifications = {
  success: (message: string) => {
    Store.addNotification({
      title: "success",
      message,
      type: "success",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 1000,
      },
    });
  },
  fail: (message: string) => {
    Store.addNotification({
      title: "danger",
      message,
      type: "danger",
      insert: "top",
      container: "top-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 1000,
      },
    });
  },
};
