//@ts-nocheck
import { ICreateProfile, IProfileResponse } from "pages/Profiles/model/types";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Input from "shared/ui/inputs/Input/Input";
import BottomControls from "../../ui/BottomControls/BottomControls";
import { ICreateProfileStepProps } from "../CreateProfileStep1/CreateProfileStep1";
import { zodResolver } from "@hookform/resolvers/zod";
import * as z from "zod";

import styles from "./CreateProfileStep3.module.css";
import { formatText } from "shared/lib/utils/formatText";

import GeoSelectsContextProvider from "features/GeoSelects/GeoSelectsContextProvider";
import StateSelect, {
  statefulCountries,
} from "features/GeoSelects/StateSelect";
import CountrySelect from "features/GeoSelects/CountrySelect";

export type ICraeteProfileStep3 = Pick<
  ICreateProfile,
  "address_1" | "address_2" | "city" | "state"
> & { country: string; zip_code: string };

interface IProps extends ICreateProfileStepProps {
  onSubmit: (values: ICraeteProfileStep3) => void;
  isViewExisting?: boolean;
  onCancel?: () => void;
  initialData?: IProfileResponse;
  onFinish?: (values: ICraeteProfileStep3) => void;
}

const schema = z.object({
  address_1: z.string().min(1, { message: "Required" }),
  address_2: z.optional(z.string()),
  city: z.string().min(1, { message: "Required" }),
  country: z.string().min(1, { message: "Required" }),
  zip_code: z.string().min(1, { message: "Required" }),
});

const schemaWithState = schema.and(
  z.object({ state: z.string().min(1, { message: "Required" }) })
);

const CraeteProfileStep3: React.FC<IProps> = ({
  setStep,
  onSubmit,
  isViewExisting,
  onCancel,
  initialData,
  onFinish,
}) => {
  const [country, setCountry] = useState("");

  const isWithState = statefulCountries.includes(country);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
  } = useForm<ICraeteProfileStep3>({
    reValidateMode: "onBlur",
    defaultValues: initialData,
    resolver: zodResolver(isWithState ? schemaWithState : schema),
    mode: "onChange",
  });

  return (
    <GeoSelectsContextProvider
      onChangeCountry={(c) => {
        setValue("state", "");
        setCountry(c);
      }}
    >
      <div className={styles.form}>
        <Controller
          control={control}
          name="address_1"
          render={({ field }) => (
            <Input
              {...field}
              label="Address 1"
              placeholder="Address 1"
              error={errors.address_1?.message}
              disabled={isViewExisting}
            />
          )}
        />
        <Controller
          control={control}
          name="address_2"
          render={({ field }) => (
            <Input
              {...field}
              label="Address 2"
              placeholder="Address 2"
              error={errors.address_2?.message}
              disabled={isViewExisting}
            />
          )}
        />
        <Controller
          control={control}
          name="city"
          render={({ field }) => (
            <Input
              {...field}
              label="City"
              placeholder="City"
              error={errors.city?.message}
              disabled={isViewExisting}
            />
          )}
        />
        <Controller
          control={control}
          name="country"
          render={({ field }) => (
            <CountrySelect
              {...field}
              label="Country"
              placeholder="Country"
              onSelect={(v) => {
                field.onChange(v);
              }}
            />
          )}
        />
        <Controller
          control={control}
          name="state"
          render={({ field }) => (
            <StateSelect
              {...field}
              label="State"
              placeholder="State"
              disabled={isViewExisting}
              onSelect={field.onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="zip_code"
          render={({ field }) => (
            <Input
              {...field}
              label="Zipcode / Postal Code"
              placeholder="Zipcode / Postal Code"
              error={errors.zip_code?.message}
              format={formatText.int}
              disabled={isViewExisting}
            />
          )}
        />

        {!isViewExisting && (
          <BottomControls
            disabled={!isValid}
            onCommonClick={handleSubmit(onSubmit)}
            onClickNext={() => {
              setStep((prev) => prev + 1);
            }}
            onClickPrev={() => {
              if (isViewExisting) {
                onCancel?.();
              } else {
                setStep((prev) => prev - 1);
              }
            }}
            isViewExisting={isViewExisting}
            //isFinish={!isViewExisting}
          />
        )}
      </div>
    </GeoSelectsContextProvider>
  );
};

export default CraeteProfileStep3;
