import React from "react";
import styles from "./TableCell.module.scss";
import cn from "classnames";

export interface TableCellData {
  text: string | number;
  color?: "inherit" | "white" | "red" | "green" | "blue";
  colspan?: number;
  record?: any;
  width?: number;
}

export interface TableCellProps extends TableCellData {
  // eslint-disable-next-line no-unused-vars
  render?: (text: any, record: any, index?: number) => React.ReactNode;
  index: number;
}

const TableCell: React.FC<TableCellProps> = ({
  render,
  text,
  record,
  color = "inherit",
  colspan = 1,
  index,
  width,
}) => {
  return (
    <td
      colSpan={colspan}
      className={cn(styles.cell, styles.bodyCell, styles[color])}
      style={{ width: width ? `${width}%` : "" }}
    >
      {render ? (
        render(text, record, index)
      ) : text ? (
        text
      ) : (
        <span style={{ color: "var(--color-text-gray-darker)" }}>None</span>
      )}
    </td>
  );
};

export default TableCell;
