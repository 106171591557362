import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ISpv } from "./types";

interface DealsState {
  isLoading: Record<string, boolean>;
  spvs: ISpv[];
}

const initialState: DealsState = {
  isLoading: {},
  spvs: [],
};

const dealsSlice = createSlice({
  name: "deals",
  initialState,
  reducers: {
    setSpvs: (state, action: PayloadAction<ISpv[]>) => {
      state.spvs = action.payload;
    },
  },
});

export const dealsActions = dealsSlice.actions;

export default dealsSlice.reducer;
