import { useTypedSelector } from "app/store/useTypedSelector";
import TemplateBase from "features/template/TemplateBase/TemplateBase";
import { getSpvList } from "pages/Deals/model/thunks";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useParams,
} from "react-router-dom";
import Tabs from "shared/ui/controls/Tabs/Tabs";

import styles from "./SpvAdmin.module.scss";
import SpvAdminContributions from "./subpages/SpvAdminContributions/SpvAdminContributions";
import SpvAdminDocuments from "./subpages/SpvAdminDocuments/SpvAdminDocuments";
import SpvAdminFinances from "./subpages/SpvAdminFinances/SpvAdminFinances";
import SpvAdminOverview from "./subpages/SpvAdminOverview/SpvAdminOverview";

const tabs = [
  { id: "overview", name: "Overview" },
  { id: "finances", name: "SPV Finances" },
  { id: "documents", name: "Entity Documents" },
  /* { id: "data", name: "Data Room" },
  { id: "close", name: "Close" }, */
  { id: "contributions", name: "Capital Contributions" },
  /*  { id: "tax", name: "Tax Docs" }, */
];

const SpvAdmin = () => {
  const history = useHistory();
  const { tab, id } = useParams<Record<string, string>>();
  const dispatch = useDispatch();

  const { spvs } = useTypedSelector((state) => state.deals);

  useEffect(() => {
    if (spvs?.length) return;
    dispatch(getSpvList());
  }, [id]);

  const spv = useMemo(() => {
    return spvs?.find((el) => el.id === +id);
  }, [spvs, id]);

  if (!spv) {
    return (
      <TemplateBase title="SPV Admin">
        <div className={styles.tabs}>
          <Tabs
            tabs={tabs}
            activeTab={tab}
            onClick={(tab) => history.push(`/spv/${id}/${tab}`)}
          />
        </div>
        loading...
      </TemplateBase>
    );
  }

  return (
    <TemplateBase title="SPV Admin">
      <div className={styles.tabs}>
        <Tabs
          tabs={tabs}
          activeTab={tab}
          onClick={(tab) => history.push(`/spv/${id}/${tab}`)}
        />
      </div>
      <Switch>
        <Route exact path={`/spv/${id}/overview`}>
          <SpvAdminOverview spv={spv} />
        </Route>
        <Route exact path={`/spv/${id}/finances`}>
          <SpvAdminFinances spv={spv} />
        </Route>
        <Route exact path={`/spv/${id}/documents`}>
          <SpvAdminDocuments />
        </Route>
        <Route exact path={`/spv/${id}/contributions`}>
          <SpvAdminContributions spv={spv} />
        </Route>
        <Redirect to={`/spv/${id}/overview`} />
      </Switch>
    </TemplateBase>
  );
};

export default SpvAdmin;
