import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ISpvProfile, ISpvTransaction } from "./types";

interface State {
  transactions: Record<string, ISpvTransaction[]>;
  profiles: Record<string, ISpvProfile[]>;
}

const initialState: State = {
  transactions: {},
  profiles: {},
};

const spvSlice = createSlice({
  name: "spv",
  initialState,
  reducers: {
    setTransactions: (
      state,
      action: PayloadAction<{ data: ISpvTransaction[]; key: string | number }>
    ) => {
      state.transactions[action.payload.key] = action.payload.data;
    },
    setProfiles: (
      state,
      action: PayloadAction<{ data: ISpvProfile[]; key: string | number }>
    ) => {
      console.log(action.payload);
      state.profiles[action.payload.key] = action.payload.data;
    },
  },
});

export const spvActions = spvSlice.actions;

export default spvSlice.reducer;
