//@ts-nocheck
import dayjs from "dayjs";
import CountrySelect from "features/GeoSelects/CountrySelect";
import GeoSelectsContextProvider from "features/GeoSelects/GeoSelectsContextProvider";
import React from "react";
import { Controller } from "react-hook-form";
import { dateFormats } from "shared/constants/dateConstants";
import { scrollIntoView } from "shared/lib/utils/scrollIntoView";
import Datepicker from "shared/ui/inputs/Datepicker/Datepicker";
import Input from "shared/ui/inputs/Input/Input";
import PercentageInput from "shared/ui/inputs/PercentageInput/PercentageInput";

interface IProps {
  control: any;
  errors?: Record<string, { message?: string }>;
  withoutOwnerPercentage?: boolean;
}

const PersonForm: React.FC<IProps> = ({
  control,
  errors,
  withoutOwnerPercentage,
}) => {
  return (
    <>
      <Controller
        control={control}
        name="first_name"
        render={({ field }) => (
          <Input
            {...field}
            label="First Name"
            placeholder="First Name"
            onFocus={scrollIntoView}
          />
        )}
      />
      <Controller
        control={control}
        name="middle_name"
        render={({ field }) => (
          <Input
            {...field}
            label="Middle Name"
            placeholder="Middle Name"
            onFocus={scrollIntoView}
          />
        )}
      />
      <Controller
        control={control}
        name="last_name"
        render={({ field }) => (
          <Input
            {...field}
            label="Last Name"
            placeholder="Last Name"
            onFocus={scrollIntoView}
          />
        )}
      />
      <Controller
        control={control}
        name="email"
        render={({ field }) => (
          <Input
            {...field}
            label="Email Address"
            placeholder="Email Address"
            error={errors.email?.message}
            onFocus={scrollIntoView}
          />
        )}
      />
      <Controller
        control={control}
        name="phone"
        render={({ field }) => (
          <Input
            {...field}
            label="Phone"
            placeholder="Phone"
            onFocus={scrollIntoView}
          />
        )}
      />
      {!withoutOwnerPercentage && (
        <Controller
          control={control} /* @ts-ignore */
          name="ownership_percentage"
          render={({ field } /* @ts-ignore */) => (
            <PercentageInput
              label="Ownership Percentage"
              {...field}
              onFocus={scrollIntoView}
            />
          )}
        />
      )}
      <Controller
        control={control}
        /* @ts-ignore */
        name="zipcode"
        render={({ field }) => (
          /* @ts-ignore */
          <Input
            {...field}
            label="Zipcode"
            placeholder="Zipcode"
            onFocus={scrollIntoView}
          />
        )}
      />
      <Controller
        control={control}
        name="country"
        render={({ field }) => (
          <GeoSelectsContextProvider>
            <CountrySelect
              {...field}
              label="Country"
              placeholder="Country"
              onSelect={field.onChange}
            />
          </GeoSelectsContextProvider>
        )}
      />
      <Controller
        control={control}
        name="ssn"
        render={({ field }) => (
          <Input
            {...field}
            label="SSN"
            placeholder="XXX-XX-XXXX"
            onFocus={scrollIntoView}
          />
        )}
      />
      <Controller
        control={control}
        name="date_of_birth"
        render={({ field }) => (
          <Datepicker
            {...field}
            value={new Date(field.value)}
            label="Date of Birth"
            onChange={(v) => field.onChange(dayjs(v).format(dateFormats.day))}
          />
        )}
      />
    </>
  );
};

export default PersonForm;
