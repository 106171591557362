import { useTypedSelector } from "app/store/useTypedSelector";
import TemplateBase from "features/template/TemplateBase/TemplateBase";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Status from "shared/ui/atoms/Status/Status";
import Button from "shared/ui/controls/Button/Button";
import ThreeDots from "shared/ui/controls/ThreeDots/ThreeDots";
import Table from "shared/ui/layout/Table";
import CraeteSpvModal from "widgets/SpvModal/CraeteSpvModal";
import { getSpvList } from "./model/thunks";
import { ISpv } from "./model/types";

const Deals = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { spvs } = useTypedSelector((state) => state.deals);

  useEffect(() => {
    dispatch(getSpvList());
  }, []);

  return (
    <TemplateBase title="Deals">
      <Table<ISpv>
        data={spvs}
        columns={[
          { title: "Spv", dataIndex: "name" },
          { title: "LLC Type", dataIndex: "llc_type" },
          {
            title: "Status",
            dataIndex: "status",
            render: (status) => (status ? <Status>{status}</Status> : "None"),
          },
          {
            title: "",
            dataIndex: "id",
            render: (id) => (
              <ThreeDots
                items={[
                  {
                    text: "View",
                    onClick: () => {
                      history.push(`/spv/${id}/overview`);
                    },
                  },
                ]}
              />
            ),
          },
        ]}
      />
    </TemplateBase>
  );
};

export default Deals;
