import styles from "./Dropdown.module.scss";
import cn from "classnames";
import {
  PropsWithChildren,
  ReactNode,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import useOnClickOutside from "shared/lib/hooks/useOnClickOutside";
import Icon, { IconNames } from "shared/ui/atoms/Icon";
import LabeledItem from "shared/ui/atoms/LabeledItem/LabeledItem";

export interface MenuItem {
  key: string;
  label: string;
  icon?: IconNames;
  iconColor?: string;
  subMenu?: MenuItem[];
  navigation?: boolean;
  component?: ReactNode;
  skipSetAlreadySelected?: boolean;
}

interface DropdownProps {
  icon?: ReactNode;
  value?: string;
  //eslint-disable-next-line
  onValueChange?: (key: string) => void;
  items?: MenuItem[];
  opened?: boolean;
  disabled?: boolean;
  overlayClassName?: string;
  itemClassName?: string;
  //eslint-disable-next-line
  onSelect?: (key: string) => void;
  label?: string;
  containerClassName?: string;
  variant?: "primary" | "default";
  size?: "small" | "medium";
  placement?: "right" | "center";
  placeholder?: string;
  onOpen?: () => void;
  onClose?: () => void;
  isAnyLabelHidden?: boolean;
}

const Dropdown: React.FC<PropsWithChildren<DropdownProps>> = ({
  icon,
  value,
  onValueChange,
  items,
  opened,
  disabled,
  overlayClassName,
  itemClassName,
  children,
  onSelect,
  label,
  containerClassName,
  variant = "default",
  size = "medium",
  placement = "right",
  placeholder,
  onOpen,
  onClose,
  isAnyLabelHidden,
}) => {
  const [isOpened, setIsOpened] = useState(opened);
  const [dispalyingValue, setDispalyingValue] = useState("");

  const onClick = (key: string) => {
    const localValue = items?.find((el) => el.key == key)?.label;
    setDispalyingValue(localValue || "");
    if (onSelect) {
      onSelect(key);
    }
    if (onValueChange) {
      onValueChange(key);
    }
    setIsOpened(false);
  };

  const triggerDropdown = () => {
    setIsOpened(!isOpened);
    if (isOpened) {
      onClose?.();
    } else {
      onOpen?.();
    }
  };

  const dropdownRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(dropdownRef, () => {
    setIsOpened(false);
    onClose?.();
  });

  useLayoutEffect(() => {
    const localValue = items?.find((el) => el.key == value)?.label;
    setDispalyingValue(localValue || "");
  }, [value, items]);

  return (
    <div className={containerClassName}>
      <LabeledItem label={label}>
        <div
          className={styles.dropdown}
          onClick={triggerDropdown}
          ref={dropdownRef}
        >
          <div
            className={cn(
              styles.dropdownPlaceholder,
              isOpened && styles.opened,
              disabled && styles.disabled,
              styles[size],
              styles[variant]
            )}
          >
            {children ? (
              children
            ) : !isAnyLabelHidden ? (
              <span className={styles.placeholder}>
                {icon && icon}
                {dispalyingValue || (
                  <span className={styles.placeholderText}>{placeholder}</span>
                )}
                &nbsp;
              </span>
            ) : null}
            <span className={styles.arrow}>
              <Icon name="ChevronDown" />
            </span>
          </div>
          <ul
            className={cn(
              styles.overlay,
              isOpened && styles.opened,
              overlayClassName,
              styles[placement]
            )}
          >
            {items?.map((item) =>
              item.component ? (
                <li key={item.key} onClick={() => onClick(item.key)}>
                  {item.component}
                </li>
              ) : (
                <li
                  key={item.key}
                  className={cn(styles.item, itemClassName)}
                  onClick={() => onClick(item.key)}
                >
                  {item.icon && <Icon name={item.icon} />}
                  <span>{item.label}</span>
                </li>
              )
            )}
          </ul>
        </div>
      </LabeledItem>
    </div>
  );
};

export default Dropdown;
