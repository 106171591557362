export const checkIcon = (
  <svg
    width="11"
    height="9"
    viewBox="0 0 11 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6032 1.22963C11.132 1.75847 11.132 2.61588 10.6032 3.14471L5.18654 8.56138C4.65771 9.09022 3.8003 9.09022 3.27146 8.56138L0.56313 5.85305C0.0342951 5.32421 0.0342951 4.4668 0.56313 3.93797C1.09197 3.40913 1.94938 3.40913 2.47821 3.93797L4.229 5.68876L8.68813 1.22963C9.21697 0.700799 10.0744 0.700799 10.6032 1.22963Z"
      fill="#7B8682"
    />
  </svg>
);
